
export default {
  name: 'BlogMenu',
  props: {
    siteId: {
      type: Number,
      required: false,
      default: 1
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: null,
    isActive: false
  }),
  computed: {
    isMainLinkActive () {
      const path = this.siteId === 1 ? '/news/' : '/blog/'
      return path === this.$route.path
    },
    categoryName () {
      return this.siteId === 1
        ? this.$t('menu.links.news-category.main')
        : this.$t('menu.links.blog-category.main')
    }
  },
  async fetch () {
    this.menu = await this.$api('blogCategories', 'get', {
      siteId: this.siteId
    })
    this.menu = this.menu.sort((a, b) => a.sort - b.sort).filter((el) => el.menu === 1)
  }
}
