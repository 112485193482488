
export default {
  name: 'work-with-us-block',
  props: {
    item: {
      type: Object,
      required: false
    }
  }
}
