
export default {
  name: 'BlogRating',
  props: {
    articleId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      like: 0,
      dislike: 0,
      pressed: null
    }
  },
  mounted () {
    this.loadRaiting()
    this.pressed = this.checkEvaluatedArticle(this.articleId)
  },
  methods: {
    async loadRaiting () {
      const articleId = this.articleId
      const { status, data } = await this.$api('evaluationOfArticle', 'getRateArticle', { articleId })
      if (status === 'ok') {
        this.like = Number(data.like)
        this.dislike = Number(data.dislike)
      }
    },
    async ratingClick (rate) {
      const evaluationType = rate
      const articleId = this.articleId
      const params = {
        evaluationType,
        articleId
      }
      if (this.pressed) {
        return
      }
      this.pressed = rate
      this[rate] += 1

      await this.$api('evaluationOfArticle', 'addRateArticle', params)
      this.setEvaluatedArticle(articleId, evaluationType)
    },
    setEvaluatedArticle (articleId, pressed) {
      const evaluatedArticle = localStorage.getItem('evaluatedArticle')
      let obj = {}
      obj[articleId] = pressed
      if (evaluatedArticle) {
        obj = JSON.parse(evaluatedArticle)
        obj[articleId] = pressed
      }
      localStorage.setItem('evaluatedArticle', JSON.stringify(obj))
    },
    checkEvaluatedArticle (articleId) {
      let evaluatedArticle = localStorage.getItem('evaluatedArticle')
      if (evaluatedArticle) {
        evaluatedArticle = JSON.parse(evaluatedArticle)
        return evaluatedArticle[articleId]
      }
      return null
    }
  }
}
