
import { CIcon } from 'site-lib'
import domainList from '../../plugins/json/domains.json'
import RegistrationLocal from '../../mixins/register-local'

export default {
  name: 'Menu',
  components: {
    CIcon,
    Banners: () => import('./banners.vue')
  },
  mixins: [RegistrationLocal],
  props: {
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isTablet: null,
      showMenu: true,
      showLanguageMenu: false,
      menuItems: {
        desktop: () => import('./menu/new/desktop.vue'),
        mobile: () => import('./menu/new/mobile.vue')
      },
      currentMenu: '',
      headerClass: '',
      onTopClass: '',
      hideOnPages: ['pollresult', 'mobile-get', 'creating'],
      darkPages: [
        'index',
        'calculation',
        'blog',
        'about',
        'facilities',
        'partners',
        'crm-lite',
        'collaboration',
        'crm-for-clients',
        'crm-biznes-process',
        'collaboration-business',
        'security',
        'vnedrenie-crm-sistemy'
      ]
    }
  },
  computed: {
    getLocalesList () {
      return domainList.languages.filter((el) => el.code !== this.activeLanguage)
    },
    menuClass () {
      return this.headerClass !== ''
        ? `${this.headerClass + this.onTopClass} ${this.activeLanguage}`
        : `${this.className + this.onTopClass} ${this.activeLanguage}`
    }
  },
  watch: {
    $route () {
      this.checkMenuAvailability()
    },
    isTablet: {
      handler (prev, cur) {
        if (cur !== prev) {
          this.getCurrentMenu()
        }
      }
    }
  },
  created () {
    this.checkMenuAvailability()
  },
  beforeMount () {
    document.body.addEventListener('click', (e) => {
      if (this.showLanguageMenu === false) {
        return
      }
      const target = this.$refs.langMenu
      const ePath = e.composedPath()
      if (!ePath.includes(target)) {
        this.$refs.languageMenuTrigger.dispatchEvent(new Event('click'))
      }
    })
  },
  mounted () {
    if (process.browser) {
      document.getElementsByTagName('body')[0].style.overflow = ''
      window.addEventListener('orientationchange', this.setIsTablet)
      window.addEventListener('resize', this.setIsTablet)
    }
    this.isTablet = this.isTabletScreen()
    this.callTrackingStart()
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('orientationchange', this.setIsTablet)
      window.removeEventListener('resize', this.setIsTablet)
    }
  },
  methods: {
    setLocaleWithParam (code) {
      this.setLocale(code)
      this.setLocaleHash()
      this.showLanguageMenu = false
      this.StartCallTrackingAfterChangeLocale()
    },
    setIsTablet () {
      this.isTablet = this.isTabletScreen()
    },
    handleScroll (evt) {
      this.showLanguageMenu = false
      this.headerClass = evt > 0 ? 'stickyed' : ''
    },
    getIndexClass (name) {
      return this.route === 'index' ? `${name} index-class` : name
    },
    getDarkLayout () {
      return this.darkPages.includes(this.route) && this.headerClass !== 'stickyed' && !this.isTablet ? 'dark' : ''
    },
    setTopClass (e) {
      this.onTopClass = e
    },
    checkMenuAvailability () {
      this.showMenu = !this.hideOnPages.includes(this.route)
    },
    languageMenuClickHandler () {
      this.showLanguageMenu = !this.showLanguageMenu
    },
    languageMenuEventDispatcher () {
      this.$refs.languageMenuTrigger.dispatchEvent(new Event('click'))
    },
    isTabletScreen () {
      return window.matchMedia('(max-width: 1199px)').matches
    },
    getCurrentMenu () {
      this.isTablet
        ? this.currentMenu = this.menuItems.mobile
        : this.currentMenu = this.menuItems.desktop
    }
  }
}
