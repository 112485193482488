
import { CButton } from 'site-lib/index.ts'

export default {
  name: 'FormElements',
  components: {
    CButton,
    MCButton: () => import('../../UI/buttons/mc-button.vue'),
    FormField: () => import('~/components/forms/form-field.vue')
  },
  props: {
    formFields: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    model: {},
    errors: [],
    buttonText: null,
    messageAfterSubmit: [],
    showPopupAfterSubmit: false,
    hasSubmitErrors: false,
    submitButtonIsDisabled: false
  }),
  watch: {
    model (value) {
      this.$emit('update:model', value)
    },
    showPopupAfterSubmit (value) {
      this.$emit('update:showPopupAfterSubmit', value)
    }
  },
  mounted () {
    this.buttonText = this.isExist(this.formButtonText)
      ? this.formButtonText
      : this.$t('regForm.buttonDefault')
    this.formFields.map((el) => {
      if (el.validateOnKey || el.required) {
        this.errors.push({
          field: el.name,
          correct: false
        })
      }
    })
  },
  methods: {
    formErrors (e) {
      this.errors.map((el, i) => {
        if (el.field === e.field) {
          if (e.correct) {
            this.errors.splice(i, 1)
            this.formFields.find((x) => x.name === el.field).showError = false
          } else {
            this.formFields.find((x) => x.name === el.field).showError = this.showErrorsWhenSubmit
          }
        }
      })
    },
    async submitForm () {
      const defaultButtonText = this.isExist(this.formButtonText)
        ? this.formButtonText
        : this.$t('regForm.buttonDefault')
      if (this.errors.length > 0) {
        this.errors.map((el) => this.formErrors(el))
        this.buttonText = this.$t('regForm.fillForm')
        setTimeout(() => {
          this.buttonText = defaultButtonText
        }, 3000)
        return false
      }

      this.submitButtonIsDisabled = true
      this.buttonText = this.$t('regForm.buttonIndexCheck')
      const result = await this.$api('sendMail', 'subscribe', { email: this.model.email })
      this.buttonText = defaultButtonText
      this.submitButtonIsDisabled = false
      if (result.length > 0) {
        this.hasSubmitErrors = true
        result.map((e) => this.messageAfterSubmit.push(e))
      } else {
        this.hasSubmitErrors = false
      }
      this.showPopupAfterSubmit = true
      return true
    },
    closeModal () {
      this.closePopup('articles-subscription')
    }
  }
}
