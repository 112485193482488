
import Vue from 'vue'
import { CIcon } from '../CIcon'

export default Vue.extend({
  name: 'CInput',
  components: { CIcon },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  data() {
    return {
      hasBeenTouched: false,
      customCheckError: false
    }
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    dataField:{
      type: String,
      required: false,
    },
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    errorList: {
      type: Array,
      required: false,
      default: null
    },
    customCheck:{
      type: Function,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  computed: {
    iconColorClass(): string {
      if(!this.hasBeenTouched) {
        return 'grey'
      }
      else{
        return this.error || this.hasCustomErrors || this.customCheckError ? 'coral' : 'green'
      }
    },
    // isMobile() {
    //   if (window && window?.innerWidth < 1024) {
    //     return true
    //   } 
    //   return false
    // },
    hasCustomErrors(): boolean {
      if(this.errorList){
        return this.errorList.some(({error, field}: any) => error && field === this.dataField)
      }
      return false
    }
  },
  methods: {
    inputHandler (value: string) {
      if(this.customCheck){
        this.customCheckError = !this.customCheck(value)
      }
      this.$emit('input', value)
    },
    handleFirstInput(){
      this.hasBeenTouched = true
    },
    blurHandler() {
      this.$emit('blur')
    }
  }
})
