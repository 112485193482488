
import { CButton, DButton, DCheckbox } from 'site-lib/index.ts'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'FormElements',
  components: {
    CButton,
    DButton,
    DCheckbox,
    VueRecaptcha,
    FormField: () => import('~/components/forms/form-field.vue')
  },
  props: {
    header: {
      type: String,
      required: false
    },
    subHeader: {
      type: String,
      required: false
    },
    styleName: {
      type: String,
      default: 'default'
    },
    inputStyle: {
      type: String,
      default: 'c-forms-form-field'
    },
    formFields: {
      type: Array,
      required: true
    },
    formButtonText: {
      type: String
    },
    formEmailName: {
      type: String,
      required: false,
      default: 'default'
    },
    formEmailTitle: {
      type: String,
      required: true
    },
    formSuccessMessage: {
      type: String,
      required: true
    },
    formSuccessDescription: {
      type: String,
      required: false
    },
    formSuccessButtonText: {
      type: String,
      required: false
    },
    formSuccessButtonLink: {
      type: String,
      required: false
    },
    showSendMore: {
      type: Boolean,
      default: false
    },
    metricsTarget: {
      type: String,
      default: null
    },
    metricsUtm: {
      type: String,
      default: null
    },
    sendsay: {
      type: Object,
      default: () => ({})
    },
    showErrorsWhenSubmit: {
      type: Boolean,
      default: false
    },
    bannerId: {
      type: Number,
      default: 0
    },
    typeSpaces: {
      type: Boolean,
      default: false
    },
    formPolicyText: {
      type: String
    },
    emitFormSuccessStatus: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: {},
    policyAgree: { agreed: false, error: false },
    errors: [],
    buttonText: null,
    policyText: null,
    messageAfterSubmit: [],
    showPopupAfterSubmit: false,
    hasSubmitErrors: false,
    submitButtonIsDisabled: false,
    showCaptchaForm: false,
    captchaToken: '',
    captchaError: '',
    isAutotest: false
  }),
  computed: {
    siteKey () {
      return process.env.GCAPTCHA_CODE
    },
    fullButtonWidth () {
      const styleNameList = ['documents', 'about', 'partners', 'contacts']
      return styleNameList.includes(this.styleName)
    }
  },
  watch: {
    model (value) {
      this.$emit('update:model', value)
    },
    showPopupAfterSubmit (value) {
      this.$emit('update:showPopupAfterSubmit', value)
    }
  },
  mounted () {
    if (process.browser && window.Cypress) {
      this.isAutotest = true
    }
    if (!this.isAutotest) {
      this.checkSettingGoogleRecaptchaForForm()
    }
    if (this.typeSpaces) {
      this.policyAgree.agreed = true
    }
    this.buttonText = this.isExist(this.formButtonText)
      ? this.formButtonText
      : this.$t('regForm.buttonDefault')
    this.policyText = this.isExist(this.formPolicyText)
      ? this.formPolicyText
      : this.$t('regForm.politicTextSmall')
    this.formFields.map((el) => {
      if (el.validateOnKey || el.required) {
        this.errors.push({
          field: el.name,
          correct: false
        })
      }
    })
  },
  methods: {
    verifyCaptcha (token) {
      this.captchaToken = token
    },
    expiredCaptcha () {
      this.$refs.recaptcha.reset()
    },
    formErrors (e) {
      this.errors.map((el, i) => {
        if (el.field === e.field) {
          if (e.correct) {
            this.errors.splice(i, 1)
            this.formFields.find((x) => x.name === el.field).showError = false
          } else {
            this.formFields.find((x) => x.name === el.field).showError = this.showErrorsWhenSubmit
          }
        }
      })
    },
    resetPolicyError () {
      this.policyAgree.error = false
    },
    sendOneMore () {
      this.showPopupAfterSubmit = false
      this.messageAfterSubmit.length = 0
    },
    async submitForm () {
      const defaultButtonText = this.isExist(this.formButtonText)
        ? this.formButtonText
        : this.$t('regForm.buttonDefault')
      if (this.errors.length > 0) {
        this.errors.map((el) => this.formErrors(el))
        this.buttonText = this.$t('regForm.fillForm')
        setTimeout(() => {
          this.buttonText = defaultButtonText
        }, 3000)
        return false
      }
      if (!this.policyAgree.agreed) {
        this.policyAgree.error = true
        return false
      }
      const fields = []
      this.formFields.map((field) => {
        if (this.isExist(this.model[field.name])) {
          const description = this.$t(`regForm.${field.name}`)
          fields.push({
            name: field.name,
            description,
            value: this.model[field.name]
          })
        }
      })
      this.submitButtonIsDisabled = true
      this.buttonText = this.$t('regForm.buttonIndexCheck')
      const token = this.captchaToken
      const result = await this.$api('formRequest', 'sendForm', {
        systemName: this.formEmailName,
        title: this.formEmailTitle,
        utm: this.isExist(this.metricsUtm) ? this.metricsUtm : null,
        sendsay: Object.keys(this.sendsay).length > 0 ? this.sendsay : null,
        isAutotest: this.isAutotest,
        urlPath: this.$route.path,
        token,
        fields
      })
      if (result.captcha) {
        this.captchaError = result.captcha
        this.submitButtonIsDisabled = false
        this.buttonText = defaultButtonText
        return false
      }
      this.buttonText = defaultButtonText
      this.submitButtonIsDisabled = false
      if (result.status === 'error') {
        this.hasSubmitErrors = true
        typeof result.message === 'string'
          ? this.messageAfterSubmit.push(result.message)
          : Object.values(result.message).map((e) => this.messageAfterSubmit.push(e))
      } else {
        this.hasSubmitErrors = false
        this.messageAfterSubmit.push(this.formSuccessMessage)
        if (this.isExist(this.metricsTarget)) {
          await this.sendGoalEvent(this.metricsTarget)
        }
        if (this.emitFormSuccessStatus) {
          this.$emit('formSubmitted', true)
        }
      }
      this.showPopupAfterSubmit = true
      this.statBannerPlus('success_submit_form', this.bannerId)
      return true
    }
  }
}
