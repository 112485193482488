
import { gsap } from 'gsap/dist/gsap.min'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min'

gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'PartnersTabContent',
  components: {
    Benefits: () => import('../elements/benefits.vue'),
    IntegrationsBlock: () => import('./integrations-block.vue'),
    FormElements: () => import('../forms/new/form-elements.vue')
  },
  data: () => ({
    formSubmitted: false,
    mediaQuery: gsap.matchMedia(),
    baseEase: 'power2.out'
  }),
  props: {
    partnerType: {
      type: String, // dealer | referer
      required: true
    }
  },
  watch: {
    partnerType () {
      this.setDefaultStyleVariablesProperty()
      this.$nextTick(() => {
        this.initAnimationSteps()
      })
    }
  },
  computed: {
    partnerSteps () {
      return this.$t(`pages.partners.types.${this.partnerType}.content.steps.list`)
    }
  },
  methods: {
    getFormParams () {
      return {
        formEmailName: 'partners',
        formSuccessMessage: this.$t('regForm.defaultSuccessMessage'),
        formEmailTitle: this.$t(`pages.partners.types.${this.partnerType}.form.emailTitle`),
        styleName: 'documents',
        inputStyle: 'documents',
        formFields: [
          {
            name: 'name'
          },
          {
            name: 'email',
            validateOnKey: true,
            required: true,
            rule: 'email'
          },
          {
            name: 'phone',
            validateOnKey: true,
            required: true,
            rule: 'phone'
          }
        ]
      }
    },
    toggleFormStatus (value) {
      this.formSubmitted = value
    },
    setDefaultStyleVariablesProperty () {
      this.$refs.steps.forEach((elem) => {
        elem.style.setProperty('--background-color-after', '#92A6BF')
        elem.style.setProperty('--number-div-color', '#92A6BF')
        elem.style.setProperty('--background-color-div', '#EBEEF3')
      })
    },
    initAnimationSteps () {
      ScrollTrigger.defaults({ scroller: '.layout__content' })
      this.mediaQuery.add('(min-width: 883px)', () => {
        this.$refs.steps.forEach((element, index) => {
          const duration = 1
          const delay = (duration * 2) * index
          const isLast = index + 1 === this.$refs.steps.length
          gsap.timeline({
            scrollTrigger: {
              trigger: '.content__steps',
              start: 'top 50%'
            }
          }).to(element, {
            duration,
            delay,
            ease: this.baseEase,
            repeat: isLast ? false : 1,
            yoyo: isLast ? false : 'reversed',
            '--background-color-after': '#23CE70',
            '--background-color-div': '#23CE70',
            '--number-div-color': '#FFFFFF'
          })
        })
      })
    }
  },
  mounted () {
    this.initAnimationSteps()
  }
}
