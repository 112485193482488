
import { DButton } from 'site-lib'

export default {
  name: 'HomePageContent',
  components: {
    DButton,
    Companies: () => import('../blocks/companies.vue'),
    SpacesBlock: () => import('../blocks/spaces-block.vue'),
    WebinarsBlock: () => import('../blocks/webinars-block.vue'),
    NumbersBlock: () => import('../blocks/numbers-block.vue'),
    DataProtectionBlock: () => import('../blocks/data-protection-block.vue'),
    WorkWithUsBlock: () => import('../blocks/work-with-us-block.vue'),
    IntegrationsBlock: () => import('../blocks/integrations-block.vue'),
    PriceTable: () => import('./calculation/price-table-new.vue'),
    QuoteBlock: () => import('../blocks/quote-block.vue'),
    UseCasesBlock: () => import('../blocks/use-cases-block.vue'),
    EmbeddedVideoBlock: () => import('../blocks/embedded-video-block.vue'),
    MobileBlock: () => import('../blocks/mobile-app-block.vue'),
    BlogCases: () => import('../blocks/blog-cases.vue'),
    FooterGlowWrapper: () => import('../elements/footer-glow-wrapper.vue'),
    Header: () => import('../elements/header.vue')
  },
  props: {
    headerForm: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    scrolled: false,
    observer: null,
    observableElement: null
  }),
  mounted () {
    this.setObserver()
    if (localStorage.getItem('showRegForm') === 'Y') {
      localStorage.removeItem('showRegForm')
      this.showPopup('reg-form')
    }
  },
  methods: {
    // после подгрузки компонентов observer перевешивается на footer
    setObserver (refreshed = false) {
      const domElement = refreshed ? '.footer' : '.constructor'
      this.observableElement = document.querySelector(domElement)
      this.observer = new IntersectionObserver(
        ([entry], observer) => {
          if (entry.isIntersecting && entry.target.dataset.target === 'scrollToContent') {
            this.scrolled = true
            observer.unobserve(entry.target)
          }
          if (entry.isIntersecting && entry.target.dataset.target === 'scrollToFooter') {
            const event = entry.target.dataset.target
            this.sendGoalEvent(event)
            observer.unobserve(entry.target)
          }
        },
        { root: document.querySelector('.layout__content'), rootMargin: '0px 0px 50px 0px' }
      )
      setTimeout(() => {
        this.observer.observe(this.observableElement)
      }, 500)
    },
    showRegForm (popupName) {
      const formParams = {
        metricsTarget: 'unlimitNew',
        sendMore: false
      }
      let target = ''
      if (popupName === 'reg-form') {
        formParams.metricsTarget = 'boxNew'
        target = 'boxRegistrationNew'
      }
      this.showRegPopup(popupName, formParams, target)
    }
  },
  beforeDestroy () {
    this.observer.disconnect()
    this.observer = null
    this.observableElement = null
  },
  watch: {
    scrolled (val) {
      if (val) {
        setTimeout(() => {
          this.setObserver(val)
        }, 500)
      }
    }
  }
}
