

export default {
  name: 'BusinessManagerProPage',
  components: {
    Tabs: () => import('../components/elements/tabs.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data () {
    return {
      activeTab: 'contacts',
      localTab: 'everywhere'
    }
  },
  computed: {
    tabs () {
      return [
        {
          tab: 'contacts',
          title: this.$t('pages.business-manager-pro.plans.tabs.contacts')
        },
        {
          tab: 'tasks',
          title: this.$t('pages.business-manager-pro.plans.tabs.tasks')
        },
        {
          tab: 'discussions',
          title: this.$t('pages.business-manager-pro.plans.tabs.discussions')
        },
        {
          tab: 'docs',
          title: this.$t('pages.business-manager-pro.plans.tabs.docs')
        },
        {
          tab: 'staff',
          title: this.$t('pages.business-manager-pro.plans.tabs.staff')
        },
        {
          tab: 'calendar',
          title: this.$t('pages.business-manager-pro.plans.tabs.calendar')
        }
      ]
    },
    workTabs () {
      return [
        {
          tab: 'everywhere',
          title: this.$t('pages.business-manager-pro.work.tabs.everywhere')
        },
        {
          tab: 'security',
          title: this.$t('pages.business-manager-pro.work.tabs.security')
        }
      ]
    }
  },
  mounted () {
    this.moscowSnippet()
  },
  methods: {
    moscowSnippet () {
      const fjs = document.getElementsByTagName('script')[0]
      const js = document.createElement('script')
      js.src = 'https://widgets.mos.ru/cnews/citywidgets.js'
      fjs.parentNode.insertBefore(js, fjs)

      const i = setInterval(() => {
        if (window.city_widget && document.readyState === 'complete') {
          window.city_widget('200x200', '#widget1')
          clearInterval(i)
        }
      }, 50)
      setTimeout(() => {
        clearInterval(i)
      }, 5000)
    }
  }
}

