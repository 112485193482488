

export default {
  name: 'RegisterForm',
  components: {
    RegElements: () => import('../forms/reg-elements.vue')
  },
  props: {
    header: {
      type: String,
      required: false,
      default: 'Попробуйте Мегаплан<br />прямо сейчас'
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Первые 14 дней бесплатно'
    },
    withPromoCode: {
      type: Boolean,
      required: false,
      default: false
    },
    showSocials: {
      type: Boolean,
      required: false,
      default: true
    },
    className: {
      type: String,
      required: false,
      default: 'static-header-form popup'
    },
    buttonText: {
      type: String,
      required: false,
      default: ''
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
