
import DateFormat from '../../../mixins/dateFormat'

export default {
  name: 'BlogPinnedPost',
  mixins: [DateFormat],
  components: {
    CIcon: () => import('../../site-lib/src/components/CIcon/CIcon.vue'),
    DButton: () => import('../../site-lib/src/components/DButton/DButton.vue')
  },
  data: () => ({
    latestPost: undefined
  }),
  async fetch () {
    const post = await this.$api('blogPosts', 'list', {
      siteId: 2,
      limit: 1
    })
    this.latestPost = post.items
  },
  methods: {
    getThumbUrl (path, articleBackgroundFolder, articleSlug) {
      let result
      if (path) {
        const dir = path.substring(0, path.lastIndexOf('/') + 1)
        const fileName = path.split('/').pop()
        result = `/upload/blog/${dir}thumb__${fileName}`
        if (articleBackgroundFolder) {
          result = `/upload/blog/${articleSlug}/${dir}thumb__${fileName}`
        }
      } else {
        result = '/img/plane_thumb.png'
      }
      return result
    },
    getErrorThumb (e) {
      e.target.src = e.target.parentNode.parentNode.classList.contains('pic') ? '/img/plane_thumb_full.png' : '/img/plane_thumb.png'
      e.target.style.backgroundColor = `rgba(${Math.round(Math.random() * 255)}
      , ${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, .15)`
      e.target.style.border = '1px solid rgba(0,0,0,.15)'
    }
  }
}
