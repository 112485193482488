export default {
  getType (state) {
    return state.type
  },
  getCount (state) {
    return state.count
  },
  getPeriod (state) {
    return state.period
  }
}
