
export default {
  name: 'ImplementationBlock',
  props: {
    renderItem: {
      type: String || undefined, // audit, inception, studying, admin-studying
      default: undefined,
      required: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    renderedItems () {
      if (this.renderItem) {
        return [this.$t('pages.components.implementation.blocks').find((item) => item.code === this.renderItem)]
      }
      return this.$t('pages.components.implementation.blocks')
    }
  },
  methods: {
    setFormParams (str, fullHeader = false) {
      return {
        header: fullHeader
          ? str
          : this.$t('pages.components.implementation.order.basic',
            { text: this.$t(`pages.components.implementation.order.${str}`) }),
        emailTo: this.$t('pages.components.implementation.form.email'),
        formEmailTitle: this.$t(`pages.components.implementation.form.emailTitleList.${str}`),
        subtitle: this.$t('pages.index.form.subtitle'),
        fields: ['email', 'shortname', 'phone'],
        target: 'ucSendForm',
        sendMore: false
      }
    }
  }
}
