
export default {
  name: 'BlogContent',
  components: {
    BlogSearchResult: () => import('./blog-search-result.vue'),
    BlogLoadNextPage: () => import('./blog-load-next-page.vue'),
    BlogSearch: () => import('./blog-search.vue'),
    BlogMenu: () => import('./blog-menu.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    itemsTitle: {
      type: String,
      default: ''
    },
    efficiencyTitle: {
      type: String,
      default: ''
    },
    efficiencySubTitle: {
      type: String,
      default: ''
    },
    siteId: {
      type: Number,
      default: 1
    },
    categoryId: {
      type: Number,
      default: 0
    },
    posts: {
      type: [Array, Object],
      default: () => ([])
    },
    popular: {
      type: [Array, Object],
      default: () => ([])
    },
    showSubscribeButton: {
      type: Boolean,
      default: true
    },
    showFirstItemBigCard: {
      type: Boolean,
      default: true
    },
    showEfficiency: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      items: {
        blog: () => import('./blog-items.vue'),
        popular: () => import('./blog-items.vue'),
        news: () => import('./news-items.vue')
      },
      searchResults: null,
      sectionBlogId: process.env.sectionBlogId,
      internalTitle: this.title === ''
        ? this.$t('menu.links.blog')
        : this.title,
      internalItemsTitle: this.itemsTitle === ''
        ? this.$t('pages.components.blog-content.new-posts')
        : this.itemsTitle,
      internalEfficiencyTitle: this.efficiencyTitle === ''
        ? this.$t('pages.components.blog-content.efficiencyTitle')
        : this.efficiencyTitle,
      internalEfficiencySubTitle: this.efficiencySubTitle === ''
        ? this.$t('pages.components.blog-content.efficiencySubTitle')
        : this.efficiencySubTitle
    }
  }
}
