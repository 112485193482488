

export default {
  name: 'AmpNewsPost',
  props: ['post', 'meta', 'locale'],
  computed: {
    articleTitle () {
      return this.isExist(this.meta.translations[this.locale])
        ? this.meta.translations[this.locale].name
        : this.meta.translations.ru.name
    },
    canonicalUrl () {
      return process.env.HOST_NAME + this.$route.fullPath.replace(/\/amp\//, '/')
    },
    canonicalRuPage () {
      return `https://megaplan.ru${this.$route.fullPath.replace(/\/amp\//, '/')}`
    }
  },
  components: {
    RegButtonAmp: () => import('../forms/reg-button-amp.vue'),
    AmpBottomBanner: () => import('./blog/amp-bottom-banner.vue')
  },
  head () {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.canonicalRuPage
        }
      ]
    }
  }
}
