
export default {
  name: 'Input',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    error: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    }
  }
}
