import Vue from 'vue'

Vue.directive('scroll', {
  inserted (el, binding) {
    const body = document.getElementsByTagName('body')[0]
    const f = ({ target }) => {
      const { offsetHeight, scrollHeight, scrollTop, offsetWidth } = target
      const documentHeight = scrollHeight - offsetHeight

      if (scrollTop > 0) {
        body.classList.add('farfromtop')

        // когда показывать кнопку наверх
        if (documentHeight > 2500 && scrollTop > 1000 && offsetWidth > 767) {
          body.classList.add('toTopBtn')
        } else {
          body.classList.remove('toTopBtn')
        }
      } else {
        body.classList.remove('farfromtop', 'toTopBtn')
      }

      if (binding.value(scrollTop, documentHeight, el)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
})
