
import subscribePopup from '../../mixins/subscribePopup'

export default {
  name: 'BlogIndex',
  components: {
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue'),
    BlogContentNew: () => import('../../components/pages/blog/blog-content-new.vue')
  },
  mixins: [subscribePopup],
  async asyncData ({ app, error }) {
    const populars = await app.$api('blogPosts', 'list', {
      siteId: 2,
      limit: 4,
      popular: 1
    })
    const posts = await app.$api('blogPosts', 'list', {
      siteId: 2,
      limit: 6,
      offset: 1
    })
    if (typeof posts === 'undefined' || typeof posts.items === 'undefined') {
      return error({ statusCode: 500, message: 'Внутренняя ошибка' })
    }
    const categories = await app.$api('blogCategories', 'get', {
      siteId: 1
    })
    const newsUpdateCategory = categories.find((category) => category.slug === 'updates')
    const newsUpdateItems = await app.$api('blogPosts', 'list', {
      categoryId: newsUpdateCategory.id,
      siteId: 1,
      limit: 4
    })
    const videos = await app.$api('blogPosts', 'list', {
      siteId: 3,
      limit: 3
    })
    return {
      posts: posts.items,
      populars: populars.items,
      updates: newsUpdateItems.items,
      videos: videos.items
    }
  },
  head () {
    return {
      title: this.$t('pages.blog.meta.title'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('pages.blog.meta.description') }
      ]
    }
  }
}
