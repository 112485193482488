
import { CIcon, DButton } from 'site-lib'

export default {
  name: 'CrmCapabilitiesTemplate',
  components: {
    CIcon,
    DButton,
    BreadCrumbs: () => import('../elements/breadcrumbs.vue'),
    WebinarsBlock: () => import('../blocks/webinars-block.vue'),
    FaqBlock: () => import('../blocks/faq-block.vue'),
    Prefooter: () => import('~/components/elements/pre-footer.vue'),
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue'),
    CrmCapabilitiesDescriptionBlock: () => import('~/components/UI/crm-capabilities-description-block.vue')
  },
  props: {
    meta: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      content: undefined,
      slugsWithArticle: [
        'eisenhower-matrix',
        'video-conference-program',
        'gantt-chart',
        'scrum',
        'analog-notion',
        'dashboard',
        'knowledge-base'
      ]
    }
  },
  methods: {
    contacUsClick () {
      this.showPopup('form', {
        header: this.$t('regForm.contactSales'),
        formEmailName: 'default',
        formEmailTitle: `${this.$t('regForm.requestFrom')} "${document.title}"`
      })
    }
  },
  async fetch () {
    const hasArticle = this.slugsWithArticle.includes(this.meta.slug)
    if (this.meta && hasArticle) {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/crm-capabilities/' + this.meta.slug + '.md')
        // eslint-disable-next-line no-return-assign
        .then((res) => this.content = res.default)
        .catch((e) => {
          throw new Error(`Запись ${this.slug} не найдена\n${e}`)
        })
    }
  },
  computed: {
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate
      }
    }
  }
}
