
import loadData from '~/plugins/utils/blogPostLoadData'
import subscribePopup from '../../../mixins/subscribePopup'

export default {
  components: {
    BlogPostComponent: () => import('../../../components/pages/blog/blog-post-component.vue')
  },
  mixins: [subscribePopup],
  name: 'BlogCrmPostPage',
  async asyncData ({ app, params, error }) {
    try {
      const category = 'crm'
      return await loadData(params, app.$api, category)
    } catch (e) {
      return error({ statusCode: 404 })
    }
  },
  amp: 'hybrid',
  ampLayout: 'amp'
}
