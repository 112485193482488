export default {
  computed: {
    menuItems () {
      return [
        {
          title: this.$t('menu.links.product'),
          subMenu: {
            sections: [
              {
                title: this.$t('menu.links.about-megaplan'),
                links: [
                  {
                    title: this.$t('menu.links.facilities'),
                    href: '/facilities/',
                    iconName: 'HIERARCHY'
                  },
                  {
                    title: this.$t('menu.links.api-integration'),
                    href: '/api-integration/',
                    iconName: 'PUZZLE'
                  },
                  {
                    title: this.$t('menu.links.compare'),
                    href: '/compare/',
                    iconName: 'CLOUD_BOX'
                  },
                  {
                    title: this.$t('menu.links.mobile'),
                    href: '/mobile/',
                    iconName: 'MOBILE'
                  },
                  {
                    title: this.$t('menu.links.dev'),
                    href: 'https://dev.megaplan.ru',
                    external: 'true',
                    iconName: 'CODE'
                  },
                  {
                    title: this.$t('menu.links.security'),
                    href: '/security/',
                    iconName: 'LOCK'
                  },
                  {
                    title: this.$t('menu.links.news-category.updates'),
                    href: '/news/updates/',
                    iconName: 'REFRESH'
                  },
                  {
                    title: this.$t('menu.links.help-training'),
                    href: '/help-training/#training',
                    iconName: 'BOOK'
                  }
                ],
                showMore: false
              },
              {
                title: this.$t('menu.links.services'),
                links: [
                  {
                    title: this.$t('menu.new.services.audit'),
                    href: '/vnedrenie-crm-sistemy/#audit',
                    iconName: 'SEARCH'
                  },
                  {
                    title: this.$t('menu.new.services.inception'),
                    href: '/vnedrenie-crm-sistemy/#inception',
                    iconName: 'SETTINGS'
                  },
                  {
                    title: this.$t('menu.new.services.studying'),
                    href: '/vnedrenie-crm-sistemy/#studying',
                    iconName: 'GRADUATION'
                  },
                  {
                    title: this.$t('menu.links.vnedrenie-crm'),
                    href: '/vnedrenie-crm-sistemy/',
                    iconName: 'CHECK_SQUARE_BROKEN'
                  }
                ],
                showMore: false
              }
            ]
          }
        },
        {
          title: this.$t('menu.links.crmcapabilities'),
          subMenu: {
            sections: [
              {
                title: this.$t('menu.links.possibilities'),
                isLonger: false,
                links: [
                  {
                    title: this.$t('menu.links.crmcapabilities-category.sales-department'),
                    href: '/crm-capabilities/sales-department/',
                    iconName: 'PHONE'
                  },
                  {
                    title: this.$t('menu.links.crmcapabilities-category.projects-management'),
                    href: '/crm-capabilities/projects-management/',
                    iconName: 'DATAFLOW_02'
                  },
                  {
                    title: this.$t('menu.links.crmcapabilities-category.time-control'),
                    href: '/crm-capabilities/orders-control/',
                    iconName: 'CLOCK'
                  },
                  {
                    title: this.$t('menu.links.crmcapabilities-category.gantt-chart'),
                    href: '/crm-capabilities/gantt-chart/',
                    iconName: 'TASK'
                  }
                ],
                showMoreLink: '/crm-capabilities/'
              },
              {
                title: this.$t('menu.links.crm-for-business'),
                isLonger: false,
                links: [
                  {
                    title: this.$t('menu.links.crm-for-business-category.manufacture'),
                    href: '/crm-for-business/crm-for-manufacture/',
                    iconName: 'PRODUCTION'
                  },
                  {
                    title: this.$t('menu.links.crm-for-business-category.construction'),
                    href: '/crm-for-business/crm-for-construction/',
                    iconName: 'BUILDING'
                  },
                  {
                    title: this.$t('menu.links.crm-for-business-category.crm-for-lawyers-and-law-firms'),
                    href: '/crm-for-business/crm-for-lawyers-and-law-firms/',
                    iconName: 'BANK'
                  },
                  {
                    title: this.$t('menu.links.crm-for-business-category.crm-optovye-prodazhi'),
                    href: '/crm-for-business/crm-optovye-prodazhi/',
                    nofollow: true,
                    iconName: 'PACKAGE'
                  }
                ],
                showMoreLink: '/crm-for-business/'
              },
              {
                title: this.$t('menu.links.client-experience'),
                altBg: true,
                isLonger: false,
                links: [
                  {
                    title: this.$t('menu.links.clients-category.manufacture'),
                    href: '/video-megaplan/video-clients/malyj-proizvodstvennyj-biznes-o-megaplan.../'
                  },
                  {
                    title: this.$t('menu.links.clients-category.sales'),
                    href: '/clients/forpolymer/'
                  },
                  {
                    title: this.$t('menu.links.clients-category.geekbrains'),
                    href: '/clients/geekbrains/'
                  },
                  {
                    title: this.$t('menu.links.clients-category.fivepost'),
                    href: '/clients/fivepost/'
                  }
                ],
                showMoreLink: '/clients/'
              }
            ]
          }
        },
        {
          title: this.$t('menu.links.calculation'),
          href: '/calculation/'
        },
        {
          title: this.$t('menu.links.company'),
          subMenu: {
            sections: [
              {
                isLonger: false,
                links: [
                  {
                    title: this.$t('menu.links.company'),
                    href: '/about/',
                    iconPath: '/upload/pages/index/menuIcons/about.svg'
                  },
                  {
                    title: this.$t('menu.links.news'),
                    href: '/news/',
                    iconPath: '/upload/pages/index/menuIcons/news.svg'
                  },
                  {
                    title: this.$t('menu.links.news-category.events'),
                    href: '/news/events/',
                    iconPath: '/upload/pages/index/menuIcons/events.svg'
                  },
                  {
                    title: this.$t('menu.links.partners'),
                    href: '/partners/',
                    iconPath: '/upload/pages/index/menuIcons/partners.svg'
                  },
                  {
                    title: this.$t('menu.links.contacts'),
                    href: '/contacts/',
                    iconPath: '/upload/pages/index/menuIcons/contacts.svg'
                  }
                ]
              }
            ]
          }
        },
        {
          title: this.$t('menu.links.blog'),
          href: '/blog/'
        },
        {
          title: this.$t('menu.links.video'),
          href: '/video-megaplan/'
        }
      ]
    }
  }
}
