import pricesJson from '../plugins/json/prices.json'
import mspPricesJson from '../plugins/json/mspPrices.json'
import tables from '../plugins/json/calculation/tables.json'
import newPrices from '../plugins/json/pricesNew.json'

export default {
  data: () => ({
    table: 'components.compare-table'
  }),
  computed: {
    priceContent () {
      const route = ['calculation', 'free', 'free-closed'].includes(this.route) ? this.route : 'calculation'
      const currentTariffs = tables.pages[route][this.activeLanguage]
      const showTariffList = !this.isExist(currentTariffs)
        ? tables.pages[route].ru.showTariffs
        : currentTariffs.showTariffs
      let result
      this.mspPrices
        ? result = mspPricesJson.data[this.activeLanguage]
        : result = pricesJson.data[this.activeLanguage]
      const availableDiscount = []
      const tariffList = { cloud: [], box: [] }
      const { durations, durationFactors, prices } = result
      const tariffDescription = [
        {
          code: 'startlite',
          name: {
            ru: 'Старт'
          }
        },
        {
          code: 'collaborationlite',
          name: {
            ru: 'Бесплатный'
          }
        },
        {
          code: 'collaboration2012',
          name: {
            ru: 'Совместная работа'
          }
        },
        {
          code: 'collaborationplus',
          color: 'black',
          name: {
            ru: 'Совместная работа +',
            ua: 'Спільна робота +'
          }
        },
        {
          code: 'crmlite',
          color: 'blue',
          name: {
            ru: 'CRM: Лайт'
          }
        },
        {
          code: 'collaborationplusbusiness',
          color: 'blue',
          name: {
            ru: 'Совместная работа: Бизнес',
            ua: 'Спільна робота: Бізнес'
          }
        },
        {
          code: 'crm2012',
          name: {
            ru: 'CRM: Клиенты и продажи'
          }
        },
        {
          code: 'crmplus',
          color: 'green',
          name: {
            ru: 'CRM: Клиенты и продажи +',
            ua: 'CRM: Клієнти та продажі +'
          }
        },
        {
          code: 'crmbusiness',
          color: 'purple',
          name: {
            ru: 'CRM: Бизнес',
            ua: 'CRM: Бізнес'
          }
        }
      ]

      // Собираем доступные месяцы для скидок
      durations.forEach((el, i) => {
        availableDiscount.push({ month: el[0], factor: durationFactors[i] })
      })

      // Собираем массив тарифов
      tariffDescription.map((el) => {
        if (showTariffList.includes(el.code)) {
          let tariffName = el.name.ru
          if (typeof el.name[this.activeLanguage] !== 'undefined') {
            tariffName = el.name[this.activeLanguage]
          }
          if (el.color) {
            tariffList.cloud.push({
              name: tariffName,
              code: el.code,
              color: el.color,
              price: prices.saas[el.code]
            })
          } else {
            tariffList.cloud.push({
              name: tariffName,
              code: el.code,
              price: prices.saas[el.code]
            })
          }
          if (el.code !== 'crmlite') {
            tariffList.box.push({
              name: tariffName,
              code: el.code,
              price: prices.box[el.code]
            })
          }
        }
      })

      return {
        discountList: availableDiscount,
        tariffList
      }
    },
    tariffContent () {
      const availableTariffs = tables.tariffList
      const result = newPrices.data.cloud[this.activeLanguage]
      const availableDiscount = []
      const tariffList = { cloud: [], box: [], team: [] }
      const { durations, durationFactors, prices } = result
      const tariffDescription = tables.tariffDescription

      // Собираем доступные месяцы для скидок
      durations.forEach((el, i) => {
        availableDiscount.push({ month: el[0], factor: durationFactors[i] })
      })

      // Собираем массив тарифов
      tariffDescription.map((el) => {
        if (availableTariffs.includes(el.code)) {
          let tariffName = el.name.ru
          if (typeof el.name[this.activeLanguage] !== 'undefined') {
            tariffName = el.name[this.activeLanguage]
          }
          if (el.code !== 'collaborationplusbusiness' && el.code !== 'crmbusiness') {
            tariffList.team.push({
              name: tariffName,
              code: el.code,
              price: prices.saas[el.code]
            })
          }
          if (el.code !== 'crmlite') {
            tariffList.box.push({
              name: tariffName,
              code: el.code,
              additionPrice: prices.box[el.code],
              price: prices.boxFullPrice[el.code],
              color: el.color
            })
          }
          tariffList.cloud.push({
            name: tariffName,
            code: el.code,
            price: prices.saas[el.code],
            color: el.color
          })
        }
      })

      return {
        discountList: availableDiscount,
        tariffList
      }
    }
  },
  methods: {
    /**
     * Работа с дробными в ценах
     * @param price
     * @param symbols
     * @returns {number}
     */
    priceFraction (price, symbols) {
      const originalPrice = Number(price).toFixed(symbols)
      const fraction = originalPrice.split('.')[1]

      if (fraction === '00') {
        symbols -= 2
      } else if (fraction.slice(-1) === '0') {
        symbols -= 1
      }

      let result = (Math.round(price * 100) / 100)

      if (typeof symbols !== 'undefined' || symbols > 0) {
        result = result.toFixed(symbols)
      }

      return result
    },
    /**
     * Получаем сумму и текущую скидку для страниц с ценами (цены по api передаются)
     * @param price
     * @param condition
     * @param country
     * @returns {{price: number, discount: number}}
     */
    calculatePrice (price, condition, country) {
      let symbols = 0
      let correctedPrice = 0

      const countryConditions = [
        {
          country: 'by', // код страны
          symbols: 1 // символов после запятой
        },
        {
          country: 'ua',
          symbols: 2
        }
      ]

      let newPrice = price

      if (condition !== 1) {
        newPrice = (price * condition).toPrecision(21)
      }

      const discount = Math.round(100 - ((newPrice / price) * 100))

      if (typeof countryConditions.find((x) => x.country === country) !== 'undefined') {
        symbols = countryConditions.find((x) => x.country === country).symbols
        correctedPrice = this.priceFraction(newPrice, symbols)
      }

      symbols ? newPrice = correctedPrice : newPrice = Math.round(newPrice)

      return { price: newPrice, discount }
    },
    /**
     * Собираем информацию о тарифе
     * @param tariffCode
     * @returns {[]}
     */
    getTariffInfo (tariffCode) {
      const tariffInfo = this.getTariffList(this.type).find((e) => e.code === tariffCode)
      let locale = []
      if (typeof this.$t('pages.components.prices.blocks').find((e) => e.title === tariffInfo.name) !== 'undefined') {
        locale = this.$t('pages.components.prices.blocks').find((e) => e.title === tariffInfo.name)
      }
      const links = [
        {
          code: 'collaborationplus',
          link: '/collaboration/'
        },
        {
          code: 'collaborationplusbusiness',
          link: '/collaboration-business/'
        },
        {
          code: 'crmplus',
          link: '/crm-for-clients/'
        },
        {
          code: 'crmbusiness',
          link: '/crm-biznes-process/'
        }
      ]
      const tariffLinks = links.find((e) => e.code === tariffCode)
      if (tariffLinks) {
        locale.href = tariffLinks.link
      }
      locale.product = tariffInfo.code
      return locale
    },
    /**
     * Выводим информацию о текущих скидках
     * @returns {Array}
     */
    getDiscountDescription (country) {
      const tariffForCalculate = this.priceContent.tariffList.cloud[0].price
      const list = []

      this.priceContent.discountList.forEach((el) => {
        const calculateDiscount = this.calculatePrice(tariffForCalculate, el.factor, country)
        if (calculateDiscount.discount > 0) {
          list.push({ month: el.month, discount: calculateDiscount.discount })
        }
      })

      return list
    },
    /**
     * Выводим цену и скидку
     * @param price
     * @param tab
     * @param type
     * @param month
     * @returns {string}
     */
    getPrice (price, tab, type = 'price', month = 12) {
      if (tab === 'cloud') {
        const { factor } = this.priceContent.discountList.find((x) => x.month === month)
        const newPrice = this.calculatePrice(price, factor, this.lang)
        return newPrice[type].toLocaleString()
      }
      return price.toLocaleString()
    },
    getPriceNew (price, tab, type = 'price', month = 12) {
      if (tab === 'cloud' && (month !== 6)) {
        const { factor } = this.priceContent.discountList.find((x) => x.month === month)
        const newPrice = this.calculatePrice(price, factor, this.activeLanguage)
        return newPrice[type].toLocaleString().replace(/,/g, ' ')
      }
      return price.toLocaleString().replace(/,/g, ' ')
    },
    getSpecialPrice (item, param = 'price') {
      let price
      if (this.type === 'box' && this.count !== 'unlimited') {
        price = item[this.activeLanguage].prices.find((x) => x.month === this.count)
      } else {
        price = item
      }
      let result = 'По запросу'
      if (this.isExist(price) && price[param] !== 'По запросу') {
        result = price[param].toLocaleString().replace(/,/g, ' ')
      }
      return result
    },
    /**
     * Подготовка списка для вывода
     */
    prepareList (type, list, description = true, table = this.table) {
      const list1 = []
      const list2 = []
      const list3 = []
      const compareTable = this.$t(`pages.${table}.features`)

      const pushToResult = (item) => {
        const lists = {
          1: list1,
          2: list2,
          3: list3
        }
        if (this.isExist(lists[item.group]) && !this.isIgnoredItem(item)) {
          lists[item.group].push(description ? compareTable[item.code] : item)
        }
      }

      for (let i = 0; i < list.length; i += 1) {
        pushToResult(list[i])
      }

      const result = [
        { q: 'l0', res: list1 },
        { q: 'l1', res: list2 },
        { q: 'l2', res: list3 }
      ]

      return result.find((x) => x.q === type).res
    },
    /**
     * Получаем описание тарифа
     * @param type
     * @param component
     * @returns {Array|*}
     */
    getDescription (type, component = this.table) {
      return this.prepareList(type, this.convertLocaleString(tables, component).features, true, component)
    },
    /**
     * Кастомное описание в тарифе, типа неограниченно или 20гб пространства
     * @param tariffCode
     * @param code
     * @param component
     * @returns {string}
     */
    getCustomTariffDescription (tariffCode, code, component) {
      const wrapper = this.$t(`pages.${component}.customFeatureText['${tariffCode}']`)
      let wrapperText = ''
      let wrapperTabs = []
      if (typeof wrapper !== 'string') {
        wrapper.map((el) => {
          if (el.code === code) {
            wrapperText = el.value
            wrapperTabs = this.convertLocaleString(tables, component).customFeatureText[tariffCode][code]
          }
        })
      }
      return { text: wrapperText, tabs: wrapperTabs }
    },
    /**
     * Функция вывода описаний тарифа
     * @param type
     * @param tariffCode
     * @param component
     * @returns {Array|*}
     */
    getTariffFeatures (type, tariffCode, component = this.table) {
      const self = this
      const list = []

      this.convertLocaleString(tables, component).features.forEach((el) => {
        const isTariff = el.tariffs.indexOf(tariffCode)
        if (!self.isIgnoredItem(el)) {
          if (isTariff !== -1) {
            const { group } = el
            let text = ''
            let icon = 'yes'
            let tabs = ['cloud', 'box']

            const customText = self.getCustomTariffDescription(tariffCode, el.code, component)
            if (customText.tabs.includes(self.tab)) {
              text = customText.text
              icon = 'allow'
              tabs = customText.tabs
            }

            list.push({ group, icon, text, tabs })
          } else {
            list.push({ group: el.group, icon: 'no', text: '', tabs: ['cloud', 'box'] })
          }
        }
      })

      return this.prepareList(type, list, false, component)
    },
    getTariffList (type = this.tab) {
      return this.priceContent.tariffList[type]
    },
    getTariffListByCount (type = this.tab) {
      return this.tariffContent.tariffList[type]
    },
    /**
     * Конвертация строки для i18n в ключи объекта
     * @param obj
     * @param str
     * @returns {Array|*}
     */
    convertLocaleString (obj, str) {
      return str.split('.').reduce((o, x) => o[x], obj.pages)
    },
    /**
     * Проверка пропуска значения в регионах
     * @param item
     * @returns Boolean
     */
    isIgnoredItem (item) {
      return this.isExist(item.ignore) && item.ignore.includes(this.activeLanguage)
    }
  }
}
