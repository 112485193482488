

export default {
  name: 'SecurityPage',
  components: {
    DarkBackgroundWrapper: () => import('../components/blocks/dark-bg-wrapper.vue'),
    Benefits: () => import('../components/elements/benefits.vue'),
    SplitScrollingBlock: () => import('../components/blocks/split-scrolling-block.vue'),
    QuoteBlock: () => import('../components/blocks/quote-block.vue'),
    CompanyDetails: () => import('../components/blocks/company-details.vue'),
    FaqBlock: () => import('../components/blocks/faq-block.vue'),
    FormElements: () => import('../components/forms/new/form-elements.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    formSubmitted: false
  }),
  methods: {
    getFormParams () {
      return {
        formEmailName: 'security',
        formSuccessMessage: this.$t('regForm.defaultSuccessMessage'),
        formEmailTitle: this.$t('pages.security.form.emailTitle'),
        styleName: 'contacts',
        inputStyle: 'contacts',
        formFields: [
          {
            name: 'name'
          },
          {
            name: 'email',
            validateOnKey: true,
            required: true,
            rule: 'email'
          },
          {
            name: 'phone',
            validateOnKey: true,
            required: true,
            rule: 'phone'
          }
        ]
      }
    },
    toggleFormStatus (value) {
      this.formSubmitted = value
    }
  }
}

