
import { CIcon } from 'site-lib'

export default {
  name: 'SubscribeBlock',
  components: {
    CIcon
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    mini: {
      type: Boolean,
      required: false,
      default: false
    },
    noContainer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    email: '',
    showButton: false,
    message: null,
    loadPopupObject: '',
    showPopupObject: true
  }),
  computed: {
    telegramLink () {
      return this.complexInsertinon(this.$t('pages.components.subscribe.telegram'), /{link}/,
          `<a href="https://t.me/thebigplans" target="_blank" rel="nofollow"><span>${this.$t('pages.components.subscribe.link')}</span><span>></span></a>`)
    }
  },
  watch: {
    email (val) {
      this.showButton = val.length > 0
    }
  },
  methods: {
    async subscribe () {
      const modalTitleSuccess = this.$t('pages.modals-text-subscription.success.title')
      const modalMessageSuccess = this.$t('pages.modals-text-subscription.success.message')
      const modalMessageErrorsMemberExist = this.$t('pages.modals-text-subscription.errors.emailExist')
      const modalTitleErrors = this.$t('pages.modals-text-subscription.errors.title')
      const modalErrorsCloseButtonText = this.$t('pages.modals-text-subscription.errors.close-button-text')
      const modalSuccessCloseButtonText = this.$t('pages.modals-text-subscription.success.close-button-text')
      const subscribeModalName = 'modal-subscribe'
      if (this.checkEmail()) {
        this.showPopup(subscribeModalName, {
          dialog: true,
          modalTitle: '',
          modalMessage: '',
          preloader: true,
          closeButton: false
        })
        const result = await this.$api('sendMail', 'subscribe', { email: this.email })
        this.closePopup(subscribeModalName)
        this.showPopup(subscribeModalName, {
          dialog: true,
          modalTitle: modalTitleSuccess,
          modalMessage: modalMessageSuccess,
          closeButtonText: modalSuccessCloseButtonText
        })
        if (result.result === 'success') {
          this.email = ''
          setTimeout(() => {
            this.closePopup(subscribeModalName)
          }, 5000)
        } else {
          let modalMessage = ''
          if (result.result === 'errorMemberExist') {
            modalMessage = modalMessageErrorsMemberExist
          }
          this.showPopup(subscribeModalName, {
            dialog: true,
            modalTitle: modalTitleErrors,
            modalMessage,
            closeButtonText: modalErrorsCloseButtonText
          })
        }
      } else {
        this.message = this.$t('pages.components.subscribe.error')
      }
    }
  }
}
