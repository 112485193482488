
import { DButton } from 'site-lib'
import clientsQuotes from '../../plugins/json/clientsQuotes.json'

export default {
  name: 'QuoteBlock',
  props: {
    hasButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    DButton,
    SliderMultipleItems: () => import('../UI/slider-multiple-items.vue'),
    ClientQuoteCard: () => import('../elements/client-quote-card.vue')
  },
  computed: {
    quotesSlides () {
      return clientsQuotes.comments
    }
  },
  methods: {
    buttonClick () {
      this.$router.push('/clients/')
    }
  }
}
