

export default {
  name: 'IntegrationsBlock',
  components: {
    MCButton: () => import('../UI/buttons/mc-button.vue')
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    typeFor: {
      type: String
    }
  }
}
