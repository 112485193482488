

export default {
  name: 'MCButton',
  props: {
    containerType: {
      type: String,
      required: false,
      default: 'hug'
    },
    defaultState: {
      type: String,
      required: false,
      default: ''
    },
    additionalClass: {
      type: String,
      required: false,
      default: ''
    },
    newButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getClassList () {
      return this.newButton
        ? `${this.containerType} ${this.additionalClass} new`
        : `${this.containerType} ${this.additionalClass}`
    }
  }
}
