
import { CIcon, DButton } from 'site-lib'
import UserDevice from '../../../plugins/utils/userDevice'
import PricesHelpers from '../../../mixins/prices'
import Helpers from '../../../mixins/helpers'
import tables from '../../../plugins/json/calculation/tables.json'

export default {
  name: 'compare-table-new',
  mixins: [PricesHelpers, Helpers],
  components: {
    CIcon,
    DButton
  },
  props: {
    tariffList: {
      type: [Array, Object],
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showTable: false,
      iconName: 'ARROW_SIDE_DOWN',
      features: tables.pages.components.compareTableNew.features,
      isMobile: undefined,
      order: 0,
      headingText: this.$t('pages.calculation.compareTextClose')
    }
  },
  mounted () {
    if (UserDevice().device !== undefined) {
      this.isMobile = UserDevice().device !== 'desktop'
    } else {
      this.isMobile = window.matchMedia('(max-width: 1023px)').matches
    }
  },
  computed: {
    activeTariff () {
      if (this.isMobile && this.type === 'box' && this.order === 4) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.order -= 1
      }
      return this.tariffList[this.order]
    }
  },
  methods: {
    switchForward () {
      if (this.order < this.tariffList.length - 1) {
        this.order += 1
      } else {
        this.order = 0
      }
    },
    switchBackward () {
      if (this.order > 0) {
        this.order -= 1
      } else {
        this.order = this.tariffList.length - 1
      }
    },
    groupFeature (group) {
      return [...new Set(this.features.filter((e) => e.group === group).flatMap((e) => e.tariffs))]
    },
    changeTick (name, tariffCode) {
      return (name === 'Настройка ролей и прав доступа' || name === 'Настройка автоматических действий')
        && tariffCode === 'crmplus'
    },
    getText (name) {
      if (name === 'Настройка ролей и прав доступа') {
        return this.$t('pages.calculation.compare-table.tick-text.roles')
      }
      if (name === 'Настройка автоматических действий') {
        return this.$t('pages.calculation.compare-table.tick-text.actions')
      }
      return ''
    }
  }
}
