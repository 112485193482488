export default {
  async getRouteList ({ commit }) {
    const [blog, news, video] = await Promise.all([
      this.$api('blogCategories', 'get', { siteId: 2 }),
      this.$api('blogCategories', 'get', { siteId: 1 }),
      this.$api('blogCategories', 'get', { siteId: 3 })
    ])
    const result = [...blog, ...news, ...video]
    result.forEach((category) => {
      const route = {
        slug: category.slug,
        name: category.title
      }
      commit('addRoute', route)
    })
  }
}
