import FingerprintJS from '@fingerprintjs/fingerprintjs'
import AdBlockDetect from '../plugins/utils/adblockDetect'
import UserDevice from '../plugins/utils/userDevice'
import ErrorHelper from './formErrorsHelper'
import AbTest from './abtest'
import analytics from './analytics'

export default {
  mixins: [ErrorHelper, AbTest, analytics],
  data () {
    return {
      emailField: '',
      phoneField: '',
      accountNameField: '',
      smsField: '',
      fullNameField: null,
      promoCodeField: null,
      shortNameField: '',
      surnameField: '',
      companyField: '',
      departmentField: '',
      employeesNumberField: '',
      regSourceParameter: '',
      megaplanHeadPurpose: null,
      regBtnText: this.$t('regForm.button'),
      firstFocus: false,
      oauthServices: 'yandex,ok,vkontakte,mail',
      oauthMetaData: null,
      metaData: null,
      cid: null,
      result: {
        code: null,
        message: null
      },
      accountRegistered: null,
      waitForMobileRegistration: false,
      quizFinished: false,
      registrationIsChecked: false,
      userDeviceIsMobile: undefined
    }
  },
  beforeMount () {
    this.oauthMetaData = this.getOauthMetaData()
  },
  mounted () {
    if (UserDevice().device !== undefined) {
      this.userDeviceIsMobile = UserDevice().device !== 'desktop'
    } else {
      this.userDeviceIsMobile = window.matchMedia('(max-width: 1023px)').matches
    }
    if (this.cid === null) {
      const fpPromise = FingerprintJS.load();
      (async () => {
        const fp = await fpPromise
        const result = await fp.get()
        const { visitorId } = result
        this.cid = visitorId
      })()
    }
    if (this.getPromocode) {
      this.promoCodeField = this.getPromocode
    }
  },
  computed: {
    /**
     * Получаем текущий домен
     * @returns {string}
     */
    currentDomain () {
      return process.browser && this.isExist(window) && this.isExist(window.location.hostname)
        ? window.location.hostname
        : new URL(process.env.HOST_NAME).host
    },
    /**
     * Получаем выбранный продукт для регистрации
     * @returns {any}
     */
    getSelectedProduct () {
      return this.$route.query.product || this.$store.getters['account/getSelectedProduct']
    },
    /**
     * Получаем промокод из адресной строки
     * @returns {string}
     */
    getPromocode () {
      return this.isGiftPage && this.$route.query.promocode
    }
  },
  methods: {
    loadMetaData () {
      let metaData = null
      try {
        metaData = this.isExist(this.$route.query.meta)
          ? this.base64toString(this.$route.query.meta)
          : localStorage.getItem('metaData')
        metaData = JSON.parse(metaData)
      } catch (e) {
        // e @todo выводить ошибку в dev через компонент
      }
      return metaData || {}
    },
    /**
     * Мета-параметры регистрации
     * @returns {Object}
     */
    getMetaData () {
      if (this.metaData === null) {
        this.fillMetaData()
      }
      const metaData = { ...this.metaData }
      metaData.product = this.getSelectedProduct
      metaData.source += ',email_register:enableDefault'
      if (this.regSourceParameter) {
        metaData.source += `,${this.regSourceParameter}`
      }
      /** Добавляем аб-параметры в "Источник регистрации" */
      let abData = localStorage.getItem('ab_data')
      if (abData !== null) {
        abData = JSON.parse(abData)
        Object.keys(abData).map((abName) => {
          if (this.abEnable(abName) && Object.prototype.hasOwnProperty.call(abData, abName)) {
            metaData.source += `,${abName}:${abData[abName]}`
          }
        })
      }
      return metaData
    },

    setRegistrationComment (comment) {
      this.$store.commit('account/setComment', comment)
    },

    getRegistrationComment () {
      return this.$store.getters['account/getComment']
    },

    removeURLParameter (url, parameter) {
      const regExp = new RegExp(`${parameter}=[^&#]*&*`, 'g')
      return url.replace(regExp, '')
    },

    /**
     * Заполняем мето-параметры для регистрации
     * @returns {Object}
     */
    fillMetaData () {
      if (!process.browser) {
        this.metaData = {}
        return
      }
      let metaData = this.loadMetaData()
      if (typeof metaData === 'string') {
        metaData = JSON.parse(metaData)
      }
      const queryParams = this.$route.query
      const cleanFullPath = this.removeURLParameter(this.$route.fullPath, 'meta')
      const fullUri = `${document.location.protocol}//${this.currentDomain}${cleanFullPath}`
      if (queryParams.meta) {
        this.$store.commit('locale/wasSwitched', true)
        this.$router.push(cleanFullPath)
      }

      /** "UTM-откуда пришел" - переопределеяем, если он пустой или открыли ссылку с параметром utm_source */
      if (!this.isExist(metaData.utm_source) || (this.isExist(queryParams.utm_source) && !this.$store.getters['locale/getWasSwitched'])) {
        metaData.utm_source = fullUri
      }

      const formType = this.getActivePopup === 'reg-form'
        ? 'popup'
        : 'inline'

      if (process.browser) {
        /** "Источник регистрации" - всегда текущая страница + параметры браузера и аб-параметры (заполняются позже) */
        metaData.source = `${fullUri}@os:${UserDevice().os},device:${UserDevice().device}`
        metaData.source += `,adBlock:${AdBlockDetect()}`
        metaData.source += `,formType:${formType}`
        /** Реферальные и партнерские параметры. Переопределяем, если переданы */
        metaData.ref = queryParams.ref || metaData.ref || ''
        metaData.partner = queryParams.partner || metaData.partner || ''
        metaData.product = this.getSelectedProduct
        localStorage.setItem('metaData', JSON.stringify(metaData))
      }
      this.metaData = metaData
    },
    stringToBase64 (string) {
      return Buffer.from(string, 'binary').toString('base64')
    },
    base64toString (string) {
      return Buffer.from(string, 'base64').toString()
    },
    /**
     * Формирует стркоку с мета-параметрами для oauth-регистрации
     * @returns {Object}
     */
    getOauthMetaData () {
      return this.stringToBase64(JSON.stringify(this.getMetaData()))
    },

    getCid () {
      const str = `${Date.now()}|${this.cid}`

      // eslint-disable-next-line no-bitwise
      const enc = this.stringToBase64(str)
      return enc.substring(0, enc.length - 2)
    },

    /**
     * Проверка перед отправкой
     */
    async beforeCreate () {
      this.clearErrors()
      const checkResult = await this.$api('account',
        'checkFields',
        this.collectFormData())
      if (checkResult.status && checkResult.status.code === 'ok') {
        localStorage.setItem('registerData', JSON.stringify(this.collectFormData()))
        this.$router.push('/creating/', () => {
          this.sendPageView()
        })
      } else if (checkResult.errors) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const fieldName in checkResult.errors) {
          this.setError(fieldName, checkResult.errors[fieldName])
        }
      } else {
        this.setError('Email', this.$t('regForm.undef'))
      }
    },
    /**
     * Регистрация с лендинга на тильде
     */
    checkRegFromTilda (query) {
      if (query.type === 'tilda' && query.useremail) {
        this.emailField = query.useremail
        localStorage.setItem('registerData', JSON.stringify(this.collectFormData()))
      }
    },
    checkOauthReg (query) {
      if (query.type === 'oauth' && query.email) {
        this.emailField = query.email
        this.fullNameField = `${query.firstName} ${query.lastName}`
        localStorage.setItem('registerData', JSON.stringify(this.collectFormData()))
      }
    },
    checkPartnerSite (query) {
      if (query.partner && query.email) {
        this.emailField = query.email
        this.fullNameField = query.fullName
        this.phoneField = query.phone
        this.accountNameField = query.accountName
        localStorage.setItem('registerData', JSON.stringify(this.collectFormData()))
      }
    },
    async registrationProcess (captchaToken = null) {
      const query = this.$route.query
      this.checkRegFromTilda(query)
      this.checkOauthReg(query)
      this.checkPartnerSite(query)
      this.showCaptcha = false
      const registerParams = JSON.parse(localStorage.getItem('registerData'))
      if (Object.keys(registerParams).length === 0) {
        this.result = {
          code: 'error',
          message: this.$t('pages.creating.form-error')
        }
        return false
      }
      if (this.isExist(captchaToken)) {
        registerParams.captchaToken = captchaToken
      }
      const isCaptchaRequired = await this.$api('account', 'isCaptchaRequired')
      if (isCaptchaRequired && !this.isExist(captchaToken)) {
        this.showCaptcha = true
        return false
      }
      if (!this.needShowMobileQuiz()) {
        this.progressBarFilling()
      }
      this.result = await this.createAccount(registerParams)
      if (this.result.code === 'error' && (this.result.message === 'User with same login is already exists' || this.result.message === 'Аккаунт с такой электропочтой уже зарегистрирован')) {
        this.showPopup('reg-error')
        return false
      }
      await this.sendGoalEvent('order')
      const { code, accountUrl, login, pass, message } = await this.afterCreate(this.result)
      if (code === 'ok') {
        if (!this.needShowMobileQuiz()) {
          window.location = accountUrl
          return true
        }
        // eslint-disable-next-line
        if (!this.waitForMobileRegistration) {
          await this.$router.push({
            name: 'creating-mobile',
            query: {
              accountUrl,
              login,
              pass
            }
          })
        } else {
          this.accountRegistered = {
            accountUrl,
            login,
            pass
          }
          if (process.browser) {
            window.sessionStorage.setItem('accountLocalData', JSON.stringify(this.accountRegistered))
          }
          this.checkNextStep()
        }
      } else {
        this.result = { code, message }
      }
      return true
    },

    collectFormData () {
      const description = ''
      const metaData = this.getMetaData()
      const formData = {
        AutoName: '1',
        Product: this.getSelectedProduct,
        Referer: metaData.utm_source,
        Email: this.emailField,
        AccountName: this.accountNameField,
        Phone: this.phoneField,
        FullName: this.fullNameField,
        RegistrationSource: metaData.source,
        ref: metaData.ref,
        PartnerId: metaData.partner,
        Description: description,
        Comment: this.getRegistrationComment(),
        Lang: this.activeLanguage,
        Cid: this.getCid()
      }
      if (
        this.isExist(window.yaCounter20277427)
        && this.isExist(window.yaCounter20277427.getClientID())
      ) {
        formData.YandexClientId = window.yaCounter20277427.getClientID()
      }
      // eslint-disable-next-line no-undef
      const gaObj = window.ga
      if (this.isExist(gaObj)
        && typeof gaObj.getAll === 'function'
        && this.isExist(gaObj.getAll()[0])
        && typeof gaObj.getAll()[0].get === 'function'
      ) {
        // eslint-disable-next-line no-undef
        formData.Ga = ga.getAll()[0].get('clientId')
      }

      if (this.promoCodeField) {
        formData.PromoCode = this.promoCodeField
      }

      return formData
    },
    /**
     * Отправляем данные для создания аккаунта в МП
     */
    async createAccount (params) {
      const result = await this.$api('account', 'registerAccount', params)
      if (!this.isExist(result)) {
        return { code: 500, message: this.$t('regForm.fatal') }
      }
      if (result.status.code === 'ok') {
        localStorage.removeItem('registerData')
        return result
      }
      return {
        ...result.status
      }
    },
    /**
     * Проверяем, что МП аккаунт доступен после регистрации
     * @param url
     * @param tryCount
     * @returns {Promise<unknown>}
     */
    isAccountCreated (url, tryCount = 0) {
      return new Promise((resolve, reject) => {
        this.$api('account', 'checkRegister', { url })
          .then((result) => {
            if (result.code === 'error') {
              if (tryCount < 5) {
                setTimeout(() => {
                  tryCount += 1
                  this.isAccountCreated(url, tryCount)
                }, 2000)
              } else {
                reject(new Error('500'))
              }
            } else {
              resolve(result)
            }
          })
      })
    },
    /**
     * Действия после успешной проверки и регистрация
     */
    afterCreate (accountData = null) {
      if (accountData != null && accountData.code === 'error') {
        return false
      }
      this.sendGoalEvent(this.$store.getters['account/getProductTarget'])
      this.setProduct('')
      if (!this.userDeviceIsMobile) {
        this.sendGoalEvent('Registration', {
          registerDate: new Date(), // Дата регистрации
          megaplanAccountUrl: accountData.data.accountUrl // URL аккаунта
        })
      } else {
        this.sendGoalEvent('RegistrationMobile', {
          registerDate: new Date(),
          megaplanAccountUrl: accountData.data.accountUrl
        })
      }
      this.$store.commit('account/setProductTarget', '')

      const accountUrl = `${accountData.data.accountUrl}/login#one_time_key=${accountData.data.OneTimeKey}`
      return this.isAccountCreated(`${accountData.data.accountUrl}/login/`)
        .then(() => (
          {
            code: 'ok',
            accountUrl,
            login: accountData.data.login,
            pass: accountData.data.pass,
            message: null
          }
        ))
        .catch(() => (
          {
            code: 'error',
            accountUrl: null,
            login: null,
            pass: null,
            message: 'Произошла ошибка при создании аккаунта'
          }
        ))
    },
    checkNextStep () {
      this.accountRegistered = this.accountRegistered
        || (process.browser && JSON.parse(window.sessionStorage.getItem('accountLocalData')))
      if (this.accountRegistered && (this.quizFinished || !this.needShowMobileQuiz())) {
        if (UserDevice().device === 'desktop') {
          window.location = this.accountRegistered.accountUrl
        } else {
          this.$router.push({
            name: 'creating-mobile',
            query: this.accountRegistered
          }).then()
        }
      }
    },
    async toRegistrationQuiz () {
      // Важно needShowMobileQuiz вызвать вначале, чтобы данные аб-тест попали в regData
      const needShowMobileQuiz = this.needShowMobileQuiz()
      const regData = this.collectFormData()
      const emailConfirmation = await this.$api('account',
        'checkFields',
        regData)
      if (emailConfirmation.status && emailConfirmation.status.code === 'ok') {
        localStorage.setItem('registerData', JSON.stringify(regData))
        this.closePopup('reg-form')
        if (needShowMobileQuiz) {
          this.showPopup('registration-quiz')
        } else {
          this.$router.push('/creating/', () => {
            this.sendPageView()
          })
        }
      } else if (emailConfirmation.errors) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const fieldName in emailConfirmation.errors) {
          this.setError(fieldName, emailConfirmation.errors[fieldName])
        }
      } else {
        this.setError('Email', this.$t('regForm.undef'))
      }
    },
    needShowMobileQuiz () {
      return this.userDeviceIsMobile
    },
    needShowDesktopQuiz () {
      if (!this.userDeviceIsMobile && this.userDeviceIsMobile !== undefined) {
        const showQuizSetting = process.env.SHOW_QUIZ
        if (showQuizSetting === 'on') {
          return true
        }
        if (showQuizSetting === 'ab') {
          return this.getAbValue('showQuiz') === 'on'
        }
      }
      return false
    }
  }
}
