
export default {
  components: {
    PostContent: () => import('~/components/pages/blog/blog-post-content.vue')
  },
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    itemsProp: {
      type: Array,
      default: () => ([])
    },
    popularsProp: {
      type: Array,
      default: () => ([])
    },
    showLinks: {
      type: [String, Number],
      default: ''
    },
    comments: {
      type: Array,
      required: false
    },
    showComments: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      items: this.itemsProp,
      populars: this.popularsProp
    }
  },
  methods: {
    async showPopulars () {
      try {
        const populars = await this.$api('blogPosts', 'list', {
          categoryId: this.post.articleCategoryId,
          siteId: 2,
          limit: 4,
          popular: 1
        })
        const items = await this.$api('blogPosts', 'list', {
          siteId: 2,
          limit: 4
        })
        const blogItems = items.items.filter((el) => el.articleSlug !== this.post.articleSlug)
        const blogPopulars = populars.items.filter((el) => el.articleSlug !== this.post.articleSlug)
        if (blogItems.length === 4) {
          blogItems.splice(-1)
        }
        if (blogPopulars.length === 4) {
          blogPopulars.splice(-1)
        }
        this.items = blogItems
        this.populars = blogPopulars
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  },
  async mounted () {
    if (process.browser) {
      setTimeout(() => {
        this.showPopulars()
      }, 2000)
    }
  }

}
