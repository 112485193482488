
import { DButton } from 'site-lib'

export default {
  name: 'Card',
  components: {
    DButton
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    buttonClick () {
      this.$router.push(this.card.link)
    }
  }
}
