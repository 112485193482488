import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/blog-posts/`

function list (params) {
  const query = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
  return request(API_URL, `list?${query}`, null, 'get')
}

function article (params) {
  const query = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
  return request(API_URL, `article?${query}`, null, 'get')
}

/**
 * item, limit = 9, siteId = 1, debug = null
 */
function search (params) {
  return request(API_URL, 'search', params)
}

/**
 * postId
 */
function updateViews (params) {
  return request(API_URL, 'update-views', params)
}

export {
  list,
  article,
  search,
  updateViews
}
