import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/blog-comments/`

function getLocalComments (postUrl) {
  return request(API_URL, `get-comments?chan=${postUrl}`, null, 'get')
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getLocalComments
}
