

export default {
  name: 'ModalContainer',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    params: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    currentPopup () {
      const popupName = this.name
      // eslint-disable-next-line prefer-template
      return () => import('~/components/popup/new/' + popupName)
    }
  },
  methods: {
    stopP (e) {
      e.stopPropagation()
    }
  },
  watch: {
    /**
     * Закрываем попап окно при смене роута
     */
    $route () {
      if (process.browser) {
        document.querySelector('body').style.overflow = ''
      }
      this.$root.$emit('popupGenerator', this.name, false)
    }
  }
}
