
export default {
  name: 'CackleComments',
  props: {
    comments: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (process.browser) {
        window.cackle_widget = []
        window.cackle_widget.push({
          widget: 'Comment',
          id: 80296,
          instant: true,
          msg: {
            placeholder: 'Чтобы оставить комментарий, сначала авторизуйтесь'
          }
        });
        // eslint-disable-next-line func-names
        (function () {
          const mc = document.createElement('script')
          mc.type = 'text/javascript'
          mc.async = true
          mc.src = `${document.location.protocol === 'https:' ? 'https' : 'http'}://cackle.me/widget.js`
          const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(mc, s.nextSibling)
        }())
      }
    })
    const handleLoad = () => {
      const links = this.$el.querySelectorAll('.mc-logo a')
      links.forEach((link) => {
        link.setAttribute('rel', 'nofollow')
      })
    }
    window.addEventListener('load', handleLoad)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('load', handleLoad)
    })
  }
}
