
export default {
  name: 'BlogContent',
  components: {
    BlogSearchResult: () => import('./blog-search-result.vue'),
    BlogAuthorItems: () => import('./blog-author-items.vue'),
    BlogAuthorProfile: () => import('./blog-author-profile.vue'),
    BlogAuthorLoadMore: () => import('./blog-author-load-more.vue'),
    BlogSearch: () => import('./blog-search.vue'),
    BlogMenu: () => import('./blog-menu.vue'),
    SubscribeBlock: () => import('./subscribe.vue')
  },
  props: {
    meta: {
      type: Object,
      required: true
    },
    posts: {
      type: [Array, Object],
      default: () => ([])
    }
  },
  data () {
    return {
      searchResults: null
    }
  }
}
