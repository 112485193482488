
import { CModal } from 'site-lib/index.ts'

export default {
  name: 'FormPopup',
  components: {
    CModal,
    TwoStepFormElements: () => import('~/components/forms/new/two-step-elements.vue')
  },
  props: {
    analyticEvent: {
      type: String,
      required: false,
      default: ''
    }
  }
}
