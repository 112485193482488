
import RU from '../locales/ru.json'
import UA from '../locales/ua.json'
import BY from '../locales/by.json'
import KZ from '../locales/kz.json'

export default {
  name: 'Contacts',
  components: {
    FormElements: () => import('../components/forms/new/form-elements.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    ru: RU,
    ua: UA,
    by: BY,
    kz: KZ,
    formSubmitted: false
  }),
  mounted () {
    this.callTrackingStart()
  },
  methods: {
    getFormParams () {
      return {
        formEmailName: 'contacts',
        formSuccessMessage: this.$t('regForm.defaultSuccessMessage'),
        formEmailTitle: this.$t('pages.contacts.form.emailTitle'),
        styleName: 'contacts',
        inputStyle: 'contacts',
        formFields: [
          {
            name: 'name'
          },
          {
            name: 'email',
            validateOnKey: true,
            required: true,
            rule: 'email'
          },
          {
            name: 'phone',
            validateOnKey: true,
            required: true,
            rule: 'phone'
          }
        ]
      }
    },
    toggleFormStatus (value) {
      this.formSubmitted = value
    }
  }
}
