

/**
   * Форма поиска для блога
   */
export default {
  name: 'BlogSearch',
  components: {
    CIcon: () => import('../../site-lib/src/components/CIcon/CIcon.vue')
  },
  props: {
    siteId: {
      type: Number,
      default: 1
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchItem: '',
      result: null,
      inputCollapsed: true,
      blogSearchClass: 'collapsed'
    }
  },
  computed: {
    placeholder () {
      return this.siteId === process.env.sectionBlogId
        ? this.$t('pages.blog.search')
        : this.$t('pages.news.search')
    }
  },
  async fetch () {
    await this.searchAction()
  },
  methods: {
    openInput () {
      this.inputCollapsed = false
      this.blogSearchClass = ''
      this.$emit('openInput', true)
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
    },
    closeInput () {
      this.inputCollapsed = true
      this.blogSearchClass = 'collapsed'
      this.searchItem = ''
      this.result = null
      this.$emit('ready', null)
      this.$emit('openInput', false)
    },
    async searchAction () {
      if (this.isExist(this.searchItem) && this.searchItem.length > 3) {
        const result = await this.$api('blogPosts', 'search', {
          searchItem: this.searchItem,
          limit: 9,
          siteId: this.siteId
        })
        this.result = this.isExist(result.items)
          ? result.items
          : null
        this.$emit('ready', this.result)
      } else {
        this.$emit('ready', null)
      }
    }
  }
}

