
import { CModal } from 'site-lib/index.ts'

export default {
  name: 'make-order',
  components: {
    CModal,
    MakeOrderForm: () => import('../../forms/new/make-order-form.vue')
  },
  data: () => ({
    model: {},
    showPopupAfterSubmit: false
  }),
  computed: {
    formFields () {
      return [
        {
          name: 'name',
          autofocus: true
        },
        {
          name: 'email',
          required: true,
          validateOnKey: true,
          rule: 'email'
        },
        {
          name: 'phone',
          required: true,
          validateOnKey: true,
          rule: 'phone'
        },
        {
          name: 'company'
        }
      ]
    }
  },
  methods: {
    getTranslation (key, alternativeKey = '', alternativeKeyVariables = {}) {
      if (this.$te(key) || this.$te(key, 'ru')) {
        return this.$i18n.nl2br(key)
      }
      if (alternativeKey) {
        return this.$i18n.nl2br(alternativeKey, [], alternativeKeyVariables)
      }
      return ''
    },
    /**
     * Проверяем поля формы
     * на пустоту
     */
    checkEmptyModelField () {
      let result = true
      Object.keys(this.model).forEach((key) => {
        if (this.model[key].length > 0) {
          result = false
        }
      })
      return result
    },
    /**
     * Проверяем поля формы
     * на полную заполненность
     */
    checkNoEmptyAllFields () {
      const fieldNames = []
      let result = true
      this.formFields.forEach((item) => {
        const nameField = item.name
        fieldNames.push(nameField)
      })

      fieldNames.forEach((key) => {
        if (!this.model[key]) {
          result = false
        }
        if (this.model[key] && this.model[key].length === 0) {
          result = false
        }
      })
      return result
    }
  }
}
