export default {
  head () {
    let links = []
    let title = this.meta?.title
    let description = this.meta?.description
    if (typeof this.meta?.translations !== 'undefined' && typeof this.meta?.translations[this.activeLanguage] !== 'undefined') {
      title = this.meta.translations[this.activeLanguage].title
      description = this.meta.translations[this.activeLanguage].description
    }
    if (typeof this.meta?.canonical !== 'undefined') {
      links = [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://megaplan.ru${this.meta.canonical}`
        }
      ]
    }
    if (this.meta && Object.prototype.hasOwnProperty.call(this.meta, 'customMeta')) {
      const customMeta = this.meta.customMeta
      if (customMeta && customMeta.title) {
        title = customMeta.title
      }
      if (customMeta && customMeta.description) {
        description = customMeta.description
      }
    }
    return {
      title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        }
      ],
      link: links
    }
  }
}
