
import RegButtonAmp from '../../forms/reg-button-amp.vue'
import AmpBottomBanner from '../blog/amp-bottom-banner.vue'

export default {
  name: 'AmpCrmPost',
  props: ['post', 'meta'],
  computed: {
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.post) {
        mdTemplate = this.$md.render(this.post)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate,
        components: {
          RegButton: () => import('../../forms/reg-button-amp.vue')
        }
      }
    },
    canonicalUrl () {
      return process.env.HOST_NAME + this.$route.fullPath.replace(/\/amp\//, '/')
    }
  },
  components: {
    RegButtonAmp,
    AmpBottomBanner
  },
  head () {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.canonicalUrl
        }
      ]
    }
  }
}
