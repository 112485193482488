import request from './request'

const API_URL = '/adm/api/send-mail/'

function send (params) {
  return request(API_URL, 'index', params)
}

function subscribe (params) {
  return request(API_URL, 'subscribe', params)
}

export {
  send,
  subscribe
}
