
export default {
  name: 'ApiIntegrationPage',
  components: {
    Tabs: () => import('../components/elements/tabs.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue'),
    MCButton: () => import('../components/UI/buttons/mc-button.vue')
  },
  async asyncData () {
    let integrationsJson
    await import('../plugins/json/api-integration/integrationsList.json').then((res) => {
      integrationsJson = res.default
    })
    return {
      integrations: integrationsJson
    }
  },
  methods: {
    regAction () {
      const formParams = { regSourceOption: 'startUrl:/settings/application/' }
      this.showPopup('reg-form', formParams)
    }
  },
  data () {
    return {
      activeTab: 'cloud',
      tabItems: [{ title: this.$t('pages.api-integration.cloud'), tab: 'cloud' }, { title: this.$t('pages.api-integration.box'), tab: 'box' }]
    }
  }
}
