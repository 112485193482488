
export default {
  name: 'AboutPage',
  components: {
    Achievements: () => import('../components/blocks/achievements.vue'),
    CompanyDetails: () => import('../components/blocks/company-details.vue'),
    Awards: () => import('../components/blocks/awards.vue'),
    FormElements: () => import('../components/forms/new/form-elements.vue'),
    CompanyTimeline: () => import('../components/UI/company-timeline.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data () {
    return {
      formSubmitted: false
    }
  },
  methods: {
    buttonClick () {
      window.open('https://hh.ru/employer/581294', '_blank').focus()
    },
    toggleFormStatus (value) {
      this.formSubmitted = value
    },
    getFormParams () {
      return {
        header: this.$t('pages.about.form.header'),
        subHeader: this.$t('pages.about.form.sub-header'),
        formEmailTitle: this.$t('pages.about.form.formEmailTitle'),
        formSuccessMessage: this.$t('regForm.defaultSuccessMessage'),
        styleName: 'about',
        inputStyle: 'about',
        formEmailName: 'about',
        formFields: [
          {
            name: 'name'
          },
          {
            name: 'phone',
            validateOnKey: true,
            required: true,
            rule: 'phone'
          },
          {
            name: 'email',
            validateOnKey: true,
            required: true,
            rule: 'email'
          }
        ]
      }
    }
  }
}
