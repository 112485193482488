

export default {
  name: 'CreateAccount',
  mounted () {
    if (this.$route.query.partnerId || this.$route.query.partner) {
      localStorage.setItem('showRegForm', 'Y')
      const partner = this.$route.query.partnerId || this.$route.query.partner
      this.$router.push({ path: '/', query: { partner } })
    } else {
      this.$router.push('/')
    }
  }
}
