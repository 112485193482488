
import VueRecaptcha from 'vue-recaptcha'
import registerLocal from '../../mixins/register-local'
import callTracking from '../../mixins/callTracking'

export default {
  name: 'RegistrationContent',
  data () {
    return {
      showCaptcha: false,
      stepsCount: 5,
      currentStep: 1,
      isBtnDisabled: false,
      latestPhone: '',
      isPhoneCorrect: false,
      showSmsCodeField: false,
      smsCodeCorrect: false,
      timerValue: 60,
      timerId: null,
      sendSmsAgainIsPossible: false
    }
  },
  components: {
    OnboardingInput: () => import('../UI/inputs/onboarding-input.vue'),
    MCButton: () => import('../UI/buttons/mc-button.vue'),
    SocialRegistration: () => import('../widgets/social-registration.vue'),
    McRadioButton: () => import('../UI/inputs/mc-radio-button.vue'),
    VueRecaptcha
  },
  mixins: [registerLocal, callTracking],
  watch: {
    async smsField (val) {
      if (val && val.length === 4) {
        this.clearError('smsField')
        this.isBtnDisabled = true
        const data = {
          Phone: this.latestPhone,
          Code: val
        }
        const smsCheckResult = await this.$api('account', 'verifyCode', data)
        if (smsCheckResult.status && smsCheckResult.status.code === 'ok') {
          this.isBtnDisabled = false
          this.smsCodeCorrect = true
          await this.sendGoalEvent('smsConfirmed', {
            phone: this.latestPhone
          })
        } else if (smsCheckResult.errors.length) {
          smsCheckResult.errors.forEach((error) => {
            if (error.message) {
              this.setError('smsField', error.message)
            }
          })
        }
      }
    },
    async currentStep (n) {
      if (n === 5) {
        const quizResult = {
          Shortname: this.shortNameField,
          Email: this.emailField,
          Phone: this.phoneField,
          Code: this.smsField,
          Company: this.companyField,
          Employees: this.employeesNumberField,
          Purpose: this.megaplanHeadPurpose,
          Department: this.departmentField
        }
        await this.$api('account', 'accountData', quizResult)
        this.quizFinished = true
        this.timerValue = 3
        this.loadTimerStart()
      }
    }
  },
  mounted () {
    this.callTrackingStart()
  },
  methods: {
    async stepOneHandler () {
      this.clearErrors()
      if (!this.isPhoneCorrect) {
        await this.phoneCheckHandler()
      }
      const formData = this.collectFormData()
      const checkResult = await this.$api('account',
        'checkFields',
        formData)
      if (checkResult.status && checkResult.status.code === 'ok') {
        if (this.isPhoneCorrect && (this.showSmsCodeField === this.smsCodeCorrect)) {
          localStorage.setItem('registerData', JSON.stringify(this.collectFormData()))
          await this.sendGoalEvent('newRegistrationContinued', {
            step: this.currentStep
          })
          this.nextStep()
          await this.accountHandler(formData)
        }
      } else if (checkResult.errors) {
        // eslint-disable-next-line no-restricted-syntax
        for (const error in checkResult.errors) {
          if (error && checkResult.errors[error] !== '') {
            this.setError(error, checkResult.errors[error])
          }
        }
      }
    },
    async phoneCheckHandler () {
      if (this.phoneField && this.phoneField !== this.latestPhone) {
        this.latestPhone = this.phoneField
        await this.checkPhoneNumber(this.phoneField)
      }
    },
    // eslint-disable-next-line
    async checkPhoneNumber (str) {
      const data = {
        Phone: str
      }
      const resStatus = await this.$api('account',
        'checkPhone',
        data)
      if (resStatus.status && resStatus.status.code === 'need_confirm') {
        this.clearError('Phone')
        this.showSmsCodeField = true
        this.isPhoneCorrect = true
        this.phoneTimerStart()
      } else if (resStatus.status && resStatus.status.code === 'phone_error') {
        if (resStatus.errors.length) {
          resStatus.errors.forEach((error) => {
            this.setError(error.field, error.message)
          })
        }
      } else if (resStatus.status && resStatus.status.code === 'ok') {
        this.clearError('Phone')
        this.isPhoneCorrect = true
      }
    },
    changePhone () {
      const input = document.querySelector('input#Phone')
      input.value = ''
      input.focus()
      this.showSmsCodeField = false
    },
    // eslint-disable-next-line consistent-return
    phoneTimerStart () {
      this.timerId = setInterval(() => {
        let remaining = this.timerValue
        if (remaining <= 1) {
          this.sendSmsAgainIsPossible = true
          this.phoneTimerReset()
        }
        remaining -= 1
        this.timerValue = remaining
      }, 1000)
    },
    phoneTimerReset () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = null
      this.timerValue = 60
    },
    loadTimerStart () {
      this.timerId = setInterval(() => {
        let remaining = this.timerValue
        if (remaining === 1) {
          window.location = this.accountRegistered.accountUrl
        }
        remaining -= 1
        this.timerValue = remaining
      }, 1000)
    },
    sendSmsToLatestPhone () {
      this.$api('account', 'sendNewCode', {
        Phone: this.latestPhone
      })
      this.sendSmsAgainIsPossible = false
      this.phoneTimerReset()
      this.phoneTimerStart()
    },
    async accountHandler (data, captchaToken = null) {
      this.showCaptcha = false
      if (this.isExist(captchaToken)) {
        data.captchaToken = captchaToken
      }
      this.result = await this.createAccount(data)
      if (this.result.code === 'captcha_required') {
        this.showCaptcha = true
        return false
      }
      await this.sendGoalEvent('order')
      const { code, accountUrl, login, pass } = await this.afterCreate(this.result)
      if (code === 'ok') {
        this.accountRegistered = {
          accountUrl,
          login,
          pass
        }
        if (process.browser) {
          window.sessionStorage.setItem('accountLocalData', JSON.stringify(this.accountRegistered))
        }
      } else {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        this.showPopup('modal-dialog', {
          dialog: false
        })
      }
      return true
    },
    stepHandler () {
      this.sendGoalEvent('newRegistrationContinued', {
        step: this.currentStep
      })
      this.nextStep()
    },
    closeRegistrationHandler () {
      const userStartsToReg = this.shortNameField || this.phoneField || this.emailField
      if (this.currentStep === 1 && !userStartsToReg) {
        this.$router.back()
      } else {
        this.showPopup('modal-dialog', {
          dialog: true
        })
      }
      this.sendGoalEvent('newRegistrationClosed', {
        step: this.currentStep
      })
    },
    verifyCaptcha (token) {
      this.registrationProcess(token)
    },
    expiredCaptcha () {
      this.$refs.recaptcha.reset()
    },
    nextStep () {
      this.currentStep += 1
      this.isBtnDisabled = !this.isNextStepAvailable
    }
  },
  computed: {
    isCurrentStepBtnDisable () {
      if (this.isBtnDisabled) {
        return 'disabled'
      }
      return ''
    },
    getLoadIndicatorStyle () {
      let multiplier
      switch (this.currentStep) {
      case 2:
        multiplier = 20
        break
      case 3:
        multiplier = 40
        break
      case 4:
        multiplier = 60
        break
      case 5:
        multiplier = 80
        break
      default:
        multiplier = 0
      }
      return `width: ${multiplier}%`
    },
    isNextStepAvailable () {
      switch (this.currentStep) {
      case 1:
        return false
      case 2:
        if (this.companyField && this.employeesNumberField) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isBtnDisabled = false
          return true
        }
        break
      case 3:
        if (this.departmentField) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isBtnDisabled = false
          return true
        }
        break
      case 4:
        if (this.megaplanHeadPurpose) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isBtnDisabled = false
          return true
        }
        break
      default:
        return false
      }
      return false
    },
    siteKey () {
      return process.env.GCAPTCHA_CODE
    }
  },
  beforeDestroy () {
    this.clearErrors()
  }
}
