import userDevice from '../plugins/utils/userDevice'
import { setCookie, getCookie } from './cookie'

export default {
  data () {
    return {
      popupTimerId: null,
      isMobile: null
    }
  },
  beforeMount () {
    this.isMobile = userDevice().device !== 'desktop'
    if (this.isMobile) {
      this.popupTimerId = setTimeout(() => this.showSubscribePopup(), 60000)
    }
    if (!this.isMobile) {
      document.documentElement.addEventListener('mouseleave', this.showSubscribePopup)
    }
  },
  beforeDestroy () {
    if (this.isMobile) {
      clearTimeout(this.popupTimerId)
    } else {
      document.documentElement.removeEventListener('mouseleave', this.showSubscribePopup)
    }
  },
  methods: {
    showSubscribePopup () {
      if (!getCookie('hasSubscribePopupShown')) {
        setCookie('hasSubscribePopupShown', true, { 'max-age': 604800 })
        this.showPopup('articles-subscription')
      }
    }
  }

}
