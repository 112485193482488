
import { mapGetters } from 'vuex'
import Prices from '~/plugins/json/pricesNew.json'
import PricesHelpers from '~/mixins/prices'

export default {
  name: 'price-table-new',
  mixins: [PricesHelpers],
  components: {
    Tabs: () => import('../../elements/tabs.vue'),
    Swch: () => import('../../elements/swch.vue'),
    TariffCard: () => import('./tariff-card.vue')
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    activeTab: {
      type: String,
      default: 'cloud'
    },
    noGlow: {
      type: Boolean,
      default: false
    },
    forYandex: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      actualCountTab: 1,
      actualPeriodTab: 1,
      prices: Prices.data
    }
  },
  created () {
    if (this.type !== this.activeTab) {
      this.setStoreType(this.activeTab)
      this.setCount('30')
      this.actualPeriodTab = 0
    }
  },
  computed: {
    ...mapGetters({
      type: 'priceCalculator/getType',
      count: 'priceCalculator/getCount',
      period: 'priceCalculator/getPeriod'
    }),
    countTabs () {
      if (this.type === 'cloud') {
        return this.$t('pages.components.prices.count.cloud')
      }
      return this.$t('pages.components.prices.count.box')
    },
    periodTabs () {
      if (this.type === 'cloud') {
        switch (this.count) {
        case '5': {
          return this.$t('pages.components.prices.period.lessFive')
        }
        case '100': {
          return this.$t('pages.components.prices.period.lessHundred')
        }
        case 'unlimited': {
          return this.$t('pages.components.prices.period.unlimited')
        }
        default: return []
        }
      }
      return this.$t('pages.components.prices.period.lessFive')
    },
    actualPrices () {
      if (this.type === 'box') {
        return this.prices[this.type].filter((el) => this.isExist(el[this.activeLanguage])
          && el[this.activeLanguage].prices.find((e) => e.month === this.count))
      }
      if (this.teamTariff) {
        return this.prices[this.type].team.filter((el) => this.isExist(el[this.activeLanguage]))
      }
      if (this.type === 'cloud' && this.count === 'unlimited') {
        return this.prices[this.type].special.filter((el) => this.isExist(el[this.activeLanguage]))
      }
      return this.tariffContent.tariffList.cloud.filter((el) => el.code !== 'collaborationplusbusiness')
    },
    teamTariff () {
      return this.type === 'cloud' && this.count === '5'
    },
    hasPeriod () {
      return this.type === 'cloud'
    }
  },
  methods: {
    changeType (data) {
      if (data) {
        this.setStoreType('box')
        this.setCount(this.countTabs[0].tab)
        this.setPeriod(this.periodTabs[0].tab)
        this.actualPeriodTab = 0
        this.actualCountTab = 0
      } else {
        this.setStoreType('cloud')
        this.setCount(this.countTabs[1].tab)
        this.setPeriod(this.periodTabs[1].tab)
        this.actualCountTab = 1
        this.actualPeriodTab = 1
      }
      this.$emit('change-tab', this.type)
    },
    setCount (data) {
      this.$store.commit('priceCalculator/setCount', data)
      if (this.type === 'cloud') {
        const periodIndexTab = this.periodTabs.findIndex((el) => el.tab === this.period)
        this.actualPeriodTab = periodIndexTab >= 0 ? periodIndexTab : 0
        this.setPeriod(this.periodTabs[this.actualPeriodTab].tab)
      }
    },
    setPeriod (data) {
      this.$store.commit('priceCalculator/setPeriod', data)
    },
    setStoreType (type) {
      this.$store.commit('priceCalculator/setType', type)
    }
  },
  beforeDestroy () {
    this.setStoreType('cloud')
    this.setCount('100')
    this.setPeriod('12')
  }
}
