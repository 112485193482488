
/**
   * Компонент для вывода следующей страницы для подгрузки на лету
   */
import { DButton } from 'site-lib'

export default {
  name: 'BlogLoadNextPage',
  components: {
    DButton,
    Loader: () => import('../../loaders/loader.vue')
  },
  props: {
    categoryId: {
      type: Number,
      required: false,
      default: 0
    },
    nextPageNumber: {
      type: Number,
      default: 1
    },
    siteId: {
      type: Number,
      default: 1
    },
    asButton: {
      type: Boolean,
      default: false
    },
    popular: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    nextPagePosts: [],
    showLoader: false,
    hasMorePosts: true,
    itemComponent: {
      news: () => import('./news-items.vue'),
      blogList: () => import('./blog-items-list.vue'),
      video: () => import('~/components/pages/blog/video-items.vue')
    },
    sectionBlogId: process.env.sectionBlogId
  }),
  computed: {
    moreText () {
      switch (this.siteId) {
      case 2:
        return this.$t('pages.blog.more-items')
      case 3:
        return this.$t('pages.video-megaplan.more-items')
      default:
        return this.$t('pages.news.more-items')
      }
    },
    getComponent () {
      switch (this.siteId) {
      case 1:
        return this.itemComponent.news
      case 2:
        return this.itemComponent.blogList
      case 3:
        return this.itemComponent.video
      default:
        return 0
      }
    }
  },
  methods: {
    /**
         * Загрузка новой страницы
         * @param pageNumber
         */
    async loadNextPagePosts (pageNumber = 1) {
      this.showLoader = true

      const getLimit = () => {
        if (this.siteId === 3) {
          return 12
        }
        if (this.siteId === 2 && this.popular) {
          return 4
        }
        return 6
      }
      const limit = getLimit()
      const diff = this.$route.fullPath === '/blog/' ? 1 : 0
      const offset = (pageNumber * limit) + diff
      const result = await this.$api('blogPosts', 'list', {
        categoryId: this.categoryId,
        siteId: this.siteId,
        limit,
        page: pageNumber,
        popular: this.popular ? 1 : 0,
        offset
      })
      if (result) {
        this.nextPagePosts.push(result)
        document.getElementById(`page${pageNumber}`).classList.add('d-none')
        this.showLoader = false
        if (result.items.length !== limit) {
          this.hasMorePosts = false
        }
      }
    }
  }
}
