
import Meta from '../../plugins/json/crm-capabilities/meta.json'

export default {
  name: 'CrmCapabilitiesSlug',
  components: {
    CrmCapabilitiesTemplate: () => import('../../components/pages/crm-capabilities-template.vue')
  },
  async asyncData ({ params, error }) {
    const slug = params.slug
    let meta = Meta.pages.find((x) => x.slug === slug)
    if (!meta) {
      meta = {
        title: '',
        subtitle: '',
        description: ''
      }
      error({ statusCode: 404, message: `Запись ${params.slug} не найдена` })
    }
    return {
      meta,
      slug
    }
  },
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: index === 0 ? this.$t(`pages.${path}.title`) : this.meta.name,
        item: breadcrumbPath(index)
      })
    })
    return {
      title: this.meta.title,
      meta: [
        { hid: 'description', name: 'description', content: this.meta.description },
        { hid: 'og:title', name: 'og:title', content: this.meta.title },
        { hid: 'og:description', name: 'og:description', content: this.meta.description }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.meta.name,
            description: this.meta.description,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  }
}
