
export default {
  name: 'Header',
  methods: {
    showRegistrationPopup () {
      this.showPopup('reg-form')
      this.sendGoalEvent('pushreglogin')
    }
  },
  data () {
    return {
      paHost: process.env.PA_HOST
    }
  }
}
