import request from './request'

const API_URL = '/adm/api/account/'

/**
 * Проверяет существование аккаунта accountName.megaplan.ru
 * @returns Promise<boolean>
 * @param params
 */
function isAccountExist (params) {
  return request(API_URL, `is-account-exist?accountName=${params.name}`, null, 'get')
}

/**
 * Отправляем письмо с адресом мегаплана директору на мыло
 * возвращает тру/фолс (отправили или не смогли)
 * @returns Promise<boolean>
 * @param params
 */
function sendEmailWithMegaplanAddress (params) {
  return request(API_URL, 'send-email-with-megaplan-address', params)
}

function checkField (params) {
  return request(API_URL, 'check-field', params)
}

function checkPhone (params) {
  return request(API_URL, 'check-phone', params)
}

function verifyCode (params) {
  return request(API_URL, 'verify-code', params)
}

function sendNewCode (params) {
  return request(API_URL, 'send-new-code', params)
}

function checkFields (params) {
  return request(API_URL, 'check-fields', params)
}

function registerAccount (params) {
  return request(API_URL, 'register-account', params)
}

function createAccount (params) {
  return request(API_URL, 'create-account', params)
}

function checkRegister (params) {
  return request(API_URL, 'check-register', params)
}

function accountLinks () {
  return request(API_URL, 'account-links')
}

function removeAccountLink (params) {
  return request(API_URL, 'remove-account-link', params)
}

function accountData (params) {
  return request(API_URL, 'data', params)
}

function isCaptchaRequired () {
  return request(API_URL, 'check-captcha-settings')
}

export {
  isAccountExist,
  sendEmailWithMegaplanAddress,
  checkField,
  createAccount,
  checkRegister,
  checkFields,
  registerAccount,
  accountLinks,
  removeAccountLink,
  accountData,
  checkPhone,
  sendNewCode,
  verifyCode,
  isCaptchaRequired
}
