
/**
 * Элементы для регистрации
 */
import RegistrationLocal from '../../mixins/register-local'
import Abtest from '../../mixins/abtest'

export default {
  name: 'RegElements',
  mixins: [RegistrationLocal, Abtest],
  props: {
    header: {
      type: String,
      required: false,
      default: 'Попробуйте Мегаплан'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    withPromoCode: {
      type: Boolean,
      required: false,
      default: false
    },
    showSocials: {
      type: Boolean,
      required: false,
      default: true
    },
    className: {
      type: String,
      required: false,
      default: 'c-forms__reg-elements'
    },
    buttonText: {
      type: String,
      required: false,
      default: ''
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showSocialsIcons: false
    }
  },
  mounted () {
    this.initSocials()
    if (this.showSocialsIcons) {
      this.addSas()
    }
    this.callTrackingStart()
  },
  beforeDestroy () {
    this.clearErrors()
    this.removeSas()
  },
  methods: {
    /**
     * Авторизация через 1c
     */
    loginWith1C () {
      const link = `${process.env.HOST_NAME}/adm/api/oauth/callback?oauth=login1c&`
      const esc = encodeURIComponent
      const params = Object.keys(this.getMetaData()).map((k) => `${esc(k)}=${esc(this.getMetaData()[k])}`).join('&')
      window.open(link + params, '_blank')
    },
    sendGoalWithNetwork (e) {
      const path = e.target.currentSrc.split('/')
      const getSocialName = path[path.length - 1].split('.')
      const socialName = getSocialName[0]
      this.sendGoalEvent('openRegFormWithSocial', { provider: socialName })
    },
    initSocials () {
      if (this.isProduction) {
        this.showSocialsIcons = this.showSocials && this.getOauthMetaData()
      }
      this.showSocialsIcons = this.showSocials && this.oauthMetaData !== null
    },
    addSas () {
      const script = document.createElement('script')
      script.id = 'sas'
      script.src = 'https://sas.megaplan.ru/oauth/widgets/js'
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    },
    removeSas () {
      const sasScript = document.getElementById('sas')
      if (this.isExist(sasScript)) {
        sasScript.remove()
        document.querySelector('link[href="https://sas.megaplan.ru/bundles/oauth/css/widget.css"]').remove()
      }
    }
  }
}
