
import DateFormat from '../../../mixins/dateFormat'

export default {
  mixins: [DateFormat],
  name: 'VideoSlug',
  components: {
    BreadCrumbs: () => import('../../../components/elements/breadcrumbs.vue'),
    VideoItems: () => import('../../../components/pages/blog/video-items.vue'),
    BlogLoadNextPage: () => import('~/components/pages/blog/blog-load-next-page.vue'),
    FooterGlowWrapper: () => import('../../../components/elements/footer-glow-wrapper.vue')
  },
  async asyncData ({ app, params, error }) {
    const limit = 12
    let categoryTryings = 0
    let category
    while ((typeof category === 'undefined' || typeof category.id === 'undefined' || category.length === 0) && categoryTryings < 5) {
      // eslint-disable-next-line no-await-in-loop
      category = await app.$api('blogCategories', 'get', {
        slug: params.category,
        siteId: 3
      })
      categoryTryings += 1
    }
    if (typeof category === 'undefined' || typeof category.id === 'undefined' || category.length === 0) {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text')} ${params.category} ${app.i18n.t('pages.blog.record.text1')}` })
    }
    const videoList = await app.$api('blogPosts', 'list', {
      categoryId: category.id,
      siteId: 3,
      limit
    })
    if (typeof videoList === 'undefined' || typeof videoList.items === 'undefined' || !videoList.items.length) {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text2')} ${app.i18n.t('pages.blog.record.text1')}` })
    }
    return {
      categoryId: category.id,
      title: category.title,
      items: videoList.items,
      totalItems: videoList.totalCount,
      limit,
      meta: {
        title: category.meta_title,
        description: category.meta_description
      }
    }
  },
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: index === 0 ? this.$t(`pages.${path}.title`) : this.meta.title,
        item: breadcrumbPath(index)
      })
    })
    return {
      title: this.meta.title,
      meta: [
        { hid: 'description', name: 'description', content: this.meta.description },
        { hid: 'og:title', name: 'og:title', content: this.meta.title },
        { hid: 'og:description', name: 'og:description', content: this.meta.description }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.meta.title,
            description: this.meta.description,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  }
}
