
import { mapGetters } from 'vuex'
import { DButton, CIcon } from 'site-lib'
import { getCookie, setCookie } from '../../mixins/cookie'
import userDevice from '../../plugins/utils/userDevice'

export default {
  name: 'Banners',
  components: {
    DButton,
    CIcon
  },
  props: {
    top: {
      type: Boolean,
      default: false
    },
    top2: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    }
  },
  async fetch () {
    if (!this.isExist(this.getBanners)) {
      await this.$store.dispatch('common/getBannersList')
    }
  },
  data: () => ({
    showBanner: false,
    defaultImage: '/upload/common/banners/banner2-default.png',
    defaultImageMobile: '/upload/common/banners/banner2-default-mobile.png'
  }),
  computed: {
    ...mapGetters({
      getBanners: 'common/getBanners'
    }),
    bannerType () {
      return Object.keys(this.$props).find((k) => this.$props[k] === true)
    },
    banner () {
      const getBanners = process.client ? this.getBanners : null
      if (this.isExist(getBanners)) {
        const cookieKey = `${this.bannerType}BannerShowId`
        const bannerIdFromCookie = Number(getCookie(cookieKey))
        let banners = getBanners.filter((banner) => banner.type === this.bannerType.toUpperCase())
        if (this.bannerType === 'top' && this.isTop2BannerEnabled) {
          return null
        }
        banners = this.filterBanners(banners, this.$route)
        if (bannerIdFromCookie && banners.length > 1) {
          banners = banners.filter((banner) => Number(banner.id) !== bannerIdFromCookie)
        }
        return this.getRandElArray(banners)
      }
      return null
    },
    hasMobileImage () {
      if (this.isMobile && (this.isExist(this.banner) && this.isExist(this.banner.imageMobile))) {
        return !!this.banner.imageMobile
      }
      return false
    },
    isTop2BannerEnabled () {
      return this.getBanners.findIndex((banner) => banner.type === 'TOP2') !== -1
    },
    bannerSizeClass () {
      const banner = this.getBanners.find((b) => b.type === this.bannerType.toUpperCase())
      return banner.size.toLowerCase()
    },
    settings () {
      return JSON.parse(this.banner.settings)
    },
    className () {
      return `c-elements-banners-${this.bannerType}`
    },
    bannerName () {
      return `banner${this.banner.id}`
    },
    isMobile () {
      return userDevice().device !== 'desktop'
    }
  },
  watch: {
    $route (route) {
      this.canShow(route)
    }
  },
  activated () {
    if (this.$fetchState.timestamp <= Date.now() - 30000) {
      this.$fetch()
    }
  },
  mounted () {
    this.cookieUpdate()
    this.canShow()
    this.checkLimit()
  },
  methods: {
    cookieUpdate () {
      if (process.client && this.banner) {
        setCookie(`${this.bannerType}BannerShowId`, this.banner.id)
      }
    },
    checkLimit () {
      let count
      if (this.showBanner && this.settings.limitShowing) {
        if (getCookie(`${this.bannerName}ShowCount`)) {
          count = Number(getCookie(`${this.bannerName}ShowCount`))
          count += 1
        } else {
          count = 1
        }
        if (count > this.settings.limitShowing) {
          this.showBanner = false
        } else if (process.client) {
          setCookie(`${this.bannerName}ShowCount`, count)
        }
      }
    },
    canShow (route = this.$route) {
      if (this.isExist(this.banner)) {
        const bannerCookie = getCookie(this.bannerName)
        if (bannerCookie === 'hide') return
        if (!this.isExist(bannerCookie) && this.bottom) {
          this.statBannerPlus('showing', this.banner.id)
        }
        if (this.banner.action === 'REDIRECT') {
          this.showBanner = this.settings.redirectUrl !== route.path
        }
        this.showBanner = this.checkAdditionalAvctivitySettings()
      }
    },
    checkAdditionalAvctivitySettings () {
      const bannersSettings = JSON.parse(this.banner.settings)
      const additionalActivitySettings = bannersSettings.additionalAvctivitySettings
      const date = new Date()
      const nowWeekDay = date.getDay()
      const output = []
      let result
      if (additionalActivitySettings) {
        additionalActivitySettings.forEach((item) => {
          const weekday = item.weekday
          const startActive = item.startActive.split(':')
          const stopActive = item.stopActive.split(':')
          const startHappyHourD = new Date()
          const endHappyHourD = new Date()
          if (Number(nowWeekDay) === Number(weekday)) {
            startHappyHourD.setHours(Number(startActive[0]), Number(startActive[1]), 0)
            endHappyHourD.setHours(Number(stopActive[0]), Number(stopActive[1]), 0)
            if (date < startHappyHourD || date > endHappyHourD) {
              output.push(false)
            } else {
              output.push(true)
            }
          } else {
            output.push(false)
          }
        })
        result = output.some((item) => (item === true))
      } else {
        result = true
      }
      return result
    },
    filterBanners (banners, route) {
      return banners.filter((banner) => {
        let showBanner = false
        const availablePages = banner.pagesList.split(' ').join('').split(',')
        const includePages = availablePages.filter((el) => !el.includes('!'))
        const prepareExcludePages = availablePages.filter((el) => el.includes('!'))
        const excludePages = prepareExcludePages.map((el) => el.replace('!', ''))
        const isPathIncludes = (listPages, path) => {
          if (listPages.includes(path)) {
            return true
          }
          const pagesContainSubpage = listPages.filter((el) => path.startsWith(el))
          return pagesContainSubpage.length > 0
        }
        if (includePages.length === 0 && excludePages.length > 0) {
          includePages.push('*')
        }
        if (
          includePages.includes(route.name)
          || isPathIncludes(includePages, route.path)
          || includePages.includes('*')
        ) {
          showBanner = true
        }
        if (
          excludePages.includes(route.name)
          || isPathIncludes(excludePages, route.path)
          || excludePages.includes('*')
        ) {
          showBanner = false
        }
        return showBanner
      })
    },
    action () {
      this.statBannerPlus('clicks', this.banner.id)
      this.sendGoalEvent('bannerClick')
      if (this.banner.action === 'REDIRECT') {
        this.sendGoalEvent('bannerRedirect')
        this.$router.push(this.settings.redirectUrl)
      }
      if (this.banner.action === 'REDIRECT_ANOTHER') {
        this.sendGoalEvent('bannerRedirect')
        window.open(this.settings.redirectUrlAnother)
      }
      if (this.banner.action === 'FORM') {
        const formParams = {
          formEmailName: this.settings.formSystemName,
          formEmailTitle: this.banner.title,
          header: this.banner.title,
          metricsTarget: 'bannerFormSubmit',
          bannerId: Number(this.banner.id)
        }
        this.showPopup('form', formParams)
      }
    },
    closeBanner () {
      setCookie(this.bannerName, 'hide')
      this.showBanner = false
    }
  }
}
