
export default {
  name: 'swch',
  props: {
    params: {
      type: Array,
      required: true
    },
    forYandex: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    sale: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checked: false
    }
  },
  created () {
    if (this.$route.name === 'calculation' && this.$route.hash === '#box') {
      this.checked = true
    }
  },
  methods: {
    changeType () {
      this.checked = !this.checked
      this.$emit('change', this.checked)
    }
  }
}
