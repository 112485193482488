
/**
   * Вспомогательный компонент для вывода кнопок
   */
export default {
  name: 'RegButton',
  props: {
    className: {
      type: String,
      required: false,
      default: 'green'
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    popupName: {
      type: String,
      required: false,
      default: 'reg-form'
    },
    formParams: {
      type: Object,
      required: false,
      default: () => ({})
    },
    target: {
      type: String,
      required: false,
      default: 'tryForFreeButton'
    },
    isCenter: {
      type: Boolean,
      default: false
    },
    autoshow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mainClass () {
      return this.isCenter
        ? `c-forms__reg-button is-center ${this.className}`
        : `c-forms__reg-button ${this.className}`
    }
  },
  data () {
    return {
      internalText: this.text === '' ? this.$t('regForm.button') : this.text
    }
  },
  methods: {
    clickAction () {
      this.showRegPopup(this.popupName, this.formParams, this.target)
    }
  },
  mounted () {
    if (this.autoshow) {
      this.showPopup(this.popupName, { subtitle: this.$t('regForm.subtitle'), header: this.$i18n.nl2br('regForm.title'), ...this.formParams })
    }
  }
}
