export default {
  methods: {
    simpleFormat (dateString) {
      const date = new Date(dateString)
      let result = ''
      if (!Number.isNaN(date.getDate())) {
        const dd = String(date.getDate()).padStart(2, '0')
        const mm = String(date.getMonth() + 1).padStart(2, '0')
        const yy = date.getFullYear()
        result = `${dd}.${mm}.${yy}`
      }
      return result
    },
    localStringFormat (dateString) {
      const date = new Date(dateString)
      let result = ''
      if (!Number.isNaN(date.getDate())) {
        result = date.toLocaleString(this.activeLanguage, {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      }
      return result
    },
    longFormat (dateString) {
      const date = new Date(dateString)
      let result = ''
      if (!Number.isNaN(date.getDate())) {
        result = date.toLocaleString(this.activeLanguage, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        })
      }
      return result
    }
  }
}
