export default {
  getTariff (state) {
    return state.tariff
  },
  getType (state) {
    return state.type
  },
  getCount (state) {
    return state.count
  },
  getPeriod (state) {
    return state.period
  },
  getEfficiency (state) {
    return state.efficiency
  },
  getCosts (state) {
    return state.costs
  }
}
