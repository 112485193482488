
import Vue from 'vue'
import RegistrationLocal from '../mixins/register-local'
import Helpers from '../mixins/helpers'
import analytics from '../mixins/analytics'
import domainList from '../plugins/json/domains.json'
import scrollBehavior from '../app/router.scrollBehavior'

Vue.mixin(Helpers)
Vue.mixin(analytics)
export default {
  mixins: [RegistrationLocal],
  data: () => ({
    loadPopupObject: '',
    showPopupObject: false,
    popupParams: {}
  }),
  computed: {
    loadPopupGeneratorComponent () {
      return () => import('../components/popup/popup-generator.vue')
    }
  },
  created () {
    this.$root.$on('popupGenerator', (popupName, show, params = {}) => {
      this.loadPopupObject = popupName
      this.showPopupObject = show
      this.popupParams = params
    })
  },
  beforeMount () {
    this.fillMetaData()
    this.checkRouteHash()
    this.loadLocale()
  },
  beforeDestroy () {
    this.$root.$off(['popupGenerator'])
  },
  methods: {
    loadLocale () {
      let lang = /^#lang=(by|ua|kz|ru)$/.exec(this.$nuxt.$route.hash)

      if (lang && (lang.length > 1)) {
        lang = lang[1].toLowerCase()
        return this.setLocale(lang)
      }

      const locale = localStorage.getItem('locale')
      if (this.getLocalesList().includes(locale)) {
        return this.setLocale(locale)
      }
      return this.setLocale('ru')
    },
    getLocalesList () {
      return domainList.languages.map((el) => el.code)
    },
    checkRouteHash () {
      if (this.$route.hash && typeof document.getElementById(this.$route.hash.slice(1)) !== 'undefined' && document.getElementById(this.$route.hash.slice(1)) != null) {
        this.$nextTick(() => {
          window.scrollTo(0, scrollBehavior(this.$route).y)
        })
      }
    }
  }
}
