
export default {
  name: 'RegButtonAmp',
  props: {
    url: {
      type: String,
      required: false,
      default: `${process.env.HOST_NAME}/facilities/?showRegPopup=true`
    },
    isCenter: {
      type: Boolean,
      required: false
    }
  }
}
