import { getCookie } from './cookie'

export default {
  methods: {
    /**
     * Метод для сбора статистики
     * @param eventType
     * @param bannerId
     * @returns {Promise<void>}
     */
    async statBannerPlus (eventType, bannerId) {
      const yandexClientId = this.getYaCid()
      const googleClientId = this.getGaCid()

      if (eventType === 'showing') {
        const bannerDisplayedKey = `bannerDisplayed${bannerId}`
        const bannerDisplayed = sessionStorage.getItem(bannerDisplayedKey)
        const bannerName = `banner${bannerId}`

        if (!bannerDisplayed && bannerId > 0 && getCookie(bannerName) !== 'hide') {
          await this.$api('banners', 'StatPlus', {
            bannerId,
            eventType,
            yandexClientId,
            googleClientId
          })
          sessionStorage.setItem(bannerDisplayedKey, 1)
        }
      } else {
        await this.$api('banners', 'StatPlus', {
          bannerId,
          eventType,
          yandexClientId,
          googleClientId
        })
      }
    }
  }
}
