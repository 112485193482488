
export default {
  name: 'VideoBlock',
  props: {
    videoId: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    },
    imageUrlMobile: {
      type: String,
      required: false
    },
    imgAlt: {
      type: String,
      required: false,
      default: 'preview-img'
    },
    useVk: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PlayButton: () => import('./play-button.vue'),
    VkVideo: () => import('../widgets/vk-video.vue')
  },
  data () {
    return {
      videoPlay: false
    }
  },
  methods: {
    playVideo () {
      this.videoPlay = true
      this.sendGoalEvent('videoPlay')
    }
  }
}
