
import Vue, { PropType } from 'vue'
import { CIcon, IconNames } from '../CIcon'

interface Action {
    handler: () => void
    iconName: keyof typeof IconNames
}

type Types = 'text' | 'password'

export default Vue.extend({
  name: 'COnboardingInput',
  components: { CIcon },
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  data (): { focused: boolean} {
    return {
      focused: this.autofocus
    }
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String as PropType<Types>,
      required: false,
      default: 'text' as Types
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String as PropType<string | null>,
      required: false,
      default: null
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    actions: {
        type: Array as PropType<Action[]>,
        required: false,
        default: null
    }
  },

  methods: {
    inputHandler (value: string) {
      this.$emit('input', value)
    },
    blurHandler () {
      this.focused = false
      this.$emit('blur')
    },
    focusHandler () {
      this.focused = true
      this.$emit('focus')
    }
  }
})
