
export default {
  name: 'NotFoundPage',
  components: {
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    return {
      title: `${this.$route.path} — ${this.$t('pages.not-found-page.meta.title')}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.not-found-page.meta.description')
        }
      ]
    }
  }

}

