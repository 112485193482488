
import MCButton from '../UI/buttons/mc-button.vue'

export default {
  name: 'ModalDialog',
  components: {
    MCButton
  },
  props: {
    dialog: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    handleOverlayClick () {
      if (this.dialog) {
        this.closePopup('modal-dialog')
      }
      this.$router.back()
    }
  }
}
