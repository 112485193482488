
import Meta from '../../../plugins/json/articles/meta.json'
import MdHead from '../../../mixins/mdHead'
import imgPopup from '../../../mixins/imgPopup'
import DateFormat from '../../../mixins/dateFormat'

export default {
  name: 'ArticlesSlug',
  components: {
    Prefooter: () => import('../../../components/elements/pre-footer.vue'),
    BlogSearch: () => import('../../../components/pages/blog/blog-search.vue'),
    BlogMenu: () => import('../../../components/pages/blog/blog-menu.vue'),
    FooterGlowWrapper: () => import('../../../components/elements/footer-glow-wrapper.vue'),
    CackleComments: () => import('../../../components/widgets/cackle-comments.vue'),
    FaqBlock: () => import('../../../components/blocks/faq-block.vue')
  },
  mixins: [MdHead, imgPopup, DateFormat],
  async asyncData ({ app, params, store, error }) {
    const currentLocale = store.getters['locale/getLocale']
    let fileContent
    try {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/articles/' + currentLocale + '/' + params.slug + '.md')
        .then((res) => { fileContent = res.default })
    } catch (e) {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/articles/ru/' + params.slug + '.md')
        .then((res) => { fileContent = res.default })
        .catch(() => {
          error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text2')} ${params.slug} ${app.i18n.t('pages.blog.record.text1')}` })
        })
    }

    let meta = Meta.pages.find((x) => x.slug === params.slug)
    if (typeof meta === 'undefined') {
      meta = {
        title: '',
        subtitle: '',
        description: ''
      }
    }

    let comments = []
    if (meta.slug === 'chto-takoe-crm-sistema') {
      const response = await app.$api('comments', 'getLocalComments', '/news/articles/chto-takoe-crm-sistema/')
      if (response?.status === 'ok') {
        comments = response.comments
      }
    }
    return {
      content: fileContent,
      meta,
      locale: currentLocale,
      comments
    }
  },
  data: () => ({
    floatClass: '',
    links: []
  }),
  computed: {
    articleTitle () {
      return this.isExist(this.meta.translations[this.locale])
        ? this.meta.translations[this.locale].name
        : this.meta.translations.ru.name
    },
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate
      }
    },
    isChtoTakoeCrm () {
      return this.meta.slug === 'chto-takoe-crm-sistema'
    },
    showComments () {
      return this.isChtoTakoeCrm
    },
    dateUpdated () {
      const date = new Date()
      const mm = String(date.getMonth() + 1).padStart(2, '0')
      const yy = date.getFullYear()
      const formattedDate = this.localStringFormat(date).replace(/\d+/, '1')
      return {
        web: `Обновлено ${formattedDate} в 8:13`,
        schema: `${yy}-${mm}-01T08:13:47`
      }
    },
    showFaq () {
      return this.activeLanguage === 'ru' && this.meta.faq
    }
  },
  mounted () {
    this.generateLinks()
  },
  beforeDestroy () {
    this.content = null
    this.meta = null
  },
  methods: {
    generateLinks () {
      if (process.browser) {
        const post = document.getElementsByClassName('post')[0]
        const headings = post.getElementsByTagName('h2')
        for (let i = 0; i < headings.length; i += 1) {
          // eslint-disable-next-line no-bitwise
          const id = `f${(~~(Math.random() * 1e8)).toString(16)}`
          headings[i].setAttribute('id', id)
          this.links.push({
            href: id,
            text: headings[i].innerHTML
          })
        }
      }
    }
  },
  head () {
    const domain = 'https://megaplan.ru'
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbName = (index, path) => {
      switch (index) {
      case 0:
        return this.$t(`pages.${path}.title`)
      case 1:
        return this.$t(`pages.news.${path}.title`)
      case 2:
        return this.articleTitle
      default:
        return this.articleTitle
      }
    }
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: breadcrumbName(index, path),
        item: breadcrumbPath(index)
      })
    })
    return {
      title: this.meta?.translations[this.locale].title,
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'article' },
        { hid: 'og:title', name: 'og:title', content: this.meta?.translations[this.locale].title },
        { hid: 'og:description', name: 'og:description', content: this.meta?.translations[this.locale].description },
        { hid: 'description', name: 'description', content: this.meta?.translations[this.locale].description }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: `${domain}${fullPath}`,
            image: `${domain}/share.png`,
            name: this.meta?.translations[this.locale].title,
            description: this.meta?.translations[this.locale].description,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        },
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            datePublished: this.meta?.date_create,
            dateModified: this.dateUpdated.schema,
            headline: this.meta?.translations[this.locale].title,
            url: `${domain}${fullPath}`
          }
        }
      ]
    }
  },
  amp: 'hybrid',
  ampLayout: 'amp'
}

