var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.href)?_c('button',{class:[
    'c-button',
    _vm.sizeClass,
    _vm.colorClass,
    _vm.paddingClass,
    { 'button-inverted': _vm.inverted, 'button-disabled': _vm.disabled, 'button-full-width': _vm.fullWidth },
    _vm.className
  ],on:{"click":_vm.onClick}},[_vm._t("default"),_vm._v(" "),(_vm.iconName)?_c('CIcon',{attrs:{"iconName":_vm.iconName,"size":"normal","className":_vm.iconClass}}):_vm._e()],2):_c('a',{class:[
    'c-button',
    _vm.sizeClass,
    _vm.colorClass,
    _vm.paddingClass,
    { 'button-inverted': _vm.inverted, 'button-disabled': _vm.disabled, 'button-full-width': _vm.fullWidth },
    _vm.className
  ],attrs:{"href":_vm.href,"target":_vm.blank ? '_blank' : '_self',"download":_vm.download}},[_vm._t("default"),_vm._v(" "),(_vm.iconName)?_c('CIcon',{attrs:{"iconName":_vm.iconName,"size":"normal","className":_vm.iconClass}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }