
const ampifedCategories = ['crm']

async function loadData (params, $api) {
  const slug = params.post.split('?')[0]
  const categorySlug = params.category.split('?')[0]

  if (!ampifedCategories.includes(categorySlug)) {
    throw new Error('AMP version of Post not found')
  }

  let postTryings = 0
  let postData
  while ((typeof postData === 'undefined' || typeof postData.item === 'undefined' || !postData.item) && postTryings < 5) {
    // eslint-disable-next-line no-await-in-loop
    postData = await $api('blogPosts', 'article', {
      slug,
      siteId: 2
    })
    postTryings += 1
  }
  const post = postData.item
  if (!post || post.articleCategorySlug !== categorySlug) {
    throw new Error('Post not found')
  }
  return {
    post
  }
}
export default {
  async asyncData ({ app, params, error }) {
    try {
      return await loadData(params, app.$api)
    } catch (e) {
      return error({ statusCode: 404 })
    }
  },
  async mounted () {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (process.browser) {
      if (!this.isExist(this.post)) {
        const data = await loadData(this.$route.params, this.$api)
        this.post = data.post
      }
    }
  },
  components: {
    AmpBlogPost: () => import('~/components/pages/blog/amp-blog-post.vue')
  }
}
