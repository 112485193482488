
import { CIcon } from 'site-lib/index.ts'

export default {
  name: 'price-services',
  components: {
    CIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    itemClass (item) {
      const defaultClass = 'service-item'
      const colorClass = `${defaultClass}--${item.color}`
      return `${defaultClass} ${colorClass} ${this.customClass}`
    }
  }
}
