

export default {
  name: 'make-order-form',
  components: {
    MCButton: () => import('../../UI/buttons/mc-button.vue'),
    FormField: () => import('~/components/forms/form-field.vue')
  },
  props: {
    formFields: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    formButtonText: {
      type: String
    },
    showErrorsWhenSubmit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    model: {},
    errors: [],
    buttonText: null,
    messageAfterSubmit: [],
    showPopupAfterSubmit: false,
    hasSubmitErrors: false,
    submitButtonIsDisabled: false
  }),
  watch: {
    model (value) {
      this.$emit('update:model', value)
    },
    showPopupAfterSubmit (value) {
      this.$emit('update:showPopupAfterSubmit', value)
    }
  },
  mounted () {
    this.buttonText = this.isExist(this.formButtonText)
      ? this.formButtonText
      : this.$t('regForm.buttonDefault')
    this.formFields.map((el) => {
      if (el.validateOnKey || el.required) {
        this.errors.push({
          field: el.name,
          correct: false
        })
      }
    })
  },
  methods: {
    formErrors (e) {
      this.errors.map((el, i) => {
        if (el.field === e.field) {
          if (e.correct) {
            this.errors.splice(i, 1)
            this.formFields.find((x) => x.name === el.field).showError = false
          } else {
            this.formFields.find((x) => x.name === el.field).showError = this.showErrorsWhenSubmit
          }
        }
      })
    },
    async submitForm () {
      if (this.messageAfterSubmit.length > 0) {
        this.messageAfterSubmit.length = 0
      }
      const defaultButtonText = this.isExist(this.formButtonText)
        ? this.formButtonText
        : this.$t('regForm.buttonDefault')
      if (this.errors.length > 0) {
        this.errors.map((el) => this.formErrors(el))
        this.buttonText = this.$t('regForm.fillForm')
        setTimeout(() => {
          this.buttonText = defaultButtonText
        }, 3000)
        return false
      }

      this.submitButtonIsDisabled = true
      this.buttonText = this.$t('regForm.buttonIndexCheck')
      const result = await this.$api('sendMail', 'send', {
        subject: this.$t('marketOrderForm.emailTitle'),
        body:
          `Новая заявка от компании - ${this.model.company}<br />
            Имя: ${this.model.name}<br />
            Телефон: ${this.model.phone}<br />
            E-mail: ${this.model.email}<br /><br />`
      })
      this.buttonText = defaultButtonText
      this.submitButtonIsDisabled = false
      if (result && result === true) {
        this.hasSubmitErrors = false
        this.messageAfterSubmit.push(this.$t('marketOrderForm.success'))
      } else {
        this.hasSubmitErrors = true
        this.messageAfterSubmit.push(this.$t('marketOrderForm.fail'))
      }
      this.showPopupAfterSubmit = true
      return true
    }
  }
}
