
import updateList from '../../plugins/json/updates/updatesList.json'

export default {
  name: 'Updates',
  created () {
    const { link } = updateList.pages[updateList.pages.length - 1]
    this.$router.push(`/updates/${link}/`)
  }
}

