
import { CInput } from 'site-lib/index.ts'

export default {
  name: 'FormField',
  components: {
    CInput
  },
  props: {
    name: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    validateOnKey: {
      type: Boolean,
      default: false
    },
    rule: {
      type: String,
      default: 'text'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    inputStyle: {
      type: String,
      required: true
    },
    showError: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    }
  },
  data: () => ({
    model: '',
    fieldCorrect: true
  }),
  computed: {
    errorMessage () {
      if (!this.fieldCorrect || this.showError) {
        return this.$t(`regForm.errors.${this.name}`)
      }
      return ''
    },
    rules () {
      const getRules = {
        text: () => this.model.length > 1,
        phone: () => this.checkPhone(this.model),
        email: () => this.checkEmail(this.model)
      }
      return getRules[this.rule]
    }
  },
  watch: {
    fieldCorrect () {
      this.$emit('errors', {
        field: this.name,
        correct: this.fieldCorrect
      })
    },
    model () {
      if (this.validateOnKey || this.required) {
        this.fieldCorrect = this.rules()
      }
    }
  },
  methods: {
    validate () {
      if (this.validateOnKey) {
        this.fieldCorrect = this.rules()
      }
      if (this.fieldCorrect) {
        this.$emit('input', this.model)
      }
    }
  }
}
