
export default {
  layout: 'login',
  components: {
    AddressRecoveryForm: () => import('../../components/pages/login/address-recovery-form.vue'),
    AddressSuccessfullySentForm: () => import('../../components/pages/login/address-successfully-sent-form.vue')
  },
  data: () => ({
    emailSended: false
  }),
  methods: {
    handleSendEmail (isSended) {
      this.emailSended = isSended
    }
  }
}
