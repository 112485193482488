
import { CGreenBlock } from 'site-lib/index.ts'

export default {
  name: 'RosSoftKnowledge',
  components: {
    CGreenBlock,
    Collapse: () => import('../../elements/collapse.vue')
  },
  props: {
    regAction: Function
  },
  methods: {
    isString (elem) {
      return typeof elem === 'string'
    }
  }
}
