

export default {
  name: 'PopupGenerator',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    params: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data: () => ({
    forbiddenPopups: [
      'vk-video',
      'video',
      'form',
      'reg-form',
      'two-step-form',
      'registration-quiz',
      'image-popup',
      'modal-dialog',
      'mp-plus-request',
      'modal-subscribe']
  }),
  computed: {
    currentPopup () {
      const popupName = this.name
      // eslint-disable-next-line prefer-template
      return () => import('~/components/popup/' + popupName)
    }
  },
  watch: {
    /**
     * Закрываем попап окно при смене роута
     */
    $route () {
      if (process.browser) {
        document.querySelector('body').style.overflow = ''
      }
      this.$root.$emit('popupGenerator', this.name, false)
    }
  }
}
