

export default {
  name: 'FaqBlock',
  props: {
    faqList: {
      type: Array,
      required: true
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  head () {
    const faqSchema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.faqList.map((item) => ({
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.answer
        }
      }))
    }
    return { script: [{ type: 'application/ld+json', json: faqSchema }] }
  }
}
