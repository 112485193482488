
import ErrorHelper from '../../mixins/formErrorsHelper'
import RegistrationLocal from '../../mixins/register-local'

export default {
  name: 'TwoStepFormElements',
  mixins: [ErrorHelper, RegistrationLocal],
  props: {
    className: {
      type: String,
      required: false,
      default: ''
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: true
    },
    analyticEvent: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    email: '',
    name: '',
    phone: '',
    position: '',
    step: 1,
    recordId: 0,
    showPositionList: false,
    showPositionInput: false
  }),
  computed: {
    currentPosition () {
      return this.position !== '' ? this.position : this.$t('twoStepForm.positionTitle')
    }
  },
  mounted () {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.tsEmail.focus()
      })
    }
  },
  methods: {
    selectValues (key) {
      const result = []
      const valueTitles = this.$t(`twoStepForm.${key}`)
      // eslint-disable-next-line no-restricted-syntax
      for (const [, title] of Object.entries(valueTitles)) {
        result.push({
          value: title,
          title
        })
      }
      return result
    },
    /**
       * События изменения поля
       * @param $event
       */
    onFieldChange ($event) {
      const { field } = $event.target.dataset
      const { value } = $event.target
      this.checkField(field, value)
    },
    /**
       * Проверяем поле на ошибки
       */
    checkField (field, value) {
      let isCorrect = false
      if (field === 'email') {
        const re = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/
        isCorrect = re.test(String(value))
      }

      if (isCorrect) {
        this.clearError(field)
      } else {
        this.setError(field, this.$t(`regForm.errors.${field}`), this.formId)
      }
    },
    /**
       * Проверка поля во время ввода значения
       * @param field
       * @param $event
       */
    onFieldKeyUp (field, $event) {
      if ($event.keyCode === 13) {
        this.submit1()
      }
    },

    branchChange () {
      if (this.branch === this.$t('twoStepForm.branches.other')) {
        this.$nextTick(() => {
          this.$refs.branchCustom.focus()
        })
      }
    },

    /**
     * Отправка 1-й формы
     */
    async submit1 () {
      if (this.step === 1) {
        this.checkField('email', this.email)
        if (this.errorList.length === 0) {
          const metaData = this.getMetaData()
          const recordId = await this.$api('saveLead', 'step1', {
            email: this.email,
            utm: metaData.utm_source
          })
          if (recordId) {
            this.step = 2
            if (this.analyticEvent) {
              this.sendGoalEvent(this.analyticEvent).then()
            }
            this.recordId = recordId
          }
        }
      }
    },

    /**
     */
    async next () {
      this.step = 3
    },

    /**
     * Отправка 2-й формы
     */
    async submit2 () {
      if (this.errorList.length === 0) {
        const result = await this.$api('saveLead', 'step2', {
          fullName: this.name,
          phone: this.phone,
          position: this.position,
          recordId: this.recordId
        })
        if (result) {
          this.step = 4
        }
      }
    },
    choosePosition (item) {
      this.position = item
      this.showPositionList = false
    },
    focusToSelectInput () {
      this.showPositionInput = true
      this.$nextTick(() => {
        this.$refs.tsPosition.focus()
      })
    }
  }
}
