
import RegisterLocal from '../../mixins/register-local'

export default {
  name: 'FormPopup',
  components: {
    FormElements: () => import('../forms/form-elements.vue')
  },
  mixins: [RegisterLocal],
  props: {
    formFields: {
      type: Array,
      required: false,
      /**
       * в name указываем lang параметр name: 'name' === this.$t('regForm.name')
       * и текст ошибки this.$t('regForm.errors.name')
       */
      default: () => [
        {
          name: 'name',
          autofocus: true
        },
        {
          name: 'email',
          required: true,
          validateOnKey: true,
          rule: 'email'
        },
        {
          name: 'phone',
          required: true,
          validateOnKey: true,
          rule: 'phone'
        },
        {
          name: 'company'
        }
      ]
    },
    header: {
      type: String,
      default: null
    },
    subHeader: {
      type: String,
      default: null
    },
    formButtonText: {
      type: String
    },
    formEmailName: {
      type: String,
      required: false,
      default: 'default'
    },
    formEmailTitle: {
      type: String,
      required: true
    },
    formSuccessMessage: {
      type: String,
      required: false
    },
    showSendMore: {
      type: Boolean,
      default: false
    },
    metricsTarget: {
      type: String,
      default: null
    },
    metricsUtm: {
      type: String,
      default: null
    },
    setUtm: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getRoute () {
      return this.$route.name === 'blog-category-post' || this.$route.name === 'news-category-post'
        ? this.$route.fullPath
        : this.$route.name
    },
    formParams () {
      const header = this.header
        ? this.header
        : this.getTranslation(`pages.${this.getRoute}.form.header`, 'regForm.title')

      const subHeader = this.subHeader
        ? this.subHeader
        : this.getTranslation(`pages.${this.getRoute}.form.subtitle`)

      const formButtonText = this.formButtonText
        ? this.formButtonText
        : this.getTranslation(`pages.${this.getRoute}.form.btnText`, 'regForm.buttonDefault')

      const formEmailTitle = this.formEmailTitle
        ? this.formEmailTitle
        : this.getTranslation(`pages.${this.getRoute}.form.emailTitle`, 'regForm.defaultTitle', { path: document.title })

      const formSuccessMessage = this.formSuccessMessage
        ? this.formSuccessMessage
        : this.getTranslation(`pages.${this.getRoute}.form.successMessage`, 'regForm.defaultSuccessMessage')

      return {
        header,
        subHeader,
        formFields: this.formFields,
        formButtonText,
        formEmailName: this.formEmailName,
        formEmailTitle,
        formSuccessMessage,
        metricsUtm: this.setUtm ? this.getMetaData().utm_source : null,
        metricsTarget: this.metricsTarget,
        showSendMore: this.showSendMore
      }
    }
  },
  methods: {
    getTranslation (key, alternativeKey = '', alternativeKeyVariables = {}) {
      if (this.$te(key) || this.$te(key, 'ru')) {
        return this.$i18n.nl2br(key)
      }
      if (alternativeKey) {
        return this.$i18n.nl2br(alternativeKey, [], alternativeKeyVariables)
      }
      return ''
    }
  }
}
