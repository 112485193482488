
import { DButton } from 'site-lib'

export default {
  name: 'ProductContact',
  layout: 'empty',
  components: {
    DButton
  }
}
