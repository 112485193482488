import request from './request'

const API_URL = '/adm/api/analytic/'

function addVisitor (params) {
  return request(API_URL, 'add-visitor', params)
}

export {
  // eslint-disable-next-line import/prefer-default-export
  addVisitor
}
