
import Meta from '../../plugins/json/crm-for-business/meta.json'
import userDevice from '../../plugins/utils/userDevice'

export default {
  name: 'CrmCapabilitiesIndex',
  components: {
    BreadCrumbs: () => import('../../components/elements/breadcrumbs.vue'),
    CapabilityCard: () => import('../../components/elements/capability-card.vue'),
    Companies: () => import('../../components/blocks/companies.vue'),
    Prefooter: () => import('../../components/elements/pre-footer.vue'),
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue'),
    FaqBlock: () => import('../../components/blocks/faq-block.vue')
  },
  data () {
    return {
      pages: Meta.pages.filter((el) => el.active === true),
      content: undefined,
      isMobile: false
    }
  },
  async mounted () {
    this.isMobile = userDevice().device !== 'desktop'
    try {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/crm-for-business.md')
        .then((res) => { this.content = res.default })
    } catch (e) {
      throw new Error(`article nof found - ${e.message}`)
    }
  },
  computed: {
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate
      }
    }
  },
  head () {
    return {
      title: this.$t('pages.crm-for-business.meta.title'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('pages.crm-for-business.meta.description') }
      ]
    }
  }
}
