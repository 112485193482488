
import { CIcon } from 'site-lib'
import abtest from '../../../mixins/abtest'
import readingTime from '../../../mixins/helpers'
import { tagsList } from '../../../plugins/json/clients/meta.json'

export default {
  name: 'ClientCardInner',
  components: { CIcon },
  mixins: [abtest, readingTime],
  props: {
    client: {
      type: Object,
      default: () => ({})
    },
    post: {
      type: String,
      require: true
    }
  },
  methods: {
    getTagColor (tag) {
      const tagCategory = tagsList.find((cat) => cat.tags.includes(tag))
      return tagCategory.color
    },
    caseClick () {
      this.sendGoalEvent('newClientsCaseClick')
      this.sendGoalEvent('newClientsCaseClick', { 'Новая страница кейсов (клик по кейсу)': this.getAbValue('clientCardHover') })
    }
  },
  mounted () {
    this.needAbTest(process.env.CLIENT_CARD_HOVER, 'clientCardHover')
  }
}
