
import mspData from './data.json'

export default {
  name: 'RosSoftSlider',
  components: {
    Card: () => import('../../elements/big-slider-card.vue')
  },
  data () {
    return {
      mediaQuery: null,
      rossoftswiper: {
        slidesPerView: 5,
        loop: true,
        speed: 800,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    slides () {
      return mspData.sliderItems
    }
  },
  methods: {
    screenChange (e) {
      if (e.matches) {
        this.rossoftswiperRef.params.slidesPerView = 1
      } else {
        this.rossoftswiperRef.params.slidesPerView = 5
      }
      this.rossoftswiperRef.update()
    }
  },
  mounted () {
    this.mediaQuery = window.matchMedia('(max-width: 500px)')
    this.screenChange(this.mediaQuery)
    this.mediaQuery.addListener(this.screenChange)
  },
  beforeDestroy () {
    this.mediaQuery.removeListener(this.screenChange)
  }
}
