
import Vue from 'vue'
import Helpers from '../mixins/helpers'

Vue.component('RegButton', () => import('../components/forms/reg-button-amp.vue'))
Vue.mixin(Helpers)
export default {
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const domainRu = 'https://megaplan.ru'
    let canonicalHref
    const fullPath = this.$route.fullPath
    const route = fullPath.split('/').filter((folder) => folder !== 'amp').join('/')
    let htmlLang = 'ru-RU'

    canonicalHref = domainRu + route
    if (fullPath.lastIndexOf('/') !== fullPath.length - 1) {
      canonicalHref += '/'
    }
    if (domainUpperLevel === 'ua') {
      htmlLang = 'uk-UA'
    } else if (domainUpperLevel === 'by') {
      htmlLang = 'ru-BY'
    } else if (domainUpperLevel === 'kz') {
      htmlLang = 'ru-KZ'
    }

    const links = [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: canonicalHref
      }
    ]
    return {
      htmlAttrs: {
        lang: htmlLang
      },
      meta: [
        { hid: 'og:image', name: 'og:image', content: `${domain}/share.png` },
        { hid: 'description', name: 'description', content: this.$t('pages.index.meta.description') }
      ],
      link: links
    }
  }
}
