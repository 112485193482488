

export default {
  name: 'Redirect',
  components: {
    RedirectPage: () => import('../../components/pages/redirect-page.vue')
  },
  data () {
    return {
      page: {
        redirect: '',
        event: ''
      }
    }
  },

  async mounted () {
    if (process.browser) {
      const redirectUrl = await this.$api('redirect', 'getUrl', this.$route.params.suburl)
      this.page = {
        redirect: redirectUrl,
        event: `redirect_${this.$route.params.suburl}`
      }
    }
  }
}
