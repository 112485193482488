
export default {
  name: 'FormPopup',
  components: {
    TwoStepFormElements: () => import('../forms/two-step-elements.vue')
  },
  props: {
    analyticEvent: {
      type: String,
      required: false,
      default: ''
    }
  }
}
