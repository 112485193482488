
export default {
  name: 'BlogListing',
  props: {
    next: {
      type: [Object, Array],
      required: true
    },
    prev: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    linkClasses () {
      if (this.next.title && !this.prev.title) {
        return 'listing--next'
      }
      if (!this.next.title && this.prev.title) {
        return 'listing--prev'
      }
      return ''
    },
    isLinksAvailable () {
      return (this.prev.title || this.next.title)
    }
  }
}
