
import { DButton } from 'site-lib'
import useCases from '../../plugins/json/useCases.json'

export default {
  name: 'UseCasesBlock',
  components: {
    DButton,
    SliderMultipleItems: () => import('../UI/slider-multiple-items.vue'),
    Card: () => import('../elements/card.vue')
  },
  computed: {
    casesSlides () {
      return useCases.cases
    }
  },
  methods: {
    buttonClick () {
      this.$router.push('/crm-for-business/')
    }
  }
}
