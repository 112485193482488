
import AmpBottomBanner from '../blog/amp-bottom-banner.vue'

export default {
  name: 'CalculationAmp',
  props: ['prices', 'numbers'],
  components: {
    RegButtonAmp: () => import('~/components/forms/reg-button-amp.vue'),
    AmpBottomBanner
  },
  methods: {
    getSaasPrice (code) {
      return this.numbers.saas[code].toLocaleString()
    },
    getTeamPrice (code) {
      return this.numbers.team['5']['12'][code].month.toLocaleString()
    }
  },
  computed: {
    canonicalUrl () {
      return process.env.HOST_NAME + this.$route.fullPath.replace(/\/amp\//, '/')
    }
  },
  head () {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.canonicalUrl
        }
      ]
    }
  }
}
