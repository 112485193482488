
import { CModal } from 'site-lib/index.ts'

export default {
  name: 'imagePopup',
  components: { CModal },
  props: {
    image: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: false,
      default: ''
    }
  }
}
