
export default {
  name: 'SimpleLogoTitleBlock',
  components: {
    RegButton: () => import('../forms/reg-button.vue')
  },
  data: () => ({
    default: {
      imgPath: '/upload/pages/spaces/megaplan_logo_80.png',
      title: 'pages.spaces.create-title',
      buttonText: 'regForm.subtitleIndex',
      note: 'pages.spaces.create-note'
    }
  }),
  props: {
    className: {
      type: String,
      required: false,
      default: ''
    },
    imgPath: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    note: {
      type: String,
      required: false,
      default: null
    },
    buttonText: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    getDefault (param) {
      return this.default[param]
    }
  }
}
