
import Meta from '../../../plugins/json/crm-for-business/meta.json'
import MdHead from '../../../mixins/mdHead'

export default {
  components: {
    AmpCrmPost: () => import('~/components/pages/crm-for-business/crm-for-business-amp.vue'),
    AmpCrmPostExtended: () => import('~/components/pages/crm-for-business/crm-for-business-amp-extended.vue'),
    Prefooter: () => import('~/components/elements/pre-footer.vue')
  },
  mixins: [MdHead],
  beforeDestroy () {
    this.content = null
    this.meta = null
    this.folder = null
  },
  async asyncData ({ params, error }) {
    let fileContent
    let meta = Meta.pages.find((x) => x.slug === params.slug)

    if (typeof meta === 'undefined') {
      meta = {
        title: '',
        subtitle: '',
        description: ''
      }
      error({ statusCode: 404, message: `Запись ${params.slug} не найдена` })
    }

    if (!meta.updated) {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/crm-for-business/' + params.slug + '.md')
        // eslint-disable-next-line no-return-assign
        .then((res) => fileContent = res.default)
        .catch((e) => {
          error({ statusCode: 404, message: `Запись ${params.slug} не найдена\n${e}` })
        })
    }

    if (fileContent) {
      fileContent = fileContent.replace(/v-lazy="(.*?)?"/gm, (match, sub) => `src=${sub}`)
    }

    return {
      content: fileContent,
      meta,
      folder: `pages/crm-for-business/slugs/${params.slug}/`
    }
  }
}
