
import { CModal, CInput, CButton } from 'site-lib/index.ts'

import { getCookie } from '~/mixins/cookie'

export default {
  name: 'PopupMPPlusRequest',
  components: {
    CModal,
    CInput,
    CButton
  },
  data () {
    return {
      form: {
        host: { value: '', errors: [], validators: [] },
        name: { value: '', errors: [], validators: [this.requiredValidator] },
        phone: { value: '', errors: [], validators: [this.requiredValidator, this.numberValidator] }
      },
      formSuccess: false,
      hasSubmitErrors: false
    }
  },
  computed: {
    requestText () {
      return `Запрос за Мегаплан Плюс. аккаунт: \
${this.form.host.value}, имя: ${this.form.name.value}, телефон: ${this.form.phone.value}`
    }
  },
  mounted () {
    this.form.host.value = getCookie('ACCOUNT_URL') || ''
  },
  methods: {
    numberValidator (value) {
      return value.length < 10 ? this.$t('pages.megaplan-plus.modal.formatError') : ''
    },
    requiredValidator (value) {
      return value.length <= 0 ? this.$t('pages.megaplan-plus.modal.requiredError') : ''
    },

    validate () {
      const fields = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(this.form)))
      let valid = true

      fields.forEach((fieldName) => {
        const field = this.form[fieldName]

        field.errors = []
        field.validators.forEach((validator) => {
          const error = validator(field.value)
          if (error) {
            field.errors.push(error)
            valid = false
          }
        })
      })
      return valid
    },

    close () {
      this.closePopup('mp-plus-request')
    },

    async submitForm () {
      if (!this.validate()) {
        return
      }
      this.sendGoalEvent('submitMPPlusRequest')
      const submitResult = await this.$api(
        'requestConsultation',
        'send',
        {
          comment: this.requestText,
          event: this.requestText,
          mailSubject: 'Запрос на Мегаплан Плюс',
          mailContent: this.requestText,
          host: this.form.host.value
        }
      )
      if (submitResult?.status === 'ok') {
        this.formSuccess = true
      } else {
        this.formSuccess = true
        this.hasSubmitErrors = true
      }
    }
  }
}
