
import Content from '../../../plugins/json/articles/meta.json'

export default {
  name: 'ArticlesIndex',
  components: {
    FooterGlowWrapper: () => import('../../../components/elements/footer-glow-wrapper.vue'),
    BlogContent: () => import('../../../components/pages/blog/blog-content.vue')
  },
  data: () => ({
    pages: Content.pages.reverse()
  }),
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: index === 0 ? this.$t(`pages.${path}.title`) : this.$t('pages.news.articles.h1'),
        item: breadcrumbPath(index)
      })
    })
    return {
      title: this.$t('pages.news.articles.title'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('pages.news.articles.description') },
        { hid: 'og:title', name: 'og:title', content: this.$t('pages.news.articles.title') },
        { hid: 'og:description', name: 'og:description', content: this.$t('pages.news.articles.description') }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.$t('pages.news.articles.h1'),
            description: this.$t('pages.news.meta.description'),
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  }
}
