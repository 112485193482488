
import DateFormat from '../../../mixins/dateFormat'

export default {
  name: 'BlogAuthorItems',
  mixins: [DateFormat],
  props: {
    content: {
      type: [Array, Object],
      required: true
    }
  },
  methods: {
    getThumbUrl (path) {
      if (path) {
        const dir = path.substring(0, path.lastIndexOf('/') + 1)
        const fileName = path.split('/').pop()
        return `/upload/blog/${dir}thumb__${fileName}`
      }
      return '/img/plane_thumb.png'
    },
    getErrorThumb (e) {
      e.target.src = e.target.parentNode.parentNode.classList.contains('pic') ? '/img/plane_thumb_full.png' : '/img/plane_thumb.png'
      e.target.style.backgroundColor = `rgba(${Math.round(Math.random() * 255)}
      , ${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, .15)`
      e.target.style.border = '1px solid rgba(0,0,0,.15)'
    }
  }
}
