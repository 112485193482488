
import { CIcon } from 'site-lib'
import PricesHelpers from '../mixins/prices'

export default {
  name: 'Calculation',
  mixins: [PricesHelpers],
  components: {
    CIcon,
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue'),
    NewPriceTable: () => import('~/components/pages/calculation/price-table-new.vue'),
    NewCompareTable: () => import('~/components/pages/calculation/compare-table-new.vue'),
    Benefits: () => import('~/components/elements/benefits.vue'),
    PriceService: () => import('~/components/elements/price-services.vue'),
    PreFooterGradient: () => import('~/components/blocks/pre-footer-gradient.vue')
  },
  data () {
    return {
      activeTab: 'cloud',
      showCompare: false,
      realLang: ''
    }
  },
  computed: {
    tabItems () {
      return [
        { title: this.$t('pages.calculation.cloud'), tab: 'cloud', hash: '#cloud' },
        { title: this.$t('pages.calculation.box'), tab: 'box', hash: '#box' }
      ]
    },
    tariffList () {
      return this.tariffContent.tariffList[this.activeTab]
    }
  },
  created () {
    if (this.$route.hash === '#box') {
      this.activeTab = 'box'
    }
  },
  mounted () {
    this.realLang = this.activeLanguage
    if (this.$route.hash === '#box') {
      window.scrollTo(0, 0)
    }
  },
  amp: 'hybrid',
  ampLayout: 'amp'
}
