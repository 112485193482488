
/**
   * Компонент для вывода следующей страницы для подгрузки на лету
   */

export default {
  name: 'BlogAuthorLoadMore',
  components: {
    Loader: () => import('../../loaders/loader.vue')
  },
  props: {
    authorId: {
      type: Number,
      required: false,
      default: 0
    },
    nextPageNumber: {
      type: Number,
      default: 1
    },
    siteId: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    nextPagePosts: [],
    showLoader: false,
    hasMorePosts: true,
    itemComponent: {
      news: () => import('./news-items.vue'),
      blog: () => import('./blog-items.vue')
    },
    sectionBlogId: process.env.sectionBlogId
  }),
  computed: {
    moreText () {
      return this.siteId === process.env.sectionBlogId
        ? this.$t('pages.blog.more-items')
        : this.$t('pages.news.more-items')
    }
  },
  methods: {
    /**
         * Загрузка новой страницы
         * @param pageNumber
         */
    async loadNextPagePosts (pageNumber = 1) {
      this.showLoader = true

      const limit = 6
      const offset = (pageNumber * limit) - 2

      const result = await this.$api('blogPosts', 'list', {
        authorId: this.authorId,
        siteId: this.siteId,
        limit,
        page: pageNumber,
        offset
      })
      if (result) {
        this.nextPagePosts.push(result)
        document.getElementById(`page${pageNumber}`).classList.add('d-none')
        this.showLoader = false
        if (result.items.length !== limit) {
          this.hasMorePosts = false
        }
      }
    }
  }
}
