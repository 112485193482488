
import RegButtonAmp from '../../forms/reg-button-amp.vue'
import AmpBottomBanner from './amp-bottom-banner.vue'

export default {
  name: 'AmpBlogPost',
  props: ['post', 'siteId'],
  components: {
    RegButtonAmp,
    AmpBottomBanner
  },
  computed: {
    canonicalUrl () {
      return process.env.HOST_NAME + this.$route.fullPath.replace(/\/amp\//, '/')
    },
    megaplanLogo () {
      return 'https://megaplan.ru/img/logo-green.svg'
    }
  },
  head () {
    if (this.post) {
      const metaTitle = this.post.articleMetaTitle.replace(/&nbsp;/g, ' ')
      const metaDescription = this.post.articleMetaDescription.replace(/&nbsp;/g, ' ')

      return {
        title: metaTitle,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: metaDescription
          },
          { hid: 'og:title', property: 'og:title', content: metaTitle },
          {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            hid: 'og:description',
            property: 'og:description',
            content: metaDescription
          },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: this.$t(this.siteId === 1 ? 'pages.news.meta.site-name' : 'pages.blog.meta.title')
          },
          { hid: 'og:type', property: 'og:type', content: 'article' },
          { hid: 'og:url', property: 'og:url', content: this.postUrl },
          { hid: 'twitter:title', property: 'twitter:title', content: metaTitle },
          {
            hid: 'twitter:description',
            property: 'twitter:description',
            content: metaDescription
          },
          { hid: 'og:image:width', property: 'og:image:width', content: '720' },
          { hid: 'og:image:height', property: 'og:image:height', content: '382' }
        ],
        link: [{
          hid: 'canonical',
          rel: 'canonical',
          href: this.canonicalUrl
        }]
      }
    }
    return {
      title: this.$t('pages.blog.meta.common-title')
    }
  }
}
