import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  preLoad: 0,
  error: '/img/error.150.png',
  loading: '/img/spinner.svg',
  attempt: 3,
  lazyComponent: true,
  observer: true,
  throttleWait: 500,
  listenEvents: ['scroll']
})
