

export default {
  name: 'VideoItems',
  props: {
    content: {
      type: [Array, Object],
      default: () => ([])
    }
  }
}
