import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/redirect/`

function getUrl (key) {
  return request(API_URL, `get-url?key=${key}`, null, 'get')
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getUrl
}
