
import { DButton } from 'site-lib'
import ClientsAbout from '../plugins/json/clients/clientsAbout.json'

export default {
  name: 'ReviewsPage',
  components: {
    DButton,
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data () {
    return {
      ratings: ClientsAbout.ratings,
      showedReviews: null,
      showMore: true
    }
  },
  computed: {
    reviews () {
      return ClientsAbout.comments.filter((review) => review.showOnReviewsPage === 'true')
    }
  },
  mounted () {
    this.showedReviews = this.reviews.slice(0, 5)
  },
  methods: {
    showMoreReviews () {
      this.showedReviews = this.reviews
      this.showMore = false
    },
    regAction () {
      const formParams = {
        formFields: [
          {
            name: 'name',
            required: true,
            autofocus: true
          },
          {
            name: 'phone',
            required: true,
            validateOnKey: true,
            rule: 'phone'
          },
          {
            name: 'email',
            required: true,
            validateOnKey: true,
            rule: 'email'
          },
          {
            name: 'company'
          },
          {
            name: 'position'
          },
          {
            name: 'review',
            type: 'textarea'
          }
        ],
        header: this.$t('pages.reviews.leave-review-alt'),
        formSuccessMessage: this.$t('pages.reviews.successModal.title'),
        formSuccessDescription: this.$t('pages.reviews.successModal.subtitle'),
        formSuccessButtonText: this.$t('pages.reviews.successModal.link-text'),
        formSuccessButtonLink: this.$t('pages.reviews.successModal.link-href'),
        formEmailName: 'blogform'
      }
      this.showPopup('form', formParams)
    }
  }
}
