
import { DButton } from 'site-lib'

export default {
  name: 'WebinarsBlock',
  components: {
    DButton
  },
  props: {
    buttonColor: {
      required: false,
      default: 'black',
      type: String
    }
  },
  methods: {
    webinarAction () {
      this.sendGoalEvent('webinar-mainpage')
      window.open('https://my.mts-link.ru/megaplan/megapresentation?utm_source=site-main-page&utm_medium=banner&utm_campaign=body', '_blank').focus()
    }
  }
}
