
import MdHead from '../../../../mixins/mdHead'
import Meta from '../../../../plugins/json/articles/meta.json'

export default {
  name: 'ArticlesSlug',
  mixins: [MdHead],
  async asyncData ({ app, params, store, error }) {
    const currentLocale = store.getters['locale/getLocale']
    let fileContent
    try {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/articles/' + currentLocale + '/' + params.slug + '.md')
        .then((res) => { fileContent = res.default })
    } catch (e) {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/articles/ru/' + params.slug + '.md')
        .then((res) => { fileContent = res.default })
        .catch(() => {
          error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text2')} ${params.slug} ${app.i18n.t('pages.blog.record.text1')}` })
        })
    }

    if (fileContent) {
      fileContent = fileContent.replace(/<RegButton is-center><\/RegButton>/g, '')
      fileContent = fileContent.replace(/<YoutubeVideo video-id=".+"><\/YoutubeVideo>/g, '')
    }
    let meta = Meta.pages.find((x) => x.slug === params.slug)
    if (typeof meta === 'undefined') {
      meta = {
        title: '',
        subtitle: '',
        description: ''
      }
    }
    return {
      content: fileContent,
      meta,
      locale: currentLocale
    }
  },
  beforeDestroy () {
    this.content = null
    this.meta = null
  },
  components: {
    AmpNewsPost: () => import('~/components/pages/amp-news-post.vue')
  }
}
