import { render, staticRenderFns } from "./vnedrenie-crm-sistemy.vue?vue&type=template&id=441c2e23&"
import script from "./vnedrenie-crm-sistemy.vue?vue&type=script&lang=js&"
export * from "./vnedrenie-crm-sistemy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports