
import { DButton } from 'site-lib/index.ts'
import abtest from '../../../mixins/abtest'
import { tagsList } from '../../../plugins/json/clients/meta.json'

export default {
  name: 'ClientCard',
  components: {
    DButton
  },
  mixins: [abtest],
  props: {
    cardSize: {
      type: String,
      default: 'big'
      // small, big
    },
    client: {
      type: Object,
      default: () => ({})
    },
    allCasesPage: {
      type: Boolean,
      default: false
    },
    blogView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hoverVariation () {
      return this.getAbValue('clientCardHover') === 'hover1'
    },
    isCurrentCase () {
      return this.$route.params.page === this.client.slug
    }
  },
  methods: {
    getTagColor (tag) {
      const tagCategory = tagsList.find((cat) => cat.tags.includes(tag))
      return tagCategory.color
    },
    caseClick () {
      this.sendGoalEvent('newClientsCaseClick')
      this.sendGoalEvent('newClientsCaseClick', { 'Новая страница кейсов (клик по кейсу)': this.getAbValue('clientCardHover') })
    }
  },
  mounted () {
    this.needAbTest(process.env.CLIENT_CARD_HOVER, 'clientCardHover')
  }
}
