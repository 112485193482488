
export default {
  name: 'LinkPage',
  data: () => ({
    accounts: [],
    accountUrl: '',
    manualMegaplanHost: '',
    link: ''
  }),

  computed: {
    fullUrl () {
      return this.prepareHost(this.manualMegaplanHost) + this.link
    }
  },

  mounted () {
    this.link = this.prepareLink(this.$route.query.link)
    const rawData = this.getCookie('ACCOUNTS_INFO')
    if (!rawData) {
      return
    }
    const decodedData = decodeURIComponent(rawData)
    if (!decodedData) {
      return
    }
    const parcedData = JSON.parse(decodedData)
    if (!parcedData || !parcedData.accounts) {
      return
    }
    const accounts = []
    this.accounts = parcedData.accounts
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const property in this.accounts) {
      const data = this.accounts[property]
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('lastActivityTime') && data.hasOwnProperty('schemeAndHost')) {
        accounts[data.lastActivityTime] = data.schemeAndHost
      }
    }
    this.accountUrl = accounts.pop()
    if (this.accountUrl) {
      document.location.href = this.accountUrl + this.link
    }
  },

  methods: {
    getCookie (name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      return (parts.length === 2) ? parts.pop().split(';').shift() : ''
    },
    prepareHost (hostUrl) {
      if (!/\./i.test(hostUrl)) {
        hostUrl += '.megaplan.ru'
      }
      try {
        const domain = (new URL(hostUrl))
        hostUrl = domain.host
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return `https://${hostUrl}`
    },
    prepareLink (link) {
      if (link[0] !== '/') {
        link = `/${link}`
      }
      return link
    }
  }
}
