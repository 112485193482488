
export default {
  name: 'blog-quote',
  data () {
    return {
      image: '',
      name: '',
      status: ''
    }
  },
  props: {
    authorid: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true,
      default: ''
    }
  },
  mounted () {
    this.getAuthor()
  },
  methods: {
    async getAuthor () {
      const author = await this.$api('quoteAuthorsData', 'getAuthor', { authorId: this.authorid })

      this.name = author.data.name
      this.status = author.data.post
      this.image = author.data.avatar
    }
  }
}
