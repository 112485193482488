
export default {
  name: 'AwardsCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showText: null
  })
}
