
export default {
  name: 'Awards',
  components: {
    SliderMultipleItems: () => import('../UI/slider-multiple-items.vue'),
    AwardsCard: () => import('../elements/awards-card.vue')
  },
  data: () => ({
    showText: null
  })
}
