
import UserDevice from '../../plugins/utils/userDevice'

export default {
  name: 'MobileStoreRedirectPage',
  mounted () {
    this.redirectToStore()
  },
  methods: {
    redirectToStore () {
      const deviceType = UserDevice().os.split(' ')[0]
      if (deviceType === 'iOS') {
        window.location = 'https://itunes.apple.com/ru/app/%D0%BC%D0%B5%D0%B3%D0%B0%D0%BF%D0%BB%D0%B0%D0%BD-2-0/id1321856023?mt=8'
      } else if (deviceType === 'Android') {
        window.location = 'https://play.google.com/store/apps/details?id=ru.megaplan.megaplan3app'
      } else {
        this.$router.push('/mobile/')
      }
    }
  }
}
