
import Meta from '../../plugins/json/calculation/ampPrices.json'
import Prices from '../../plugins/json/prices.json'

export default {
  name: 'CalculationAmp',
  data () {
    return {
      content: Meta,
      prices: Prices.data.ru.prices
    }
  },
  components: {
    CalculationAmpTemplate: () => import('../../components/pages/calculation/calcucation-amp-template.vue')
  },
  head () {
    return {
      title: this.$t('pages.calculation.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.calculation.meta.description')
        }
      ]
    }
  }
}
