
import { DButton } from 'site-lib'
import PricesHelpers from '../mixins/prices'

export default {
  name: 'ProductFrame',
  layout: 'empty',
  mixins: [PricesHelpers],
  components: {
    DButton,
    TariffCard: () => import('../components/pages/calculation/tariff-card.vue')
  },
  data () {
    return {
      type: 'cloud',
      count: '100',
      period: '12'
    }
  },
  computed: {
    actualPrices () {
      return this.tariffContent.tariffList.cloud.filter((el) => el.code !== 'collaborationplusbusiness')
    }
  }
}
