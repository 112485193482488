
import { CIcon } from 'site-lib'

export default {
  name: 'SubscribeInteractive',
  components: {
    CIcon
  },
  data: () => ({
    success: false,
    email: '',
    message: null
  }),
  computed: {
    successText () {
      return this.complexInsertinon(this.$t('pages.components.subscribe.success-text'), /{link}/,
          `<a href="https://t.me/+90-3jtBorC0yOTRi" target="_blank" rel="nofollow"><span>${this.$t('pages.components.subscribe.link')}</span></a>`)
    }
  },
  methods: {
    async subscribe () {
      const modalMessageErrorsMemberExist = this.$t('pages.modals-text-subscription.errors.emailExist')
      const modalTitleErrors = this.$t('pages.modals-text-subscription.errors.title')
      const modalErrorsCloseButtonText = this.$t('pages.modals-text-subscription.errors.close-button-text')
      const subscribeModalName = 'modal-subscribe'
      if (this.checkEmail()) {
        const result = await this.$api('sendMail', 'subscribe', { email: this.email })
        if (result.result === 'success') {
          this.email = ''
          this.success = true
        } else {
          let modalMessage = ''
          if (result.result === 'errorMemberExist') {
            modalMessage = modalMessageErrorsMemberExist
          }
          this.showPopup(subscribeModalName, {
            dialog: true,
            modalTitle: modalTitleErrors,
            modalMessage,
            closeButtonText: modalErrorsCloseButtonText
          })
        }
      } else {
        this.message = this.$t('pages.components.subscribe.error')
      }
    }
  }
}
