
import { getCookie, setCookie } from '../../mixins/cookie'

export default {
  name: 'RedirectPage',
  props: {
    event: {
      type: String,
      required: false,
      default: ''
    },
    redirectTo: {
      type: String,
      required: true
    }
  },
  methods: {
    process (suburl, redirect = false, cookie = 'Source') {
      if (getCookie(`${suburl}View${cookie}`)) {
        this.$api('updateRedirectViews', 'updateViews', {
          key: suburl,
          isRedirect: redirect,
          isFirst: 0
        })
      } else {
        this.$api('updateRedirectViews', 'updateViews', {
          key: suburl,
          isRedirect: redirect,
          isFirst: 1
        })
        setCookie(`${suburl}View${cookie}`, true)
      }
    }
  },
  mounted () {
    if (process.client) {
      const suburl = this.$route.params.suburl
      this.process(suburl)
      if (this.event) {
        this.sendGoalEvent(this.event)
      }
      setTimeout(() => {
        window.location = this.redirectTo
        this.process(suburl, true, 'Redirect')
      }, 2000)
    }
  }
}
