

// eslint-disable-next-line no-useless-escape
const EmailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export default {
  name: 'AddressRecoveryForm',
  components: {
    Form: () => import('./components/form.vue'),
    Input: () => import('./components/input.vue'),
    Hint: () => import('./components/hint.vue'),
    Button: () => import('./components/button.vue'),
    Error: () => import('./components/error.vue'),
    ArrowForwardIcon: () => import('./components/arrow-forward-icon.vue')
  },
  data: () => ({
    email: '',
    emailError: ''
  }),
  methods: {
    validateEmail () {
      this.emailError = !this.email || EmailRegExp.test(this.email)
        ? ''
        : this.$t('pages.components.address-recovery-form.email-not-valid')
    },
    async sendAddressEmail () {
      if (!this.email) {
        return
      }

      const isSended = await this.$api('account', 'sendEmailWithMegaplanAddress', { email: this.email })
      this.emailError = isSended
        ? ''
        : this.$t('pages.components.address-recovery-form.error-while-send')

      this.$emit('check-email', isSended)
    }
  }
}
