
import { gsap } from 'gsap/dist/gsap.min'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min'
import TabsJson from '../plugins/json/spaces/spaces.json'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Spaces',
  components: {
    SimpleBlock: () => import('../components/blocks/simple-logo-title-reg.vue'),
    SafetyAnimatedBlock: () => import('../components/blocks/safety-animated-block.vue'),
    RegButton: () => import('../components/forms/reg-button.vue'),
    Tabs: () => import('../components/elements/tabs.vue'),
    Companies: () => import('../components/blocks/companies.vue'),
    FormElements: () => import('../components/forms/new/form-elements.vue')
  },
  data () {
    return {
      tabsItems: TabsJson.tabs,
      safetyItems: TabsJson.safety,
      activeTab: TabsJson.tabs[0].tab,
      industryImages: TabsJson.tabs[0],
      blockImages: TabsJson.images,
      spacesImagesCount: 13,
      servicesCount: 2,
      baseEase: 'power2.inOut',
      spacesImagesTrigger: '.spaces__images',
      observer: null
    }
  },
  mounted () {
    this.$refs.spacesIndustryWrapper.style.height = `${this.$refs.spacesIndustryImages.offsetHeight}px`
    this.setObserver()
    if (!window.matchMedia('(max-width: 1023px)').matches) {
      ScrollTrigger.defaults({ scroller: '.layout__content' })
      // GSAP
      gsap.from(this.$refs.mainImg, {
        scrollTrigger: {
          trigger: '.spaces__header-image',
          start: 'top 50%',
          end: '80% bottom',
          scrub: true
        },
        translateY: 50,
        rotateX: 20,
        lazy: true
      })
      gsap.from(this.$refs.industryTitle, {
        scrollTrigger: {
          trigger: '.spaces__industry',
          start: 'top 85%'
        },
        lazy: true,
        duration: 0.6,
        scale: 0.2,
        ease: this.baseEase,
        xPercent: -100,
        opacity: 0
      })
      const tabs = document.querySelectorAll('.tab-item')
      gsap.timeline({
        scrollTrigger: {
          trigger: '.spaces__industry',
          start: 'top 85%'
        }
      }).from(tabs, {
        lazy: true,
        duration: 0.2,
        ease: this.baseEase,
        x: -100,
        y: -50,
        scale: 0.2,
        opacity: 0,
        delay: 0.4,
        stagger: 0.1
      })
      // imagesBlock
      gsap.from(this.$refs.spacesImagesItem0, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: -70,
        yPercent: -100,
        scale: 0.5,
        autoAlpha: 0
      })
      gsap.from(this.$refs.spacesImagesItem1, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: -50,
        yPercent: -150,
        scale: 0.5,
        autoAlpha: 0
      })
      gsap.from(this.$refs.spacesImagesItem2, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: 25,
        yPercent: -155,
        scale: 0.5,
        autoAlpha: 0
      })
      gsap.from(this.$refs.spacesImagesItem3, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: 50,
        yPercent: -105,
        scale: 0.5,
        autoAlpha: 0
      })
      gsap.from(this.$refs.spacesImagesItem4, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: -10,
        yPercent: -100,
        scale: 0.5,
        autoAlpha: 0
      })
      gsap.from(this.$refs.spacesImagesItem5, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        yPercent: -70,
        xPercent: -100,
        scale: 0.05,
        autoAlpha: 0
      })
      gsap.from(this.$refs.spacesImagesItem6, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: 70,
        yPercent: -250,
        scale: 0.05,
        autoAlpha: 0
      })
      gsap.from(this.$refs.spacesImagesItem7, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: 100,
        yPercent: -50,
        scale: 0.05,
        autoAlpha: 0.3
      })
      gsap.from(this.$refs.spacesImagesItem8, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: 100,
        yPercent: 100,
        scale: 0.05,
        autoAlpha: 0.3
      })
      gsap.from(this.$refs.spacesImagesItem9, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: 100,
        yPercent: 100,
        scale: 0.05,
        autoAlpha: 0.3
      })
      gsap.from(this.$refs.spacesImagesItem10, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 70%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        xPercent: -100,
        yPercent: -20,
        scale: 0.05,
        autoAlpha: 0.3
      })
      gsap.from(this.$refs.spacesImagesItem11, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 85%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        yPercent: 30,
        scale: 0.05,
        autoAlpha: 0.3
      })
      gsap.from(this.$refs.spacesImagesItem12, {
        scrollTrigger: {
          trigger: this.spacesImagesTrigger,
          start: 'top 80%',
          end: 'top 15%',
          scrub: true
        },
        lazy: true,
        yPercent: 70,
        xPercent: 30,
        scale: 0.05,
        autoAlpha: 0.3
      })
      // integration
      gsap.timeline({
        scrollTrigger: {
          trigger: '.spaces__services',
          start: 'top 50%'
        }
      }).from(this.$refs.servicesItems, {
        lazy: true,
        duration: 1,
        ease: this.baseEase,
        x: -100,
        scale: 0.2,
        opacity: 0,
        stagger: 0.2
      })
    }
  },
  methods: {
    setTab (tab) {
      this.industryImages = this.tabsItems.find((item) => item.tab === tab)
      this.activeTab = tab
    },
    setWrapperHeight (height) {
      if (height > 100) {
        this.$refs.spacesIndustryWrapper.style.height = `${height}px`
      }
    },
    setObserver () {
      this.observer = new ResizeObserver(([{ target }]) => {
        this.setWrapperHeight(target.getBoundingClientRect().height)
      })
      this.observer.observe(this.$refs.spacesIndustryWrapper.children[0])
    },
    removeObserver () {
      if (this.observer) {
        this.observer.unobserve(this.$refs.spacesIndustryWrapper.children[0])
        this.observer = null
      }
    },
    getFormParams () {
      return {
        header: this.$t('pages.spaces.question-title'),
        subHeader: this.$t('pages.spaces.question-subtitle'),
        formEmailTitle: this.$t('pages.spaces.formEmailTitle'),
        formSuccessMessage: this.$t('regForm.defaultSuccessMessage'),
        formFields: [
          {
            name: 'name'
          },
          {
            name: 'phone',
            validateOnKey: true,
            required: true,
            rule: 'phone'
          },
          {
            name: 'email',
            validateOnKey: true,
            required: true,
            rule: 'email'
          }
        ]
      }
    }
  },
  beforeDestroy () {
    this.removeObserver()
  },
  head () {
    return {
      title: this.$t('pages.spaces.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.spaces.meta.description')
        }
      ]
    }
  }
}
