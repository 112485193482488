
export default {
  name: 'FooterGlowWrapper',
  components: {
    Prefooter: () => import('./pre-footer.vue'),
    Footer: () => import('./footer.vue')
  },
  props: {
    prefooter: {
      type: Boolean,
      default: false
    }
  }
}
