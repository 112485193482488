
import { DButton, CIcon } from 'site-lib'
import Helpers from '../../../mixins/helpers'
import PricesHelpers from '../../../mixins/prices'

export default {
  name: 'tariff-card',
  mixins: [PricesHelpers, Helpers],
  components: {
    DButton,
    CIcon
  },
  props: {
    sale: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    count: {
      type: String,
      required: true
    },
    period: {
      type: String,
      required: false
    },
    order: {
      type: Number,
      required: false
    },
    shortCard: {
      type: Boolean,
      default: false
    },
    isRegButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    teamTariff () {
      return this.type === 'cloud' && this.count === '5'
    },
    mainTariff () {
      return this.type === 'cloud' && this.count === '100'
    },
    boxUnlimited () {
      return this.type === 'box' && this.count === 'unlimited'
    },
    cloudUnlimited () {
      return this.type === 'cloud' && this.count === 'unlimited'
    },
    unlimitedTariff () {
      return this.cloudUnlimited || this.boxUnlimited
    },
    getItemPrice () {
      if (this.boxUnlimited) {
        return this.item[this.activeLanguage].prices.filter((x) => x.month === this.count)
      }
      if (this.teamTariff) {
        return this.item[this.activeLanguage].prices.filter(
          (el) => (el.month === '3' && el.period === this.period) || (el.month === '5' && el.period === this.period)
        )
      }
      if (this.unlimitedTariff) {
        return this.item[this.activeLanguage].prices.filter((e) => e.period === this.period)
      }
      return []
    },
    bindClasses () {
      return {
        'fixed-height': this.type === 'cloud' && this.count === '5',
        'unlim-fixed-height': this.count === 'unlimited',
        'no-grow': (this.type === 'box' && this.count === '300') || (this.type === 'box' && this.count === '1000'),
        box: this.type === 'box' && this.count !== 'unlimited',
        sale: this.type === 'box' && this.sale
      }
    },
    renderCondition () {
      return this.teamTariff || this.unlimitedTariff || this.boxUnlimited
    },
    tariffPossibilitiesList () {
      const items = this.getTariffInfoNew(this.item.code).possibilitiesList
      if (this.type === 'box') {
        const idx = items.indexOf('2 ТБ для файлов')
        const itemsCopy = [...items.slice(0, idx), ...items.slice(idx + 1)]
        const chatIndex = itemsCopy.indexOf('Чаты и видеозвонки')
        itemsCopy[chatIndex] = 'Чаты'
        return itemsCopy
      }
      return items
    }
  },
  methods: {
    getTariffInfoNew (tariffCode) {
      let type = this.type
      if (this.teamTariff) {
        type = 'team'
      }
      const tariffInfo = this.getTariffListByCount(type).find((e) => e.code === tariffCode)
      let locale = []
      if (typeof this.$t('pages.components.prices.blocksNew').find((e) => e.product === tariffInfo.code) !== 'undefined') {
        locale = this.$t('pages.components.prices.blocksNew').find((e) => e.product === tariffInfo.code)
      }
      const links = [
        {
          code: 'collaborationplus',
          link: '/collaboration/'
        },
        {
          code: 'collaborationplusbusiness',
          link: '/collaboration-business/'
        },
        {
          code: 'crmplus',
          link: '/crm-for-clients/'
        },
        {
          code: 'crmbusiness',
          link: '/crm-biznes-process/'
        },
        {
          code: 'crmlite',
          link: '/crm-lite/'
        }
      ]
      const tariffLinks = links.find((e) => e.code === tariffCode)
      if (tariffLinks) {
        locale.href = tariffLinks.link
      }
      locale.product = tariffInfo.code
      return locale
    },
    formAction (tariff) {
      const formParams = {
        formEmailName: 'price',
        formEmailTitle: 'Запрос безлимит тарифы.',
        header: this.$t('pages.calculation.form.header'),
        metricsTarget: 'tariffFormRequest',
        tariffCode: tariff
      }
      this.showPopup('form', formParams)
    },
    getLicenseForPeriodText () {
      if (this.period === '1') {
        return this.$t('pages.components.prices.licensePeriod')
      }
      if (this.period === '12') {
        return this.$t('pages.components.prices.licensePeriodYear')
      }
      return ''
    },
    getBoxPriceWithDiscount (item) {
      const fullPrice = this.getSpecialPrice(item, 'price')
      const priceToNum = Number(fullPrice.replace(/\s/g, ''))
      const finalPrice = Math.round(priceToNum * 0.7)
      return finalPrice.toLocaleString().replace(/,/g, ' ')
    },
    calcDiscount (item, idx) {
      if (this.teamTariff) {
        return this.item[this.activeLanguage].prices.filter(
          (el) => (el.month === '3' && el.period === '1') || (el.month === '5' && el.period === '1')
        )[idx].price * 12
      }
      if (this.unlimitedTariff) {
        return this.item[this.activeLanguage].prices.filter((e) => e.period === '1')[idx].price * 12
      }
      return '0 000'
    }
  }
}
