
import Meta from '../../plugins/json/clients/meta.json'
import Crosslinks from '../../plugins/json/crm-for-business/meta.json'
import MdHead from '../../mixins/mdHead'
import userDevice from '../../plugins/utils/userDevice'

export default {
  name: 'ClientsSlug',
  components: {
    ClientCard: () => import('../../components/pages/clients/client-card.vue'),
    ClientCardInner: () => import('../../components/pages/clients/client-card-inner.vue'),
    ClientFeatures: () => import('../../components/pages/clients/client-features.vue'),
    ClientTryBlock: () => import('../../components/pages/clients/client-try-block.vue'),
    BreadCrumbs: () => import('../../components/elements/breadcrumbs.vue'),
    ClientBanner: () => import('../../components/pages/clients/client-banner.vue'),
    Prefooter: () => import('../../components/elements/pre-footer.vue'),
    NotFoundPage: () => import('../404.vue'),
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue')
  },
  data () {
    return {
      disableSharing: false,
      sharingAnimation: false,
      isMobile: false,
      content: undefined,
      crosslinks: [],
      specials: []
    }
  },
  mixins: [MdHead],
  async asyncData ({ params, error }) {
    let meta = Meta.pages.find((x) => x.slug === params.page)
    if (!meta) {
      meta = {
        title: '',
        subtitle: '',
        description: ''
      }
      error({ statusCode: 404, message: `Запись ${params.page} не найдена` })
    }
    return {
      otherClients: Meta.pages.slice(0, 3).filter((x) => x.slug !== params.page).slice(0, 2),
      slug: params.page,
      meta
    }
  },
  async mounted () {
    this.isMobile = userDevice().device !== 'desktop'
    const localeMessages = this.$i18n.messages[this.activeLanguage]
    const { record } = localeMessages.pages.clients || this.$i18n.messages.ru.pages.clients

    if (typeof this.meta.crosslinks !== 'undefined') {
      this.meta.crosslinks.forEach((el) => {
        const crosslinkObject = this.getCrossLinkObject(el)
        if (!(crosslinkObject.desktopOnly && this.isMobile)) {
          const { crosslink } = Crosslinks.pages.find((x) => x.slug === crosslinkObject.name)
          if (typeof crosslink !== 'undefined') {
            crosslink.href = crosslinkObject.name
            this.crosslinks.push(crosslink)
          }
        }
      })
    }
    if (typeof this.meta !== 'undefined') {
      if (typeof this.meta.special !== 'undefined') {
        const special = this.meta.special
        this.specials.push(special)
      }
      // eslint-disable-next-line
      await import('~/assets/md/clients/' + this.slug + '.md')
        // eslint-disable-next-line no-return-assign
        .then((res) => this.content = res.default)
        .catch(() => {
          throw new Error(`${record.text} ${this.slug} ${record.text1}`)
        })
    }
  },
  methods: {
    getCrossLinkObject (crosslink) {
      if (typeof crosslink === 'string') {
        return {
          name: crosslink
        }
      }
      return crosslink
    },
    sharingCase () {
      if (this.disableSharing) {
        return false
      }
      this.sharingAnimation = true
      this.disableSharing = true
      navigator.clipboard.writeText(window.location.href)
      setTimeout(() => { this.sharingAnimation = false }, 3850)
      setTimeout(() => { this.disableSharing = false }, 5000)
      return false
    }
  },
  computed: {
    renderedMd ({ isMobile }) {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate,
        data () {
          return {
            isMobile
          }
        }
      }
    },
    previewPosition () {
      return this.isExist(this.meta.preview_position)
        ? ` text-${this.meta.preview_position}`
        : ''
    }
  },
  beforeDestroy () {
    this.content = null
    this.meta = null
    this.slug = null
    this.crosslinks = null
  },
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: index === 0 ? this.$t(`pages.${path}.title`) : this.meta.title,
        item: breadcrumbPath(index)
      })
    })
    return {
      title: this.meta.title,
      meta: [
        { hid: 'description', name: 'description', content: this.meta.description },
        { hid: 'og:title', name: 'og:title', content: this.meta.title },
        { hid: 'og:description', name: 'og:description', content: this.meta.description }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.meta.title,
            description: this.meta.description,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  }
}
