
export default {
  name: 'NewsPostPage',
  components: {
    PostContent: () => import('../../../components/pages/blog/blog-post-content.vue')
  },
  async asyncData ({ app, params, error }) {
    let postTryings = 0
    let post
    while ((typeof post === 'undefined' || typeof post.item === 'undefined' || !post.item) && postTryings < 5) {
      // eslint-disable-next-line no-await-in-loop
      post = await app.$api('blogPosts', 'article', {
        slug: params.post,
        siteId: 1
      })
      postTryings += 1
    }
    const showComments = post.item.isActiveComments === 1
    let comments = []
    if (showComments && typeof post !== 'undefined') {
      const postUrl = `/news/${post.item.articleCategorySlug}/${post.item.articleSlug}/`
      let commentsTryings = 0
      let response
      while (!response && commentsTryings < 5) {
      // eslint-disable-next-line no-await-in-loop
        response = await app.$api('comments', 'getLocalComments', postUrl)
        if (response?.status === 'ok') {
          comments = response.comments
        }
        commentsTryings += 1
      }
    }
    if (typeof post === 'undefined' || typeof post.item === 'undefined' || !post.item) {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text2')} ${params.post} ${app.i18n.t('pages.blog.record.text1')}` })
    }
    const postData = post.item
    return {
      post: postData,
      showComments,
      comments
    }
  }
}
