
import { DButton } from 'site-lib'

export default {
  name: 'Prefooter',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    buttonText: {
      type: String,
      required: false
    },
    hideButtonIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    popupFormName: {
      type: String,
      default: 'reg-form',
      required: false
    }
  },
  components: {
    DButton,
    RegButtonAmp: () => import('../forms/reg-button-amp.vue')
  },
  data () {
    return {
      internalTitle: this.title !== ''
        ? this.title
        : this.$t('pages.components.prefooter.title'),
      internalDescription: this.description
        ? this.description
        : this.$t('pages.components.prefooter.description', { days: 14 })
    }
  }
}
