
export default {
  name: 'capability-card',
  props: {
    item: {
      type: Object,
      required: true
    },
    imagesFolder: {
      type: String,
      required: true
    }
  }
}
