
import { DButton } from 'site-lib'

export default {
  name: 'blog-custom-form',
  components: {
    DButton
  },
  props: {
    formTitle: {
      type: String,
      default: ''
    },
    popupTitle: {
      type: String,
      default: ''
    }
  },
  methods: {
    regAction () {
      const formParams = {
        formFields: [
          {
            name: 'name',
            autofocus: true
          },
          {
            name: 'email',
            required: true,
            validateOnKey: true,
            rule: 'email'
          },
          {
            name: 'phone',
            required: true,
            validateOnKey: true,
            rule: 'phone'
          },
          { name: 'company' }
        ],
        header: this.popupTitle,
        subHeader: this.$t('pages.index.form.subtitle'),
        formEmailName: 'price',
        metricsTarget: 'submitBlogCustomForm'
      }
      this.showPopup('form', formParams)
    }
  }
}
