
import { CIcon, DButton } from 'site-lib'

export default {
  name: 'DataProtectionBlock',
  components: {
    CIcon,
    DButton
  },
  methods: {
    buttonClick () {
      this.$router.push('/security/')
    }
  }
}
