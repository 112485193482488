
import Vue from 'vue'
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)
export default {
  name: 'YoutubeVideo',
  props: {
    videoId: {
      type: String,
      default: ''
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    mute: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalAutoPlay: this.autoplay
        ? 1
        : 0
    }
  },
  mounted () {
    if (this.mute) {
      this.muteVideo()
    }
  },
  methods: {
    muteVideo () {
      this.$refs.youtube.player.mute()
    }
  }
}
