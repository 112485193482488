
import ClientsMeta from '../../../plugins/json/clients/meta.json'

export default {
  name: 'BlogContentNew',
  components: {
    BlogSearch: () => import('./blog-search.vue'),
    BlogPinnedPost: () => import('./blog-pinned-post.vue'),
    BlogSearchResult: () => import('./blog-search-result.vue'),
    BlogLoadNextPage: () => import('./blog-load-next-page.vue'),
    DButton: () => import('../../site-lib/src/components/DButton/DButton.vue'),
    SubscribeInteractive: () => import('./subscribe-interactive.vue'),
    BlogMenu: () => import('./blog-menu.vue'),
    ClientCard: () => import('~/components/pages/clients/client-card.vue'),
    PreFooter: () => import('~/components/elements/pre-footer.vue')
  },
  data () {
    return {
      isSearchOpen: false,
      searchResults: undefined,
      clients: ClientsMeta.pages.slice(0, 2),
      sectionBlogId: process.env.sectionBlogId,
      items: {
        blogList: () => import('./blog-items-list.vue'),
        popular: () => import('./blog-items.vue'),
        news: () => import('./news-items.vue')
      },
      internalItemsTitle: this.itemsTitle === ''
        ? this.$t('pages.components.blog-content.new-posts')
        : this.itemsTitle
    }
  },
  props: {
    siteId: {
      type: Number,
      default: 1
    },
    posts: {
      type: [Array, Object],
      default: () => ([])
    },
    popular: {
      type: [Array, Object],
      default: () => ([])
    },
    updates: {
      type: [Array, Object],
      default: () => ([])
    },
    videos: {
      type: [Array, Object],
      default: () => ([])
    },
    categoryId: {
      type: Number,
      default: 0
    },
    // для формата вывода айтемов в блоге "grid", "grid2", "row4", "row3"
    layout: {
      type: String,
      default: 'grid'
    }
  },
  methods: {
    inputStatus (data) {
      this.isSearchOpen = data
    }
  }
}
