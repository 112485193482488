export default {
  watch: {
    /**
     * Отправляем в метрику событие изменения урл
     */
    $route () {
      this.sendPageView()
    }
  },
  methods: {
    async sendPageView () {
      if (!process.browser) {
        return
      }
      const gaObj = window.ga
      const lastLocation = window.sessionStorage.getItem('lastLocation')
      if (!lastLocation && process.env.VISITOR_LOG === 'on') {
        setTimeout(() => {
          this.sendNewVisitor()
        }, 2000)
      }
      if (window.location.href !== lastLocation) {
        window.sessionStorage.setItem('lastLocation', window.location.href)
        if (this.isExist(window.ym)) {
          window.ym(process.env.YANDEX_ID, 'hit', window.location.href, lastLocation ? {
            referer: lastLocation
          } : {})
        }
        if (this.isExist(gaObj)) {
          if (lastLocation) {
            window.ga('set', 'referrer', lastLocation)
          }
          window.ga('set', 'page', window.location.href)
          window.ga('send', 'pageview')
        }
      }
    },
    async sendGoalEvent (event, params) {
      if (process.browser) {
        if (this.isExist(window.ym)) {
          window.ym(process.env.YANDEX_ID, 'reachGoal', event, params)
        }
        if (this.isExist(window.ga)) {
          window.ga('send', 'event', 'megaplan', event)
        }
      }
    },
    sendNewVisitor () {
      this.$api('analytic', 'addVisitor', {
        referer: document.referrer,
        ga_cid: this.getGaCid(),
        ya_cid: this.getYaCid(),
        uuid: this.generateUuid(),
        start_page: window.location.href
      })
    },
    getGaCid () {
      const gaObj = window.ga
      if (this.isExist(gaObj)
        && typeof gaObj.getAll === 'function'
        && this.isExist(gaObj.getAll()[0])
        && typeof gaObj.getAll()[0].get === 'function'
      ) {
        return gaObj.getAll()[0].get('clientId')
      }
      return ''
    },
    getYaCid () {
      if (
        this.isExist(window.yaCounter20277427)
      ) {
        return window.yaCounter20277427.getClientID()
      }
      return ''
    },
    generateUuid () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise,no-mixed-operators
        const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  }
}
