
import Vue, { PropType } from 'vue'
import { CIcon, IconNames } from '../CIcon'

type Sizes =  'xs' | 'small' | 'normal' | 'big'
type Colors = 'black' | 'green' | 'white' | 'grey' | 'stroke' | 'link' | 'grey2' | 'arrow'

export default Vue.extend({
  name: 'DButton',
  components: {
    CIcon
  },
  props: {
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    className: {
      type: String,
      required: false
    },
    iconName: {
      type: String as PropType<keyof typeof IconNames>,
      required: false
    },
    iconSize: {
      type: [String, Number] as PropType<Sizes | number>,
      required: false,
      default: 'small' as Sizes
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<Sizes>,
      required: false,
      default: 'normal' as Sizes
    },
    color: {
      type: String as PropType<Colors>,
      required: false,
      default: 'green' as Colors
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    href: {
      type: String,
      required: false
    },
    // название файла, если кнопка это ссылка на скачивание
    download: {
      type: String,
      required: false
    },
    blank: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    sizeClass(): string {
      return `button-size-${this.size}`
    },
    colorClass(): string {
      return `button-color-${this.color}`
    },
    iconClass(): string {
      return this.disabled ? 'button-icon-disabled' : 'button-icon'
    },
    showText(): boolean {
      return this.color !== 'arrow'
    }
  },
  methods: {
    onClick() {
      if (!this.disabled && !this.href) {
        this.$emit('click')
      }
    }
  }
})
