
export default {
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      validator: (value) => ['green', 'flat'].includes(value),
      default: 'green'
    }
  },
  methods: {
    onClick (event) {
      if (this.disabled) {
        return
      }
      this.$emit('click', event)
    }
  }
}
