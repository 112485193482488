export default {
  setTariff (state, tariff) {
    state.tariff = tariff
  },
  setType (state, type) {
    state.type = type
  },
  setCount (state, count) {
    state.count = count
  },
  setPeriod (state, period) {
    state.period = period
  },
  setEfficiency (state, efficiency) {
    state.efficiency = efficiency
  },
  setCosts (state, costs) {
    state.costs = costs
  }
}
