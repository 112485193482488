
import Vue from 'vue'
import Helpers from '../mixins/helpers'
import analytics from '../mixins/analytics'
import callTracking from '../mixins/callTracking'
import bannersStatistics from '../mixins/bannersStatistics'

// Подключаем миксин глобально
Vue.mixin(Helpers)
Vue.mixin(analytics)
Vue.mixin(callTracking)
Vue.mixin(bannersStatistics)

Vue.component('DButton', () => import('../components/site-lib/src/components/DButton/DButton.vue'))
Vue.component('PrintImage', () => import('../components/elements/print-image.vue'))
export default {
  data: () => ({
    loadPopupObject: '',
    showPopupObject: false,
    popupParams: {}
  }),
  computed: {
    loadPopupGeneratorComponent () {
      if (process.env.NEW_MODAL === 'on') {
        return () => import('~/components/popup/new/c-modal-container.vue')
      }
      return () => import('~/components/popup/popup-generator.vue')
    }
  },
  created () {
    this.$root.$on('popupGenerator', (popupName, show, params = {}) => {
      this.loadPopupObject = popupName
      this.showPopupObject = show
      this.popupParams = params
    })
  }
}
