
import Vue from "vue";
import { CIcon } from "../CIcon";

export default Vue.extend({
  name: "DCheckbox",
  components: {
    CIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    labelText: {
      type: String,
      required: false,
      default: "Text"
    },
    className: {
      type: String,
      required: false,
    },
    error: {
      type: Boolean,
      required: false
    },
  }
});
