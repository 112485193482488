
async function loadData (query, $api) {
  const { categorySlug, postSlug, siteId } = query
  const postData = await $api('blogPosts', 'article', {
    slug: postSlug,
    siteId,
    isPreview: 1
  })
  const post = postData.item
  if (!post || post.articleCategorySlug !== categorySlug) {
    throw new Error('Post not found')
  }
  const populars = await $api('blogPosts', 'list', {
    categoryId: post.articleCategoryId,
    siteId,
    limit: 4,
    popular: 1
  })
  const items = await $api('blogPosts', 'list', {
    siteId: 2,
    limit: 4
  })
  const blogItems = items.items.filter((el) => el.slug !== postSlug)
  const blogPopulars = populars.items.filter((el) => el.slug !== postSlug)
  if (blogItems.length === 4) {
    blogItems.splice(-1)
  }
  if (blogPopulars.length === 4) {
    blogPopulars.splice(-1)
  }
  return {
    post,
    items: blogItems,
    populars: blogPopulars,
    siteId: Number(siteId)
  }
}
export default {
  components: {
    PostContent: () => import('../../components/pages/blog/blog-post-content.vue')
  },
  name: 'BlogPostPage',
  async beforeMount () {
    if (!this.isExist(this.post) && process.browser) {
      const data = await loadData(this.$route.params, this.$api)
      this.post = data.post
      this.items = data.item
      this.populars = data.populars
    }
  },
  async asyncData ({ app, query, error }) {
    try {
      return await loadData(query, app.$api)
    } catch (e) {
      return error({ statusCode: 404 })
    }
  }
}
