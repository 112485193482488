
export default {
  name: 'CompanyDetails',
  props: {
    short: {
      type: Boolean,
      default: false
    }
  }
}
