
export default {
  name: 'ComparePage',
  components: {
    Prefooter: () => import('../components/elements/pre-footer.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data () {
    return {
      lang: 'ru'
    }
  },
  mounted () {
    this.lang = this.activeLanguage
  }
}
