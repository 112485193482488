
import * as typeformEmbed from '@typeform/embed'

export default {
  name: 'index',
  components: {
    Loader: () => import('../../components/loaders/loader.vue'),
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    title: '',
    ankFrame: '',
    formSlug: '',
    typeform: false
  }),
  async mounted () {
    if (process.browser) {
      const formSlug = this.$route.params.id
      const regular = /\D+/g
      // Если есть буквы в id то typeform, в противном случае Анкетолог
      if (regular.test(formSlug) || formSlug === '') {
        const { status, url, title } = await this.$api('interview', 'TypeformGetById', { formSlug })
        this.typeform = true
        const notFoundRedirect = () => {
          this.$router.push('/404/')
        }
        if (status === 'error') {
          notFoundRedirect()
        }
        this.title = title
        const testRedirect = () => {
          this.$router.push('/blog/')
        }
        const typeform = typeformEmbed.makePopup(`${url}${this.$route.hash}`, {
          mode: 'popup',
          onSubmit: () => {
            testRedirect()
          }
        })
        // Открываем модалку
        typeform.open()
        // Т.к. модалка в iFrame чуток ждем с учетом моб. интернета 2сек впору.
        setTimeout(() => {
          if (document.querySelectorAll('[data-qa="popup-close-button"]').length > 0) {
            // Находим кнопку закрыть и генерим событие
            const element = document.querySelectorAll('[data-qa="popup-close-button"]')[0]
            element.addEventListener('click', () => {
              testRedirect()
            }, false)
          }
        }, 2000)
      } else {
        const { status, ankTitle, ankFrame } = await this.$api('interview', 'AnketaGetById', { formSlug })
        const notFoundRedirect = () => {
          this.$router.push('/404/')
        }
        if (status === 'error') {
          notFoundRedirect()
        }
        this.title = ankTitle
        this.ankFrame = ankFrame
        const u = `https://anketolog.ru/api/v2/frame/js/${formSlug}?token=${ankFrame}`
        const s = document.createElement('script')
        this.formSlug = formSlug
        s.type = 'text/javascript'
        s.async = true
        s.src = u
        window.document.body.appendChild(s)
      }
    }
    return true
  },
  head () {
    return {
      title: this.title || this.$t('pages.test.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.test.meta.description')
        }
      ]
    }
  }
}
