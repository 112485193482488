
import { CButton } from 'site-lib'

export default {
  name: 'VkShareButton',
  components: {
    CButton
  },
  props: {
    text: {
      type: String,
      required: false,
      default: 'Поделиться ВКонтакте'
    }
  }
}
