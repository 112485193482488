
import { CModal, CButton } from 'site-lib/index.ts'

export default {
  name: 'VkSharePopup',
  components: {
    CModal,
    CButton
  },
  props: {
    header: {
      type: String,
      required: false,
      default: 'Расскажите о своем опыте работы \nв Мегаплане и получите подарок'
    }
  },
  methods: {
    sharePost () {
      // eslint-disable-next-line
      const dataAttr = this.$refs.shareLink.$attrs
      const url = `https://vk.com/share.php?url=${dataAttr['data-url']}&title=${dataAttr['data-title']}&comment=${dataAttr['data-text']}`
      window.open(url.replace(/ /g, '%20').replace(/#/g, '%23'), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
    }
  }
}
