
export default {
  data: () => ({
    loading: false,
    progress: 0,
    duration: 5000 // ms
  }),
  watch: {
    loading (cur, prev) {
      if (cur !== prev) {
        this.progress = 0

        if (this.interval) {
          clearInterval(this.interval)
          delete this.interval
        }

        if (cur) {
          this.interval = setInterval(
            () => {
              // eslint-disable-next-line no-plusplus
              if (++this.progress > 100) {
                this.progress = 0
              }
            },
            this.duration / 100 // so, it's 100 chunks for all
          )
        }
      }
    }
  },
  methods: {
    start () {
      this.loading = true
    },
    finish () {
      this.loading = false
    },
    fail () {
      this.loading = false
    }
  }
}
