
import { CIcon } from 'site-lib/index.ts'

export default {
  name: 'Collapse',
  components: {
    CIcon
  },
  data () {
    return {
      opened: false
    }
  },
  props: {
    caption: {
      type: String,
      required: true
    }
  },
  methods: {
    onChange () {
      this.opened = !this.opened
    }
  }
}
