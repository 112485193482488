

export default {
  name: 'TagsSlider',
  props: {
    tags: {
      type: Array,
      required: true
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      trackPosition: 0,
      trackEnd: 0,
      sliderBodyWidth: 0,
      sliderBodyOverflow: true,
      trackWidth: 0,
      showPrevBtn: false,
      showNextBtn: true,
      step: this.mobile ? 150 : 200
    }
  },
  methods: {
    initSlider () {
      this.sliderBodyWidth = this.$refs.sliderTrack.offsetParent?.offsetWidth
      this.trackWidth = this.$refs.sliderTrack.scrollWidth
      this.showNextBtn = this.trackWidth > this.sliderBodyWidth
      this.trackEnd = -(this.trackWidth - this.sliderBodyWidth)
      this.sliderBodyOverflow = this.trackWidth > this.sliderBodyWidth
    },
    moveTrack (step) {
      this.trackPosition += step

      if (this.trackEnd > this.trackPosition) {
        this.trackPosition = this.trackEnd
      }
      if (this.trackPosition > 0) {
        this.trackPosition = 0
      }
      this.$refs.sliderTrack.style.transform = `translateX(${this.trackPosition}px)`
      this.checkBtnVisibility()
    },
    checkBtnVisibility () {
      this.showPrevBtn = this.trackPosition < 0
      this.showNextBtn = this.trackPosition > this.trackEnd
    },
    mouseWheel (e) {
      e.deltaY > 0 ? this.moveTrack(-this.step) : this.moveTrack(this.step)
    }
  },
  mounted () {
    const interval = setInterval(() => {
      if (this.$refs.sliderTrack) {
        clearInterval(interval)
        this.initSlider()
      }
    }, 50)
    this.$nextTick(() => {
      window.addEventListener('resize', this.initSlider)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.initSlider)
  }
}
