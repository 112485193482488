
import updateList from '../../plugins/json/updates/updatesList.json'
import MdHead from '../../mixins/mdHead'

export default {
  name: 'UpdatesPage',
  components: {
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue')
  },
  mixins: [MdHead],
  asyncData ({ params }) {
    const content = updateList.pages.find((x) => x.link === params.page)

    return {
      content,
      slug: params.page
    }
  },
  beforeDestroy () {
    this.content = null
    this.slug = null
  },
  methods: {
    setContentContainerClass (i, item) {
      return this.content.is_mobile && (i + 1) % 2 === 0
        ? { 'update-block': true, 'flex-column': item.class.text === 'c-center', 'bg-gray': 'bg-gray' }
        : { 'update-block': true, 'flex-column': item.class.text === 'c-center' }
    },
    setContentItemClass (item, className) {
      if (className === 'c-left') {
        return item.class.text === className
          ? `${item.class.text} order-2 order-lg-1`
          : `${item.class.text} order-2 order-lg-2`
      }
      return item.class.image === className
        ? `${item.class.image} order-1 order-lg-2`
        : `${item.class.image} order-1 order-lg-1`
    }
  },
  head () {
    return {
      title: `${this.content.title} | ${this.$t('pages.updates.meta.title')}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `${this.$t('pages.updates.meta.common-text')} ${this.content.title}, ${this.$t('pages.updates.meta.description')}`
        }

      ]
    }
  }
}
