

export default {
  name: 'breadcrumbs',
  computed: {
    crumbs () {
      const fullPath = this.$route.fullPath
      const params = fullPath.substring(1).split('/').filter((e) => e)
      const crumbs = [{
        name: this.$i18n.t('index-page'),
        path: '/'
      }]
      let path = ''

      params.forEach((param) => {
        path = `${path}/${param}/`.replace('//', '/')
        const match = this.$router.match(path)

        if (match.name !== null) {
          crumbs.push({
            name: this.$i18n.t(this.$store.getters['categories/getRoutes'].find((e) => e.slug === param)?.name),
            path: match.path
          })
        }
      })
      return crumbs
    }
  }
}
