
import Meta from '../../plugins/json/crm-for-business/meta.json'

export default {
  name: 'CrmForBusinessSlug',
  data () {
    return {
      content: undefined,
      bg: undefined,
      customPages: [
        'crm-for-real-estate',
        'crm-for-metals-trading',
        'crm-system-for-sale',
        'crm-for-manufacture',
        'crm-for-construction',
        'crm-uslugi-b2b',
        'crm-optovye-prodazhi',
        'sistema-dlya-yuridicheskoy-kompanii',
        'crm-for-lawyers-and-law-firms',
        'korporativniy-portal',
        'crm-konsalting',
        'korporativniy-portal',
        'crm-for-accounting',
        'crm-clothing-industry'
      ],
      folder: ''
    }
  },
  components: {
    BreadCrumbs: () => import('../../components/elements/breadcrumbs.vue'),
    CrmForBusinessTemplateNew: () => import('../../components/pages/crm-for-business-template-new.vue'),
    CrmForBusinessTemplateUpdated: () => import('~/components/pages/crm-for-business/crm-for-business-template-updated.vue'),
    Prefooter: () => import('../../components/elements/pre-footer.vue'),
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue'),
    NotFoundPage: () => import('../404.vue')
  },
  async asyncData ({ params, error }) {
    const slug = params.slug
    let meta = Meta.pages.find((x) => x.slug === slug)
    if (!meta) {
      meta = {
        title: '',
        subtitle: '',
        description: ''
      }
      error({ statusCode: 404, message: `Запись ${params.slug} не найдена` })
    }
    return {
      meta,
      slug
    }
  },
  async created () {
    if (!this.meta.design2025 && (!this.meta.updated || this.isCustomPage)) {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/crm-for-business/' + this.slug + '.md')
        // eslint-disable-next-line no-return-assign
        .then((res) => this.content = res.default)
        .catch((e) => {
          throw new Error(`Запись ${this.slug} не найдена\n${e}`)
        })
    }
    const bgFormat = this.meta.bgFormat || 'jpg'
    this.bg = `pages/crm-for-business/slugs/${this.slug}/bg.${bgFormat}`
    this.folder = `pages/crm-for-business/slugs/${this.slug}/`
  },
  async beforeUpdate () {
    this.slug = this.$route.params.slug
    this.meta = Meta.pages.find((x) => x.slug === this.slug)
  },
  head () {
    let links = []
    let title = this.meta?.title
    let description = this.meta?.description
    if (typeof this.meta?.canonical !== 'undefined') {
      links = [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://megaplan.ru${this.meta.canonical}`
        }
      ]
    }
    if (this.meta && Object.prototype.hasOwnProperty.call(this.meta, 'customMeta')) {
      const customMeta = this.meta.customMeta
      if (customMeta && customMeta.title) {
        title = customMeta.title
      }
      if (customMeta && customMeta.description) {
        description = customMeta.description
      }
    }
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: index === 0 ? this.$t(`pages.${path}.title`) : this.meta.name,
        item: breadcrumbPath(index)
      })
    })
    return {
      title,
      meta: [
        { hid: 'description', name: 'description', content: description },
        { hid: 'og:title', name: 'og:title', content: this.meta.title },
        { hid: 'og:description', name: 'og:description', content: this.meta.description }
      ],
      link: links,
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.meta.name,
            description: this.meta.description,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  },
  computed: {
    isCustomPage () {
      return this.customPages.includes(this.slug)
    },
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate
      }
    }
  },
  beforeDestroy () {
    this.content = null
    this.meta = null
    this.bg = null
    this.folder = null
    this.slug = null
  },
  amp: 'hybrid',
  ampLayout: 'amp'
}

