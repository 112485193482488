
import { DButton } from 'site-lib'
import scrollToBlock from '~/mixins/helpers'

export default {
  name: 'ProductPage',
  components: {
    DButton,
    ProductFeatures: () => import('./product-features.vue'),
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue'),
    Benefits: () => import('~/components/elements/benefits.vue'),
    AdditionsBlock: () => import('~/components/blocks/additions-block.vue'),
    Companies: () => import('~/components/blocks/companies.vue'),
    ProductCalculator: () => import('./product-calculator.vue')
  },
  mixins: [scrollToBlock],
  props: {
    tariff: {
      type: String,
      required: true
    }
  }
}
