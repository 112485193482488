

export default {
  name: 'EmbeddedVideoBlock',
  props: {
    item: {
      type: Object,
      required: true
    },
    useVk: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VideoBlock: () => import('~/components/UI/video-block.vue')
  }
}
