
import { DButton } from 'site-lib'

export default {
  name: 'Partners',
  components: {
    DButton,
    Achievements: () => import('../components/blocks/achievements.vue'),
    Companies: () => import('../components/blocks/companies.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue'),
    Tabs: () => import('../components/elements/tabs.vue'),
    PartnersContent: () => import('../components/blocks/partners-tab-content.vue')
  },
  data: () => ({
    activeTab: 'dealer' // dealer | referer
  }),
  computed: {
    tabsList () {
      const tabItems = this.$t('pages.partners.types')
      const tabContent = []

      Object.keys(tabItems).map((item) => {
        const { title, tab } = tabItems[item]
        tabContent.push({ title, tab })
      })
      return tabContent
    }
  }
}
