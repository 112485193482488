
import { CIcon } from 'site-lib/index.ts'
import RegistrationLocal from '../../../mixins/register-local'

import mspData from './data.json'

export default {
  name: 'RosSoftHeader',
  components: {
    CIcon
  },
  mixins: [RegistrationLocal],
  computed: {
    programUrl () {
      return mspData.programLink
    },
    appRating () {
      return '4,7'
    },
    supportRating () {
      return '4,9'
    }
  }
}
