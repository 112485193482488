

export default {
  name: 'CrmPage',
  components: {
    Product: () => import('../components/pages/product/product.vue')
  },
  head () {
    return {
      title: this.$t('pages.crm-plus.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.crm-plus.meta.description')
        },
        { hid: 'og:title', name: 'og:title', content: this.$t('pages.crm-plus.meta.title') },
        { hid: 'og:description', name: 'og:description', content: this.$t('pages.crm-plus.meta.description') }
      ]
    }
  }
}

