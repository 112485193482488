
import { CIcon } from 'site-lib'

/**
   * Генерация табов
   * @items - принимает массив табов
   * @small - класс меняющий размер табов
   * @theme - темы
   */

export default {
  name: 'Tabs',
  components: {
    CIcon
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    theme: {
      type: String,
      required: false,
      default: ''
    },
    newDesign: {
      type: Boolean,
      required: false,
      default: false
    },
    dropDown: {
      type: Boolean,
      required: false,
      default: false
    },
    handleActiveTab: {
      type: Number,
      default: 0
    },
    forYandex: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTab: this.items[this.handleActiveTab]?.tab,
      tabTitle: this.items[this.handleActiveTab]?.title,
      tabLabel: '',
      showMenu: false
    }
  },
  created () {
    if (this.isExist(this.items.find((tab) => tab.hash === this.$route.hash))) {
      this.activeTab = this.items.find((tab) => tab.hash === this.$route.hash).tab
      this.$emit('tab', this.activeTab)
    }
  },
  watch: {
    activeTab: {
      immediate: true,
      handler (val) {
        if (this.isExist(this.items.find((tab) => tab.tab === val))) {
          this.tabTitle = this.items.find((tab) => tab.tab === val).title
          this.tabLabel = this.items.find((tab) => tab.tab === val).label
          if (this.showMenu) {
            this.showMenu = false
          }
        }
      }
    },
    handleActiveTab: {
      immediate: true,
      handler () {
        this.activeTab = this.items[this.handleActiveTab]?.tab
      }
    }
  },
  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu
    },
    setTab (tab) {
      this.activeTab = tab
      this.$emit('tab', tab)
      this.$nextTick(() => {
        this.showMenu = false
      })
    }
  }
}
