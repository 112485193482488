
import VueRecaptcha from 'vue-recaptcha'
import RegistrationLocal from '../../mixins/register-local'

export default {
  name: 'creating',
  layout: 'empty',
  mixins: [RegistrationLocal],
  components: {
    VueRecaptcha
  },
  data: () => ({
    showCaptcha: false
  }),
  computed: {
    siteKey () {
      return process.env.GCAPTCHA_CODE
    },
    statusCode () {
      return this?.result?.status?.code
    }
  },
  mounted () {
    this.registrationProcess()
    this.callTrackingStart()
  },
  methods: {
    verifyCaptcha (token) {
      this.registrationProcess(token)
    },
    expiredCaptcha () {
      this.$refs.recaptcha.reset()
    },
    progressBarFilling () {
      const bar = this.$refs.regProgressBar
      const label = this.$refs.regProgressLabel
      let width = 0
      const timerId = setInterval(() => {
        if (width >= 100) {
          clearInterval(timerId)
        } else {
          if (width <= 99) {
            width += 0.07
          }
          this.statusCode === 'ok' ? width = 100 : false
          bar.style.width = `${width}%`
          label.style.width = `${width}%`
          label.innerHTML = `${Math.floor(width)}%`
        }
      }, 20)
    }
  }
}
