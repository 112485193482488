
export default {
  name: 'NewsIndex',
  components: {
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue'),
    BlogContent: () => import('../../components/pages/blog/blog-content.vue')
  },
  async asyncData ({ app, params, error }) {
    const pages = await app.$api('blogPosts', 'list', {
      siteId: 1,
      limit: 6
    })
    if (typeof pages === 'undefined' || typeof pages.items === 'undefined') {
      return error({ statusCode: 500, message: 'Внутренняя ошибка' })
    }
    let searchFromPost = null
    if (typeof params.search !== 'undefined') {
      searchFromPost = await this.$api('blogPosts', 'search', {
        searchItem: params.search,
        limit: 9,
        siteId: this.siteId
      })
    }
    return {
      pages: pages.items, searchFromPost
    }
  },
  head () {
    return {
      title: this.$t('pages.news.meta.title'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('pages.news.meta.description') }
      ]
    }
  }
}
