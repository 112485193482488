
import { mapGetters } from 'vuex'
import YandexShare from '@cookieseater/vue-yandex-share'
import DateFormat from '../../../mixins/dateFormat'
import imgPopup from '../../../mixins/imgPopup'
import readingTime from '../../../mixins/helpers'

export default {
  name: 'BlogPostContent',
  components: {
    YandexShare,
    CackleComments: () => import('../../widgets/cackle-comments.vue'),
    FooterGlowWrapper: () => import('../../elements/footer-glow-wrapper.vue'),
    BlogSearchResult: () => import('./blog-search-result.vue'),
    SubscribeMini: () => import('./subscribe.vue'),
    SubscribeBlock: () => import('./subscribe.vue'),
    PopularItems: () => import('./blog-items.vue'),
    BlogRating: () => import('./blog-rating.vue'),
    BlogItems: () => import('./blog-items.vue'),
    BlogSearch: () => import('./blog-search.vue'),
    BlogListing: () => import('./blog-listing.vue'),
    BlogMenu: () => import('./blog-menu.vue'),
    BlogMarkPopup: () => import('../../popup/blog-mark.vue'),
    BlogBottomBanner: () => import('./blog-bottom-banner.vue'),
    BlogDynamic: () => import('./blog-dynamic.vue')
  },
  mixins: [DateFormat, imgPopup, readingTime],
  props: {
    siteId: {
      type: Number,
      required: true
    },
    post: {
      type: [Object, Array],
      required: true
    },
    items: {
      type: Array,
      required: false,
      default: () => ([])
    },
    populars: {
      type: Array,
      required: false,
      default: () => ([])
    },
    showNavMenu: {
      type: [String, Number],
      required: false,
      default: '0'
    },
    comments: {
      type: Array,
      required: false
    },
    showComments: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    services: ['vkontakte', 'odnoklassniki', 'twitter', 'telegram', 'whatsapp'],
    searchResults: null,
    subscribeBlockIsFixed: false,
    subscribeBlockTop: 0,
    subscribeBlockHide: 0,
    isOGImageExist: true,
    links: [],
    likes: 0,
    dislikes: 0
  }),
  computed: {
    ...mapGetters({
      getBanners: 'common/getBanners'
    }),
    dynamicHtml () {
      return `<div>${this.post.articleDetail}</div>`
    },
    showBannerButton () {
      if (this.isExist(this.getBanners)) {
        const result = this.getBanners.map(({ settings }) => {
          const { redirectUrl } = JSON.parse(settings)
          return redirectUrl
        })
        return result.find((x) => x === this.$route.path)
      }
      return false
    },
    bannerFormParams () {
      const bannerName = 'december_2020'
      const langComponent = `pages.components.banners.${bannerName}`
      return {
        emailTitle: this.$t(`${langComponent}.emailTitle`),
        emailTo: this.$t(`${langComponent}.email`),
        header: this.$t(`${langComponent}.header`),
        subtitle: this.$t(`${langComponent}.subtitle`),
        btnText: this.$t(`${langComponent}.btnText`),
        target: this.isExist(this.$t(`${langComponent}.target`)) ? this.$t(`${langComponent}.target`) : '',
        name: bannerName
      }
    },
    postUrl () {
      return `https://megaplan.ru/${this.siteId === 1 ? 'news' : 'blog'}/${this.post.articleCategorySlug}/${this.post.articleSlug}/`
    },
    pageTitle () {
      return this.siteId === 1 ? this.$t('pages.news.title') : this.$t('pages.blog.title')
    },
    isActiveComments () {
      return process.browser && Boolean(Number(this.post.isActiveComments))
    },
    backgroundOnCard () {
      return Boolean(Number(this.post.backgroundOnCard))
    },
    nextPost () {
      return {
        link: `/${this.siteId === 1 ? 'news' : 'blog'}/${this.post.articleCategorySlug}/${this.post.nextSlug}/`,
        title: this.post.nextTitle
      }
    },
    prevPost () {
      return {
        link: `/${this.siteId === 1 ? 'news' : 'blog'}/${this.post.articleCategorySlug}/${this.post.prevSlug}/`,
        title: this.post.prevTitle
      }
    },
    dateUpdated () {
      const availableDate = this.post.articleDateUpdate || this.post.articleDateCreate
      const date = new Date(availableDate)
      const formattedDate = this.longFormat(date)
      return `${this.post.articleDateUpdate ? 'Обновлено' : 'Опубликовано'} ${formattedDate}`
    }
  },
  async fetch () {
    const url = `${process.env.HOST_NAME}/upload/blog/${this.post.articleSlug}/${this.post?.articleBackground?.split('.')[0]}-og.jpg`
    const articleId = this.post.articleId
    const { status, data } = await this.$api('evaluationOfArticle', 'getRateArticle', { articleId })
    if (status === 'ok') {
      this.likes = Number(data.like)
      this.dislikes = Number(data.dislike)
    }
    try {
      const response = await fetch(url, { method: 'HEAD' })
      if (!response.ok) {
        this.isOGImageExist = false
      }
      return true
    } catch (error) {
      this.isOGImageExist = false
      return false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.buttonInit()
    })

    setTimeout(() => {
      this.generateLinks()
      this.observeLinks()
    }, 0)

    const subscribeBlock = document.getElementsByClassName('subscribeBlock')[0]
    let scrolledShow = 0
    if (this.isExist(subscribeBlock)) {
      scrolledShow = subscribeBlock.getBoundingClientRect().top
    }
    let scrolledHide
    const fbCommentBlock = document.getElementById('comments')
    if (this.isExist(fbCommentBlock)) {
      scrolledHide = fbCommentBlock.getBoundingClientRect().top
    }
    const fixHide = 96
    if (window.scrollY > 0) {
      scrolledShow += window.scrollY
      if (this.isExist(scrolledHide)) {
        scrolledHide += window.scrollY
      }
    }
    this.subscribeBlockTop = scrolledShow - fixHide
    if (this.isExist(scrolledHide)) {
      this.subscribeBlockHide = scrolledHide - fixHide
    }
    if (process.browser && this.post) {
      setTimeout(() => {
        this.$api('blogPosts', 'updateViews', { postId: this.post.articleId })
      })
    }
  },
  methods: {
    getBackgroundPath () {
      let result = `/upload/blog/${this.post.articleBackground}`
      if (this.post.articleBackgroundFolder) {
        result = `/upload/blog/${this.post.articleSlug}/${this.post.articleBackground}`
      }
      return result
    },
    handleScrollSubscribe (evt) {
      if (this.subscribeBlockTop < evt && !this.subscribeBlockIsFixed) {
        this.subscribeBlockIsFixed = true
      }

      if (this.subscribeBlockIsFixed && (this.subscribeBlockTop > evt || this.subscribeBlockHide < evt)) {
        this.subscribeBlockIsFixed = false
      }
    },
    buttonInit () {
      const buttons = document.getElementsByClassName('contentRegButton')
      const meta = this.$t(`pages.${this.$route.path}.form`)
      for (let i = 0; i < buttons.length; i += 1) {
        let popupType = 'reg-form'
        let buttonTarget = 'tryForFreeButton'
        if (buttons[i].classList.contains('custom')) {
          popupType = 'form'
          buttonTarget = buttons[i].getAttribute('data-target')
        }
        meta.target = buttonTarget
        buttons[i].addEventListener('click', () => {
          this.showPopup(popupType, meta)
        })
      }
    },
    generateLinks () {
      if (process.browser && this.showNavMenu !== '0') {
        const post = document.getElementsByClassName('blog-post-content')[0]
        const headings = post.querySelectorAll('h2, h3')
        for (let i = 0; i < headings.length; i += 1) {
          // eslint-disable-next-line no-bitwise
          const id = `f${(~~(Math.random() * 1e8)).toString(16)}`
          headings[i].setAttribute('id', id)
          if (headings[i].tagName === 'H2') {
            this.links.push({
              href: id,
              text: headings[i].innerText,
              sublink: false
            })
          } else if (headings[i].tagName === 'H3') {
            this.links.push({
              href: id,
              text: headings[i].innerText,
              sublink: true
            })
          }
        }
      }
    },
    observeLinks () {
      if (process.browser && this.showNavMenu !== '0') {
        const post = document.getElementsByClassName('blog-post-content')[0]
        const headings = post.querySelectorAll('h2, h3')
        const windowHeight = document.documentElement.clientHeight
        const activeClass = 'active'
        document.addEventListener('scroll', () => {
          headings.forEach((navItem, index) => {
            const coords = navItem.getBoundingClientRect()
            const topVisible = coords.top > 0 && ((coords.top - windowHeight) < -(windowHeight / 2))
            if (topVisible) {
              this.$refs.navLinks.forEach((link) => {
                if (link.classList.contains(activeClass)) {
                  link.classList.remove(activeClass)
                }
              })
              this.$refs.navLinks[index].classList.add(activeClass)
            }
          })
        })
      }
    }
  },
  head () {
    if (this.post) {
      const metaTitle = this.post.articleMetaTitle.replace(/&nbsp;/g, ' ')
      const metaDescription = this.post.articleMetaDescription.replace(/&nbsp;/g, ' ')
      const domain = 'https://megaplan.ru'
      const { fullPath } = this.$route
      const pathArray = fullPath.split('/').filter((path) => path)
      let ogImage = '/share.png'
      let ogVkImage = '/share.png'
      if (this.isOGImageExist && this.post.articleBackground) {
        if (this.post.articleBackgroundFolder) {
          ogImage = `/upload/blog/${this.post.articleSlug}/${this.post.articleBackground.split('.')[0]}-og.jpg`
          ogVkImage = `/upload/blog/${this.post.articleId}/${this.post.articleBackground.split('.')[0]}-vk.jpg`
        } else {
          ogImage = `/upload/blog/${this.post.articleBackground.split('.')[0]}-og.jpg`
          ogVkImage = `/upload/blog/${this.post.articleBackground.split('.')[0]}-vk.jpg`
        }
      }
      ogVkImage = process.env.HOST_NAME + ogVkImage
      ogImage = process.env.HOST_NAME + ogImage
      const schemaOrg = 'https://schema.org'
      const lgJson = 'application/ld+json'
      const breadcrumbName = (index, path) => {
        switch (index) {
        case 0:
          return this.$t(`pages.${path}.title`)
        case 1:
          return this.post.articleCategoryTitle
        case 2:
          return this.post.articleTitle
        default:
          return this.post.articleTitle
        }
      }
      const breadcrumbPath = (index) => {
        let path = domain
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= index; i++) {
          path += `/${pathArray[i]}`
        }
        return path
      }
      const breadcrumbsList = [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Главная',
          item: domain
        }
      ]
      pathArray.map((path, index) => {
        breadcrumbsList.push({
          '@type': 'ListItem',
          position: index + 2,
          name: breadcrumbName(index, path),
          item: breadcrumbPath(index)
        })
      })
      return {
        title: metaTitle,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: metaDescription
          },
          { hid: 'og:title', property: 'og:title', content: metaTitle },
          {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            hid: 'og:description',
            property: 'og:description',
            content: metaDescription
          },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: this.$t(this.siteId === 1 ? 'pages.news.meta.site-name' : 'pages.blog.meta.title')
          },
          { hid: 'og:type', property: 'og:type', content: 'article' },
          { hid: 'og:url', property: 'og:url', content: this.postUrl },
          { hid: 'twitter:title', property: 'twitter:title', content: metaTitle },
          {
            hid: 'twitter:description',
            property: 'twitter:description',
            content: metaDescription
          },
          { hid: 'og:image:width', property: 'og:image:width', content: '720' },
          { hid: 'og:image:height', property: 'og:image:height', content: '382' },
          {
            hid: 'og:image',
            property: 'og:image',
            content: `${ogImage}`
          },
          {
            hid: 'vk:image',
            property: 'vk:image',
            content: `${ogVkImage}`
          }
        ],
        script: [
          {
            type: lgJson,
            json: {
              '@context': schemaOrg,
              '@type': 'WebPage',
              url: this.postUrl,
              image: `${ogImage}`,
              name: this.post.articleTitle,
              description: metaDescription,
              breadcrumb: {
                '@type': 'BreadcrumbList',
                itemListElement: breadcrumbsList
              },
              publisher: {
                '@type': 'Organization',
                name: 'Megaplan',
                logo: {
                  '@type': 'ImageObject',
                  url: `${ogImage}`,
                  width: '200',
                  height: '200'
                }
              }
            }
          },
          {
            type: lgJson,
            json: {
              '@context': schemaOrg,
              '@type': 'Article',
              mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': this.postUrl
              },
              name: this.post.articleTitle,
              headline: this.post.articleTitle,
              description: this.post.articleMetaDescription,
              image: {
                '@type': 'ImageObject',
                url: `${ogImage}`,
                width: '200',
                height: '200'
              },
              datePublished: this.post.articleDateCreate,
              dateModified: this.post.articleDateUpdate,
              author: {
                '@type': 'Person',
                name: this.post.authorName
              },
              interactionStatistic: {
                '@type': 'InteractionCounter',
                userInteractionCount: this.post.articleViews
              },
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: this.likes,
                reviewCount: this.likes + this.dislikes
              },
              publisher: {
                '@type': 'Organization',
                name: 'Megaplan',
                logo: {
                  '@type': 'ImageObject',
                  url: `${ogImage}`,
                  width: '200',
                  height: '200'
                }
              }
            }
          },
          this.siteId === 1 ? {
            type: lgJson,
            json: {
              '@context': schemaOrg,
              '@type': 'NewsArticle',
              datePublished: this.post.articleDateCreate,
              dateModified: this.post.articleDateUpdate,
              headline: this.post.articleTitle,
              url: this.postUrl
            }
          } : {}
        ]
      }
    }
    return {
      title: this.$t('pages.blog.meta.common-title')
    }
  }
}
