
import { DButton } from 'site-lib/index.ts'

export default {
  name: 'ClientTryBlock',
  components: {
    DButton
  },
  methods: {
    auditClick () {
      const formParams = {
        header: this.$t('pages.vnedrenie-crm-sistemy.get-help'),
        formEmailName: 'trainingcenter',
        formEmailTitle: this.$t('pages.clients.audit.email-title')
      }
      const popupName = 'form'
      this.showRegPopup(popupName, formParams)
    }
  }
}
