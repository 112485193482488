
import { CModal, CInput, DButton } from 'site-lib/index.ts'

import VueRecaptcha from 'vue-recaptcha'
import RegistrationLocal from '~/mixins/register-local'

export default {
  name: 'RegistrationQuiz',
  data: () => ({
    showCaptcha: false,
    loading: false,
    isFullscreen: undefined,
    showSmsCodeField: false,
    latestPhone: ''
  }),
  components: {
    CModal,
    CInput,
    DButton,
    VueRecaptcha
  },
  mixins: [RegistrationLocal],
  computed: {
    siteKey () {
      return process.env.GCAPTCHA_CODE
    }
  },
  methods: {
    showDropdownMenu (menuRef) {
      this.$refs[menuRef].classList.add('opened')
    },
    hideDropdownMenu (menuRef) {
      this.$refs[menuRef].classList.remove('opened')
    },
    chooseVariant (model, variant) {
      this[model] = variant
    },
    verifyCaptcha (token) {
      this.registrationProcess(token)
    },
    expiredCaptcha () {
      this.$refs.recaptcha.reset()
    },
    async toCreatedAccountPage () {
      const quizResult = {
        Shortname: this.shortNameField,
        Surname: this.surnameField,
        Phone: this.phoneField,
        Company: this.companyField,
        Department: this.departmentField,
        Employees: this.employeesNumberField,
        Code: this.smsField
      }
      this.clearErrors()
      this.loading = true
      const quizResponse = await this.$api('account',
        'accountData',
        quizResult)
      if (quizResponse.status && quizResponse.status.code === 'ok') {
        this.quizFinished = true
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (this.employeesNumberField !== this.$t('regForm.employeesValuesList')[0]) {
          this.sendGoalEvent('sql-lead')
        }
        await this.registrationProcess()
      } else if (quizResponse.errors.length) {
        this.loading = false
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        quizResponse.errors.forEach((error) => {
          if (error.field && error.message) {
            this.setError(error.field, error.message)
          }
        })
      }
    },
    phoneCheckHandler () {
      if (this.phoneField && this.phoneField !== this.latestPhone) {
        this.latestPhone = this.phoneField
        this.checkPhoneNumber(this.phoneField)
      }
    },
    // eslint-disable-next-line
    async checkPhoneNumber (str) {
      const data = {
        Phone: str
      }
      const resStatus = await this.$api('account',
        'checkPhone',
        data)
      if (resStatus.status && resStatus.status.code === 'need_confirm') {
        this.showSmsCodeField = true
      } else if (resStatus.errors) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        resStatus.errors.forEach((error) => {
          if (error.message) {
            this.setError(this.phoneField, error.message)
          }
        })
      }
    }
  },
  beforeDestroy () {
    this.clearErrors()
  },
  mounted () {
    this.isFullscreen = !window.matchMedia('(min-width: 1000px)').matches
  }
}
