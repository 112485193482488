import { render, staticRenderFns } from "./vnedrenie-crm-sistemy.vue?vue&type=template&id=8bef8002&scoped=true&"
import script from "./vnedrenie-crm-sistemy.vue?vue&type=script&lang=js&"
export * from "./vnedrenie-crm-sistemy.vue?vue&type=script&lang=js&"
import style0 from "./vnedrenie-crm-sistemy.vue?vue&type=style&index=0&id=8bef8002&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bef8002",
  null
  
)

export default component.exports