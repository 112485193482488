export default {
  data () {
    return {
      sessionStorageKey: 'callTracking',
      contactPhone: '',
      contactPhoneRu: '',
      contactPhoneUa: '',
      contactPhoneKz: '',
      contactPhoneBy: '',
      optimizedPhone: '',
      sourceKey: '',
      callTrackingItems: []
    }
  },
  methods: {
    callTrackingStart () {
      if (process.client) {
        if (
          sessionStorage.getItem(this.sessionStorageKey)
          && sessionStorage.getItem(this.sessionStorageKey).items
        ) {
          this.callTrackingItems = []
          this.callTrackingItems = this.getCallTrackingFromStorage('items')
          this.startPhonesSets()
        } else {
          this.loadCallTrackingItemsFromApi()
        }
      }
    },
    /**
     * Загружаем информацию из api
     * @returns {Promise<void>}
     */
    async loadCallTrackingItemsFromApi () {
      let callTrackingItems = await this.$api('CallTrackingData', 'list')
      callTrackingItems = callTrackingItems.callTracking
      this.setCallTrackingItemsArr(callTrackingItems)
      this.startPhonesSets()
      this.setCallTrackingInfo()
    },
    /**
     * Определяем номера по логике
     * и записываем в переменные
     */
    startPhonesSets () {
      const locale = localStorage.getItem('locale')
      let callTrackingItems = this.callTrackingItems
      callTrackingItems = this.filterCallTrackingsByLocale(callTrackingItems, locale)
      const sourceKey = (this.getCallTrackingFromStorage('sourceKey')) ? this.getCallTrackingFromStorage('sourceKey') : this.checkCallTrackingSourceKey()
      this.sourceKey = sourceKey
      this.contactPhoneRu = this.getPhoneForLocale('ru')
      this.contactPhoneUa = this.getPhoneForLocale('ua')
      this.contactPhoneKz = this.getPhoneForLocale('kz')
      this.contactPhoneBy = this.getPhoneForLocale('by')
      const fromJsonPhone = this.$t('contacts.phone')
      if (!this.getCallTrackingFromStorage('phone')) {
        if (callTrackingItems) {
          this.setSuitablePhoneNumber(callTrackingItems, sourceKey)
        } else {
          this.setPhone(fromJsonPhone)
        }
      } else {
        this.setPhone(this.getCallTrackingFromStorage('phone'))
      }
      if (process.client && !sessionStorage.getItem(this.sessionStorageKey)) {
        this.setCallTrackingInfo()
      }
    },
    /**
     * Получаем данные колл-трекинга
     * из session storage по ключу
     * @param key
     * @returns {*}
     */
    getCallTrackingFromStorage (key) {
      let result
      if (sessionStorage.getItem(this.sessionStorageKey) && key) {
        result = JSON.parse(sessionStorage[this.sessionStorageKey])[key]
      } else {
        result = undefined
      }
      return result
    },
    /**
     * Записываем данные
     * session storage
     */
    setCallTrackingInfo () {
      const phone = this.contactPhone
      const sourceKey = this.sourceKey
      const items = this.callTrackingItems
      sessionStorage.setItem(this.sessionStorageKey, JSON.stringify({ phone, sourceKey, items }))
    },
    /**
     * Запускается при смене языковой зоны
     */
    StartCallTrackingAfterChangeLocale () {
      this.cleanPhoneInStorage()
      this.callTrackingStart()
    },
    /**
     * Определяем ключ номера телефона
     * @returns {string}
     */
    checkCallTrackingSourceKey () {
      const referrer = document.referrer
      let sourceKey
      let search = document.location.search
      let hash = document.location.hash

      if (search === '' && referrer !== '') {
        switch (referrer) {
        case 'https://yandex.ru/':
          sourceKey = 'yandexOrganic'
          break
        case 'https://www.google.com/':
          sourceKey = 'googleOrganic'
          break
        default:
        }
      } else if (search !== '' || hash !== '') {
        let urlParametrs
        if (search !== '') {
          search = search.slice(1)
          urlParametrs = search.split('&')
        } else if (hash !== '') {
          if (hash.indexOf('?') > -1) {
            hash = hash.split('?')[1]
            urlParametrs = hash.split('&')
          } else {
            urlParametrs = []
            urlParametrs.push(hash)
          }
        }
        const arrParamNames = []
        urlParametrs.forEach((item) => {
          const param = item.split('=')
          const paramName = param[0]
          const paramVal = param[1]
          arrParamNames.push(paramName)
          if (paramName === 'utm_campaign' && paramVal === 'brand') {
            sourceKey = 'yandexBrand'
          } else if (paramName === 'utm_campaign' && paramVal === 'megaplan') {
            sourceKey = 'yandexBrand'
          } else if (paramName === 'utm_campaign' && paramVal !== 'brand') {
            sourceKey = 'yandexAdv'
          } else if (paramName === 'utm_campaign' && paramVal !== 'megaplan') {
            sourceKey = 'yandexAdv'
          }
        })
        if (arrParamNames.indexOf('utm_campaign') === -1) {
          sourceKey = 'default'
        }
      } else {
        sourceKey = 'default'
      }
      return sourceKey
    },
    /**
     * Записываем подходящий номер телефона
     * @param items
     * @param sourceKey
     */
    setSuitablePhoneNumber (items, sourceKey) {
      const fromJsonPhone = this.$t('contacts.phone')
      let suitablePhoneNumber

      if (sourceKey === 'default') {
        items.forEach((item) => {
          if (item.default === '1') {
            suitablePhoneNumber = item.phone
          }
        })
        if (suitablePhoneNumber) {
          this.setPhone(suitablePhoneNumber)
        } else {
          this.setPhone(fromJsonPhone)
        }
      } else {
        items.forEach((item) => {
          if (item.source === sourceKey) {
            suitablePhoneNumber = item.phone
          }
        })
        if (suitablePhoneNumber) {
          this.setPhone(suitablePhoneNumber)
        } else {
          this.setPhone(fromJsonPhone)
        }
      }
    },
    /**
     * Записываем номер телефона
     * @param phone
     */
    setPhone (phone) {
      this.contactPhone = phone
      this.optimizePhone(phone)
    },
    /**
     * Чистим телефон от лишних символов
     * @param phone
     */
    optimizePhone (phone) {
      this.optimizedPhone = phone.replace(/[^\d+]/gim, '')
    },
    /**
     * Чистит телефон из session storage
     */
    cleanPhoneInStorage () {
      sessionStorage.setItem(this.sessionStorageKey, JSON.stringify({ phone: '', sourceKey: this.sourceKey }))
    },
    /**
     * Фильтруем номера по языковой зоне
     * @param callTrackingItems
     * @returns {{}}
     */
    filterCallTrackingsByLocale (callTrackingItems, locale) {
      const result = []
      if (callTrackingItems) {
        callTrackingItems.forEach((item) => {
          if (item.locale === locale) {
            result.push(item)
          }
        })
      }
      return result
    },
    /**
     * Записываем массив объектов колл-трекинга
     * @param callTrackingItems
     */
    setCallTrackingItemsArr (callTrackingItems) {
      this.callTrackingItems = []
      const items = []
      callTrackingItems.forEach((item) => {
        items.push(item)
      })
      this.callTrackingItems = items
    },
    /**
     * Возвращает телефон для заданной
     * языковой зоны
     * @param locale
     * @returns {*}
     */
    getPhoneForLocale (locale) {
      let result
      const items = this.filterCallTrackingsByLocale(this.callTrackingItems, locale)
      items.forEach((item) => {
        if (item.source === this.sourceKey) {
          result = item.phone
        }
      })
      if (!result) {
        items.forEach((item) => {
          if (item.source === 'nosource') {
            result = item.phone
          }
        })
      }
      return result
    }
  }
}
