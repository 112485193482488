
export default {
  name: 'Achievements',
  props: {
    mode: {
      // dark | light
      type: String,
      required: false,
      default: 'light'
    }
  }
}
