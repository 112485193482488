export default () => {
  const element = document.createElement('div')
  let status = false
  element.classList.add('adBanner')
  element.style.cssText = 'height: 1px; width: 1px; background-color: transparent'
  document.body.appendChild(element)
  document.querySelector('.adBanner').clientHeight === 0 ? status = true : status = false
  document.body.removeChild(element)
  return status
}
