
import ClientsMeta from '~/plugins/json/clients/meta.json'

export default {
  name: 'ImplementationPage',
  components: {
    Companies: () => import('~/components/blocks/companies.vue'),
    ImplementationBlock: () => import('~/components/blocks/implementation-block.vue'),
    ClientCard: () => import('~/components/pages/clients/client-card.vue'),
    BlogItemsList: () => import('~/components/pages/blog/blog-items-list.vue'),
    BlogCard: () => import('~/components/pages/blog/blog-list-card.vue'),
    DarkBackgroundWrapper: () => import('~/components/blocks/dark-bg-wrapper.vue'),
    Prefooter: () => import('~/components/elements/pre-footer.vue'),
    SliderMultipleItems: () => import('~/components/UI/slider-multiple-items.vue'),
    FaqBlock: () => import('~/components/blocks/faq-block.vue'),
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue'),
    StagesBlock: () => import('~/components/blocks/stages-block.vue')
  },
  data: () => ({
    isExpanded: false,
    clientsList: ['metall-stroy-groupp', 'likk'],
    clientsMeta: ClientsMeta.pages
  }),
  async asyncData ({ app, error }) {
    const slug = 'management'
    const blogList = [
      'kak-delegirovat-chtoby-ne-peredelyvat',
      '5-porokov-komandy-i-kak-s-nimi-borotsya',
      'principy-upravleniya-bez-prinuzhdeniya',
      'tvoya-komanda-ploho-rabotaet'
    ]
    let fileContent
    const blogPosts = []
    const limit = 50
    let attempt = 0
    try {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/vnedrenie/vnedrenie.md')
        .then((res) => { fileContent = res.default })
    } catch (e) {
      error({ statusCode: 404, message: `${e.message}` })
    }
    const category = await app.$api('blogCategories', 'get', {
      slug,
      siteId: 2
    })
    if (typeof category === 'undefined' || typeof category.id === 'undefined' || category.length === 0) {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text')} ${slug} ${app.i18n.t('pages.blog.record.text1')}` })
    }

    while (blogPosts.length < 4 && attempt < 10) {
      // eslint-disable-next-line no-await-in-loop
      const posts = await app.$api('blogPosts', 'list', {
        categoryId: category.id,
        siteId: 2,
        limit,
        page: attempt + 1,
        offset: limit * attempt
      })
      const findPosts = posts.items.filter((post) => blogList.includes(post.articleSlug))
      blogPosts.push(...findPosts)
      attempt += 1
    }
    return {
      posts: blogPosts,
      content: fileContent
    }
  },
  methods: {
    setFormParams (str, fullHeader = false) {
      return {
        header: fullHeader
          ? str
          : this.$t('pages.vnedrenie-crm-sistemy.order.basic',
            { text: this.$t(`pages.vnedrenie-crm-sistemy.order.${str}`) }),
        emailTo: this.$t('pages.vnedrenie-crm-sistemy.form.email'),
        formEmailTitle: this.$t(`pages.vnedrenie-crm-sistemy.form.emailTitleList.${str}`),
        subtitle: this.$t('pages.index.form.subtitle'),
        fields: ['email', 'shortname', 'phone'],
        target: 'ucSendForm',
        sendMore: false
      }
    },
    scrollToRouteHash () {
      const hashList = ['audit', 'inception', 'studying']
      const hashText = this.$route.hash.replace('#', '')
      hashList.includes(hashText) ? this.scrollToBlock(hashText) : undefined
    },
    expandArticle () {
      this.isExpanded = !this.isExpanded
    }
  },
  computed: {
    clients () {
      return this.clientsMeta.filter((client) => this.clientsList.includes(client.slug))
    },
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate
      }
    }
  },
  mounted () {
    if (this.$route.hash) {
      this.scrollToRouteHash()
    }
  },
  amp: 'hybrid',
  ampLayout: 'amp'
}

