import resultModifier from './resultModifier'

const prefix = 'blog-comments/'
export default (axios, apiUrl) => ({
  /**
   * Получение комментариев в блоге
   * @param siteId
   * @param postId
   * @param page
   * @returns {*}
   */
  get (siteId, postId, page = null) {
    const perPage = page || 0

    return axios.get(`${apiUrl + prefix}get?perPage=${perPage}&postId=${postId}&siteId=${siteId}`)
      .then((res) => resultModifier(res.data))
  },

  /**
   * Получения комментария по ID
   * @param commentId
   * @param type
   * @returns {*}
   * @todo fix!!!
   */
  getById (commentId, type = null) {
    return axios.get(`${apiUrl + prefix}get-by-id?commentId=${commentId}&type=${type}`)
      .then((res) => resultModifier(res.data))
  },

  /**
   * Обновление комментария
   * @param formData
   * @returns {*}
   */
  update (formData) {
    return axios.post('blog-comments/update', formData)
      .then((res) => resultModifier(res.data))
  },

  /**
   * Добавления комментария
   * @param formData
   * @returns {*}
   */
  add (formData) {
    return axios.post('blog-comments/add', formData)
      .then((res) => resultModifier(res.data))
  }
})
