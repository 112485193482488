/**
 * Параметры
 ---browser:
 Microsoft Edge
 IE 9
 IE 10
 IE 11
 IE <= 8
 Firefox
 Google Chrome
 Safari
 Opera
 Неизвестный браузер
 Не определено
 ---os:
 win7
 win8
 win9
 win10
 winvista
 winxp
 winnt
 mac
 linux
 nix
 ---mobile - включая планшеты:
 Не определено
 Windows Phone (Nokia Lumia)
 iOS (iPhone iPad)
 Android
 Blackberry
 Неизвестное мобильное устройство с браузером Safari
 Неизвестное мобильное устройство
 ---tablet:
 true
 false
 ---touch:
 true
 false
 @todo req!
 */

const undef = 'Не определено'

// eslint-disable-next-line sonarjs/cognitive-complexity
export default (req = null) => {
  const ua = process.server ? (req? req.headers['user-agent'] : '')  : navigator.userAgent
  const browser = /Edge\/\d+/.test(ua)
    ? 'Microsoft Edge' : /MSIE 9/.test(ua)
      ? 'IE 9' : /MSIE 10/.test(ua)
        ? 'IE 10' : /MSIE 11/.test(ua)
          ? 'IE 11' : /MSIE\s\d/.test(ua)
            // eslint-disable-next-line no-useless-escape
            ? 'IE <= 8' : /rv:11/.test(ua)
              ? 'Firefox' : /Firefox\W\d/.test(ua)
                ? 'Google Chrome' : /Chrom(e|ium)\W\d|CriOS\W\d/.test(ua)
                  ? 'Safari' : /\bSafari\W\d/.test(ua)
                    ? 'Opera' : /\bOpera\W\d/.test(ua)
                      ? 'Opera' : /\bopr\W\d/i.test(ua)
                        ? 'Неизвестный браузер' : typeof MSPointerEvent !== 'undefined'
                          ? undef : ''
  const os = /Windows NT 10/.test(ua)
    ? 'win10' : /Windows NT 6\.0/.test(ua)
      ? 'winvista' : /Windows NT 6\.1/.test(ua)
        ? 'win7' : /Windows NT 6\.\d/.test(ua)
          ? 'win8' : /Windows NT 5\.1/.test(ua)
            ? 'winxp' : /Windows NT [1-5]\./.test(ua)
              ? 'winnt' : /Mac/.test(ua)
                ? 'mac' : /Linux/.test(ua)
                  ? 'linux' : /X11/.test(ua)
                    ? 'nix' : ''
  const touch = 'ontouchstart' in document.documentElement
  const mobile = /iemobile|windows phone|lumia/i.test(ua)
    ? 'Windows Phone (Nokia Lumia)' : /iPhone|iP[ao]d/.test(ua)
      ? 'iOS (iPhone iPad)' : /Android/.test(ua)
        ? 'Android' : /BlackBerry|PlayBook|BB10/.test(ua)
          ? 'Blackberry' : /Mobile Safari/.test(ua)
            ? 'Неизвестное мобильное устройство с браузером Safari' : /webos|mobile|tablet|opera mini|\bcrmo\/|opera mobi/i.test(ua)
              ? 'Неизвестное мобильное устройство' : undef
  const tablet = /tablet|ipad/i.test(ua)
  // eslint-disable-next-line no-restricted-globals
  const deviceWidth = window.screen.availWidth ? window.screen.availWidth : screen.availWidth
  // eslint-disable-next-line no-restricted-globals
  const deviceHeight = window.screen.availHeight ? window.screen.availHeight : screen.availHeight

  // получаем ориентацию экрана
  const getOrientation = () => (window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait')

  // получаем ширину экрана
  const getDeviceWidth = () => (getOrientation() === 'landscape' && mobile !== undef ? deviceHeight : deviceWidth)

  // получаем высоту экрана
  const getDeviceHeight = () => (getOrientation() === 'landscape' && mobile !== undef ? deviceWidth : deviceHeight)

  let Os = touch || os === 'nix' ? mobile : os
  if (tablet) { Os += '(Tablet)' }
  const getDevice = () => {
    let device = 'desktop'
    if (touch || os === 'nix') {
      device = 'mobile'
    }
    if (tablet) {
      device = 'tablet'
    }
    return device
  }

  return {
    browser,
    os: Os,
    device: getDevice(),
    isTouch: touch,
    width: getDeviceWidth(),
    height: getDeviceHeight(),
    orientation: getOrientation()
  }
}
