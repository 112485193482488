
export default {
  name: 'blog-read-related',
  props: {
    article: {
      type: Object,
      default: () => ({})
    }
  }
}
