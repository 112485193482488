
import { CModal } from 'site-lib/index.ts'

export default {
  name: 'ArticlesSubscription',
  components: {
    CModal,
    FormElements: () => import('~/components/forms/new/articles-subscription-form.vue')
  },
  data: () => ({
    model: {},
    showPopupAfterSubmit: false
  }),
  computed: {
    formFields () {
      return [
        {
          name: 'email',
          required: true,
          validateOnKey: true,
          rule: 'email'
        }
      ]
    }
  },
  methods: {
    /**
     * Проверяем поля формы
     * на пустоту
     */
    checkEmptyModelField () {
      let result = true
      Object.keys(this.model).forEach((key) => {
        if (this.model[key].length > 0) {
          result = false
        }
      })
      return result
    },
    /**
     * Проверяем поля формы
     * на полную заполненность
     */
    checkNoEmptyAllFields () {
      const fieldNames = []
      let result = true
      this.formFields.forEach((item) => {
        const nameField = item.name
        fieldNames.push(nameField)
      })

      fieldNames.forEach((key) => {
        if (!this.model[key]) {
          result = false
        }
        if (this.model[key] && this.model[key].length === 0) {
          result = false
        }
      })
      return result
    }
  },
  beforeDestroy () {
    if (this.bannerId > 0 && this.showPopupAfterSubmit === false) {
      if (this.checkEmptyModelField() === true) {
        this.statBannerPlus('closing_without_filling_in', this.bannerId)
      } else if (this.checkNoEmptyAllFields() === true) {
        this.statBannerPlus('closing_with_filled', this.bannerId)
      }
    }
  }
}
