
import { mapGetters } from 'vuex'
import { gsap, ScrollTrigger } from '~/plugins/utils/gsap'

export default {
  name: 'SplitScrollingBlock',
  props: {
    content: {
      type: Object,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      menuHeight: 'common/getMenuHeight'
    }),
    computedMenuHeight () {
      return this.menuHeight || 70
    }
  },
  mounted () {
    const mm = gsap.matchMedia()
    const photos = this.$refs.rightImages?.slice(1)
    photos !== undefined ? gsap.set(photos, { xPercent: 101 }) : null
    mm.add('(min-width: 768px)', () => {
      if (this.dark) {
        gsap.to('.split.desktop.dark', {
          scrollTrigger: {
            trigger: '.split.desktop.dark',
            start: 'top top',
            scrub: true,
            end: 'bottom bottom'
          },
          '--translate': '-150%'
        })
      }

      if (photos) {
        this.$refs.leftSection.forEach((detail, index) => {
          const headline = detail.children[0]
          const animation = gsap.timeline()
            .to(photos[index], { xPercent: 0 })
            .set(this.$refs.rightImages[index], { autoAlpha: 0 })
          ScrollTrigger.create({
            trigger: headline,
            start: '50% 85%',
            end: '50% 50%',
            animation,
            scrub: true,
            markers: false
          })
        })
      }
    })
  },
  beforeDestroy () {
    ScrollTrigger.killAll()
  }
}
