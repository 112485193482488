
export default {
  name: 'RowMenu',
  data () {
    return {
      linksClass: '',
      setLinksFixed: false,
      scrollHeight: 400
    }
  },
  computed: {
    menu () {
      return [
        {
          title: this.$t('menu.links.news'),
          href: '/news/'
        },
        {
          title: this.$t('menu.links.news-category.events'),
          href: '/news/events/'
        },
        // {
        //   title: this.$t('menu.links.vacancy'),
        //   href: '/company/#vacancy'
        // },
        {
          title: this.$t('menu.links.partners'),
          href: '/partners/'
        },
        {
          title: this.$t('menu.links.history'),
          href: '/company/'
        },
        {
          title: this.$t('menu.links.contacts'),
          href: '/contacts/'
        }
      ]
    }
  },
  methods: {
    handleScroll (evt) {
      if (window.innerWidth > 1199) {
        if (!this.setLinksFixed && evt > this.scrollHeight) {
          this.setLinksFixed = true
          this.linksClass = 'stickyed'
        }
        if (this.linksClass !== '' && evt < 350) {
          this.linksClass = ''
        }
        if (this.setLinksFixed && evt < this.scrollHeight) {
          this.setLinksFixed = false
          this.linksClass = 'hide'
        }
      }
    }
  }
}
