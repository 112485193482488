
import Vue, { PropType } from 'vue'
import { CIcon } from '../CIcon'

type Sizes = 'normal' | 'big' | 'full'
type Types = 'white' | 'transparent'

export default Vue.extend({
  name: 'CModal',
  components: {
    CIcon
  },
  props: {
    withoutPadding: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String as PropType<Types>,
      required: false,
      default: 'white'
    },
    size: {
      type: String as PropType<Sizes>,
      required: false,
      default: 'normal'
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  },
  computed: {
    sizeClass (): string {
      return `c-modal-size-${this.size}`
    },
    typeClass (): string {
      return `c-modal-type-${this.type}`
    },
    paddingClass (): string {
      return this.withoutPadding ? '' : 'c-modal-padding'
    }
  }
})
