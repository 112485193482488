import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/interview/`

function TypeformGetById (params) {
  return request(API_URL, `typeform-get-by-id?formSlug=${params.formSlug}`, null, 'get')
}

function AnketaGetById (params) {
  return request(API_URL, `anketolog-get-by-id?formSlug=${params.formSlug}`, null, 'get')
}

function getLast () {
  return request(API_URL, 'get-last', null, 'get')
}

export {
  AnketaGetById,
  TypeformGetById,
  getLast
}
