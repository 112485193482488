function getCookie (name) {
  const matches = typeof document !== 'undefined' ? document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([$()*+./?[\\\]^{|}])/g, '\\$1')}=([^;]*)`
  )) : null
  return matches ? decodeURIComponent(matches[1]) : null
}

function setCookie (name, value, options = { 'max-age': 2592000 }) {
  options = {
    path: '/',
    domain: window.location.hostname,
    ...options
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  Object.keys(options).map((optionKey) => {
    updatedCookie += `; ${optionKey}`
    const optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`
    }
  })
  document.cookie = updatedCookie
}

function deleteCookie (name) {
  setCookie(name, '', { 'max-age': -1 })
}

export {
  getCookie,
  setCookie,
  deleteCookie
}
