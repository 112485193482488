export default {
  setSelectedProduct (state, product) {
    state.selectedProduct = product
  },
  setProductTarget (state, target) {
    state.productTarget = target
  },
  setSocialUser (state, u) {
    state.socialUser = u
  },
  setComment (state, comment) {
    state.comment = comment
  }
}
