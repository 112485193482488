
export default {
  name: 'QuotesSlider',
  props: {
    title: {
      type: String,
      required: false
    },
    slides: {
      type: Array,
      required: true
    }
  },
  methods: {
    pauseSwiper () {
      this.mySwiper.autoplay.stop()
    },
    startSwiper () {
      this.mySwiper.autoplay.start()
    }
  },
  data () {
    return {
      quotesSwiper: {
        slidesPerView: 1,
        loop: true,
        effect: 'slide',
        speed: 300,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        watchSlidesVisibility: true,
        navigation: {
          nextEl: '.quotes__slider-nav--next',
          prevEl: '.quotes__slider-nav--prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  }
}
