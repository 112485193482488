
import Vue, { PropType } from 'vue'
import { IconNames } from './iconMetadata'

type Sizes = 'xsmall' | 'small' | 'normal' | 'big'
type Colors = 'black' | 'grey' | 'white' | 'green' | 'blue-sky' | 'sea-wave' | 'orange' | 'coral' | 'blue' | 'purple' | 'pink'

export default Vue.extend({
  name: 'CIcon',
  props: {
    iconName: {
      type: String as PropType<keyof typeof IconNames>,
      required: false,
    },
    size: {
      type: [String, Number] as PropType<Sizes | number>,
      required: false,
      default: 'normal' as Sizes
    },
    color: {
      type: String as PropType<Colors>,
      required: false,
      default: 'black' as Colors
    },
    className: {
      type: String,
      required: false
    }
  },
  computed: {
    content (): string {
      if (this.iconName) {
        return String.fromCharCode(IconNames[this.iconName])
      }
      return ''
    },
    customSize (): string | null {
      if (typeof this.size === 'number') {
        return `${this.size}px`
      }
      return null
    },
    sizeClass (): string {
      return !this.customSize ? `icon-size-${this.size}` : ''
    },
    colorClass (): string {
      return `icon-color-${this.color}` || ''
    },
    styleObject () {
      const style: Partial<CSSStyleDeclaration> = {}
      if (this.customSize) {
        style.width = this.customSize
        style.height = this.customSize
        style.fontSize = this.customSize
      }
      return style
    }
  },
  methods: {
    handleClick (e: PointerEvent) {
      this.$emit('click', e)
    }
  }
})
