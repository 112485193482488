

export default {
  name: 'LearnCenterPage',
  async asyncData ({ error }) {
    let fileContent
    try {
      // eslint-disable-next-line prefer-template
      await import('~/assets/md/vnedrenie/vnedrenie.md')
        .then((res) => { fileContent = res.default })
    } catch (e) {
      error({ statusCode: 404, message: `${e.message}` })
    }
    return {
      content: fileContent
    }
  },
  computed: {
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate
      }
    },
    canonicalUrl () {
      return process.env.HOST_NAME + this.$route.fullPath.replace(/\/amp\//, '/')
    }
  },
  components: {
    RegButtonAmp: () => import('../../components/forms/reg-button-amp.vue'),
    AmpBottomBanner: () => import('../../components/pages/blog/amp-bottom-banner.vue')
  },
  head () {
    return {
      title: this.$t('pages.vnedrenie-crm-sistemy.meta.title'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('pages.vnedrenie-crm-sistemy.meta.description') }
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.canonicalUrl
        }
      ]
    }
  }
}
