import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/consultation-request/`

const DEFAULT_PARAMS = {
  comment: '',
  event: '',
  mailTo: 'price',
  mailSubject: '',
  mailContent: '',
  host: ''
}

function send (params) {
  return request(API_URL, 'send', { ...DEFAULT_PARAMS, ...params })
}

export {
  // eslint-disable-next-line import/prefer-default-export
  send
}
