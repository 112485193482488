
import { mapGetters } from 'vuex'
import pricesJson from '~/plugins/json/pricesNew.json'
import PricesHelpers from '~/mixins/prices'
import Helpers from '~/mixins/helpers'

export default {
  name: 'ProductCalculator',
  mixins: [PricesHelpers, Helpers],
  components: {
    Swch: () => import('~/components/elements/swch.vue'),
    Tabs: () => import('~/components/elements/tabs.vue')
  },
  data () {
    return {
      prices: pricesJson.data,
      actualCountTab: 1,
      actualPeriodTab: 1,
      refreshCountKey: 1,
      refreshPeriodKey: 1,
      observer: null,
      matchMediaSwitcher: false
    }
  },
  props: {
    tariff: {
      type: String,
      required: true
    }
  },
  computed: {
    tariffCode () {
      if (this.tariff === 'crm-biznes-process') {
        return 'crmbusiness'
      }
      if (this.tariff === 'collaboration-business') {
        return 'collaborationplusbusiness'
      }
      return this.tariff.replaceAll('-', '')
    },
    ...mapGetters({
      type: 'priceCalculator/getType',
      count: 'priceCalculator/getCount',
      period: 'priceCalculator/getPeriod'
    }),
    countTabs () {
      return this.$t(`pages.components.prices.count-product.${this.tariffCode}.${this.type}`)
    },
    periodTabs () {
      const localesString = `pages.components.prices.period-product.${this.tariffCode}.${this.type}`
      if (this.type === 'cloud') {
        switch (this.count) {
        case '5': {
          return this.$t(`${localesString}.lessFive`)
        }
        case '100': {
          return this.$t(`${localesString}.lessHundred`)
        }
        case 'unlimited': {
          return this.$t(`${localesString}.unlimited`)
        }
        default: return []
        }
      }
      return this.$t('pages.components.prices.period-product.box')
    },
    disableSwitch () {
      return this.tariffCode === 'crmlite'
    },
    price () {
      let tariffInfo
      if (this.type === 'cloud' && this.count === '5') {
        tariffInfo = this.prices[this.type].team
          .find((el) => el.code === this.tariffCode)[this.activeLanguage].prices
          .find((e) => e.period === this.period && e.month === this.count)
        if (this.period === '12') {
          const pricePerMonth = Math.ceil(tariffInfo.price / 12)
          return pricePerMonth.toLocaleString().replace(/,/g, ' ')
        }
        return this.getSpecialPrice(tariffInfo, 'price')
      }
      if (this.type === 'cloud' && this.count === 'unlimited') {
        tariffInfo = this.prices[this.type].special
          .find((el) => el.code === this.tariffCode)[this.activeLanguage].prices
          .find((e) => e.period === this.period)
        return this.getSpecialPrice(tariffInfo, 'price')
      }
      if (this.type === 'box' && this.count !== 'unlimited') {
        tariffInfo = this.prices[this.type]
          .find((el) => el.code === this.tariffCode)
        return this.getSpecialPrice(tariffInfo, 'price')
      }
      if (this.type === 'box' && this.count === 'unlimited') {
        tariffInfo = this.prices[this.type]
          .find((el) => el.code === this.tariffCode)[this.activeLanguage].prices
          .find((e) => e.month === this.count)
        return this.getSpecialPrice(tariffInfo, 'price')
      }
      tariffInfo = this.tariffContent.tariffList.cloud.find((el) => el.code === this.tariffCode)
      return this.getPriceNew(tariffInfo.price, this.type, 'price', +this.period)
    }
  },
  mounted () {
    if (this.countTabs[this.actualCountTab].disable) {
      this.setCount(this.findActualTab(this.countTabs, 'count'))
    }
    if (this.periodTabs[this.actualPeriodTab].disable) {
      this.setPeriod(this.findActualTab(this.periodTabs, 'period'))
    }
    this.observer = new ResizeObserver(([{ target }]) => {
      this.matchMediaSwitcher = target.getBoundingClientRect().width < 650
    })
    this.observer.observe(document.querySelector('.calculator'))
  },
  methods: {
    setCount (count) {
      if (this.count !== count) {
        this.$store.commit('priceCalculator/setCount', count)
        this.setPeriod(this.findActualTab(this.periodTabs, 'period'))
      }
    },
    setPeriod (period) {
      if (this.period !== period) {
        this.$store.commit('priceCalculator/setPeriod', period)
      }
    },
    setType (type) {
      const boxOrCloud = type ? 'box' : 'cloud'
      if (this.type !== boxOrCloud) {
        this.$store.commit('priceCalculator/setType', boxOrCloud)
        this.$emit('change-tab', this.type)
        const hasCountTab = this.countTabs.find((e) => e.tab === this.count)
        const hasPeriodTab = this.periodTabs.find((e) => e.tab === this.period)
        if (!hasCountTab || hasCountTab.disable) {
          this.setCount(this.findActualTab(this.countTabs, 'count'))
        }
        if (!hasPeriodTab || hasPeriodTab.disable) {
          this.setPeriod(this.findActualTab(this.periodTabs, 'period'))
        }
      }
    },
    findActualTab (tabsArray, ref) {
      const refName = ref[0].toUpperCase() + ref.slice(1).toLowerCase()
      const dataName = `actual${refName}Tab`
      const tabIndex = tabsArray.findIndex((e) => !e.disable)
      if (tabIndex >= 0 && this[dataName] !== tabIndex) {
        this[dataName] = tabIndex
        return tabsArray[tabIndex].tab
      }
      if (tabIndex >= 0 && this[dataName] === tabIndex && this.count !== tabsArray[tabIndex].tab) {
        const refKey = `refresh${refName}Key`
        this[refKey] += 1
      }
      return tabsArray[this[dataName]].tab
    },
    getNoteText () {
      const discountText = this.$t(`pages.components.product.calculator.noteDiscount.${this.period}`)
      if (this.type === 'cloud' && this.count === '5') {
        return `${this.$t('pages.components.product.calculator.noteText.licenceTeam')}${discountText}`
      }
      if (this.count === 'unlimited') {
        return `${this.$t('pages.components.product.calculator.noteText.licenseUserCount', { number: '300' })}${discountText}`
      }
      if (this.type === 'box' && this.count !== 'unlimited') {
        return this.$t('pages.components.product.calculator.noteText.licenseUserCount', { number: this.count })
      }
      return `${this.$t('pages.components.product.calculator.noteText.licenceOne')}${discountText}`
    }
  },
  beforeDestroy () {
    this.$store.commit('priceCalculator/setType', 'cloud')
    this.$store.commit('priceCalculator/setCount', '100')
    this.$store.commit('priceCalculator/setPeriod', '12')
    if (this.observer) {
      this.observer.unobserve(document.querySelector('.calculator'))
      this.observer = null
    }
  }
}
