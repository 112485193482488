
import { CModal } from 'site-lib/index.ts'

import UserDevice from '~/plugins/utils/userDevice'

export default {
  name: 'video',
  components: { CModal },
  props: {
    videoId: {
      type: String,
      required: false,
      default: 'JfrLOg4IFrM'
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isAutoplayed () {
      const deviceType = UserDevice().os.split(' ').shift()
      return this.autoplay || deviceType === 'iOS' || deviceType === 'Android'
    },
    isVkVideo () {
      const regex = /\d{3,}(_)\d{2,}/
      return regex.test(this.videoId)
    }
  },
  methods: {
    close () {
      this.closePopup('video')
    }
  }
}
