
import Vue, { PropType } from 'vue'
import { COnboardingInput } from '../COnboardingInput'

export default Vue.extend({
  name: 'COnboardingInputPassword',
  components: { COnboardingInput },
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  data (): { type: "text" | "password" } {
    return {
      type: "password"
    }
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String as PropType<string | null>,
      required: false,
      default: null
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  methods: {
    changeType () {
      this.type = this.type === "password" ? "text" : "password"
    }
  },
  computed: {
    actions (): any {
      return [{ handler: () => this.changeType(), iconName: this.type === "password" ? 'EYE' : 'EYE_OFF' }]
    }
  }
})
