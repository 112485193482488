
import { CIcon } from 'site-lib'
import { timeline } from '~/plugins/json/timeline/timeline.json'

export default {
  name: 'CompanyTimeline',
  components: {
    CIcon
  },
  data () {
    return {
      timelineArray: timeline,
      itemsLength: timeline.length - 1,
      currentItemIndex: 1,
      trackPosition: 0,
      trackEnd: 0,
      trackWidth: 0,
      sliderBodyWidth: 0
    }
  },
  mounted () {
    const interval = setInterval(() => {
      if (this.$refs.track) {
        clearInterval(interval)
        this.initData()
      }
    }, 50)
    this.$nextTick(() => {
      window.addEventListener('resize', this.initData)
    })
  },
  computed: {
    currentItem () {
      return this.timelineArray[this.currentItemIndex]
    }
  },
  watch: {
    currentItemIndex () {
      this.checkVisibility()
    }
  },
  methods: {
    initData () {
      this.sliderBodyWidth = this.$refs.track.clientWidth
      this.trackWidth = this.$refs.track.scrollWidth
      this.trackEnd = -(this.trackWidth - this.sliderBodyWidth)
      this.checkVisibility()
    },
    checkVisibility () {
      const position = this.$refs[`item${this.currentItemIndex}`][0].offsetLeft
      const itemWidth = this.$refs[`item${this.currentItemIndex}`][0].offsetWidth
      const prevItemStart = position - itemWidth
      const nextItemEnd = position + itemWidth
      const isItemsWithin = () => {
        const moveTrackToZero = [0, 1, 2]
        if (moveTrackToZero.includes(this.currentItemIndex)) {
          return true
        }
        return prevItemStart >= 0 && nextItemEnd <= this.sliderBodyWidth
      }
      if (isItemsWithin()) {
        this.trackPosition = 0
      } else if (nextItemEnd >= this.sliderBodyWidth) {
        this.trackPosition = -(nextItemEnd - this.sliderBodyWidth)
      }
      this.$refs.track.style.transform = `translateX(${this.trackPosition}px)`
    },
    buttonClick (step) {
      if (step === 'next') {
        this.currentItemIndex = (this.currentItemIndex + 1) > this.itemsLength ? 0 : this.currentItemIndex + 1
      } else {
        this.currentItemIndex = (this.currentItemIndex - 1) < 0 ? this.itemsLength : this.currentItemIndex - 1
      }
    },
    setCurrentItem (index) {
      this.currentItemIndex = index
    }
  }
}
