
export default {
  name: 'NewsSlug',
  components: {
    FooterGlowWrapper: () => import('../../../components/elements/footer-glow-wrapper.vue'),
    BlogContent: () => import('../../../components/pages/blog/blog-content.vue')
  },
  async asyncData ({ app, params, error }) {
    let categoryTryings = 0
    let category
    while ((typeof category === 'undefined' || typeof category.id === 'undefined' || category.length === 0) && categoryTryings < 5) {
      // eslint-disable-next-line no-await-in-loop
      category = await app.$api('blogCategories', 'get', {
        slug: params.category,
        siteId: 1
      })
      categoryTryings += 1
    }
    if (typeof category === 'undefined' || typeof category.id === 'undefined' || category.length === 0) {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text')} ${params.category} ${app.i18n.t('pages.blog.record.text1')}` })
    }
    const posts = await app.$api('blogPosts', 'list', {
      categoryId: category.id,
      siteId: 1,
      limit: 6
    })

    if (typeof posts === 'undefined' || typeof posts.items === 'undefined') {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text')} ${params.category} ${app.i18n.t('pages.blog.record.text1')}` })
    }
    return {
      title: category.title,
      categoryId: category.id,
      pages: posts.items,
      meta: {
        title: category.title + app.i18n.t('pages.news.meta.common-title'),
        description: app.i18n.t(`pages.news.categories.${category.slug}.meta.description`)
      },
      categoryTitle: app.i18n.t(`pages.news.categories.${category.slug}.h1`)
    }
  },
  data: () => ({
    searchResults: null
  }),
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: index === 0 ? this.$t(`pages.${path}.title`) : this.title,
        item: breadcrumbPath(index)
      })
    })
    return {
      title: this.meta.title,
      meta: [
        { hid: 'description', name: 'description', content: this.meta.description },
        { hid: 'og:title', name: 'og:title', content: this.meta.title },
        { hid: 'og:description', name: 'og:description', content: this.meta.description }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.title,
            description: this.$t('pages.news.meta.description'),
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  }
}
