import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/banners/`

function list () {
  return request(API_URL, 'list', null, 'get')
}

function StatPlus (params) {
  return request(API_URL, 'stat-plus', params, 'post')
}

export {
  // eslint-disable-next-line import/prefer-default-export
  StatPlus,
  list
}
