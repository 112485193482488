import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/redirect/`

function updateViews (params) {
  return request(API_URL, 'update-views', params, 'patch')
}

export {
  // eslint-disable-next-line import/prefer-default-export
  updateViews
}
