
export default {
  name: 'Companies',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    typeFor: {
      type: String,
      required: false,
      default: 'company'
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalTitle: this.title !== '' ? this.title : this.$t('pages.components.companies.title'),
      links: {}
    }
  },
  computed: {
    companiesCount () {
      if (this.typeFor === 'spaces') {
        return 7
      }
      if (this.typeFor === 'partners') {
        return 6
      }
      return 5
    }
  }
}
