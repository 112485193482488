
import userDevice from '~/plugins/utils/userDevice'

export default {
  name: 'blog-importsubst-banner',
  props: {
    text: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    isMobile () {
      return process.browser && userDevice().device !== 'desktop'
    }
  }
}
