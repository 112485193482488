
export default {
  name: 'FooterLinks',
  props: {
    hideContacts: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      menuLinks: [
        {
          title: this.$t('menu.links.possibilities'),
          subMenu: [
            {
              link: '/facilities/',
              title: this.$t('menu.links.facilities')
            },
            {
              link: '/mobile/',
              title: this.$t('menu.links.mobile')
            },
            {
              link: '/crm-for-business/',
              title: this.$t('menu.links.crm-for-business-examples')
            },
            {
              link: '/clients/',
              title: this.$t('menu.links.clients-cases')
            },
            {
              link: '/api-integration/',
              title: this.$t('menu.links.api-integration')
            },
            {
              link: '/security/',
              title: this.$t('menu.links.security')
            },
            {
              link: 'https://dev.megaplan.ru/',
              title: this.$t('menu.links.dev')
            },
            {
              link: '/partners/',
              title: this.$t('menu.links.partners-program')
            },
            {
              link: '/crm-capabilities/',
              title: this.$t('menu.links.crm-capabilities'),
              hideNofollow: true
            }
          ]
        },
        {
          title: this.$t('menu.links.tariffs'),
          subMenu: [
            {
              link: '/calculation/',
              title: this.$t('menu.links.calculation')
            },
            {
              link: '/crm-for-clients/',
              title: this.$t('menu.links.crm')
            },
            {
              link: '/collaboration/',
              title: this.$t('menu.links.collaboration')
            },
            {
              link: '/compare/',
              title: this.$t('menu.links.compare-short')
            }
          ]
        },
        {
          title: this.$t('menu.links.solutions'),
          subMenu: [
            {
              link: '/crm-for-business/crm-for-manufacture/',
              title: this.$t('menu.links.crm-for-manufacture')
            },
            {
              link: '/crm-for-business/service/',
              title: this.$t('menu.links.crm-for-service')
            },
            {
              link: '/crm-for-business/crm-for-construction/',
              title: this.$t('menu.links.crm-for-construction')
            },
            {
              link: '/crm-for-business/crm-system-for-sale/',
              title: this.$t('menu.links.crm-for-sale'),
              hideNofollow: true
            }
          ]
        },
        {
          title: this.$t('menu.links.articles'),
          subMenu: [
            {
              link: '/news/articles/chto-takoe-crm-sistema/',
              title: this.$t('menu.links.chto-takoe-crm-sistema'),
              hideNofollow: true
            },
            {
              link: '/news/articles/kak-vybrat-crm-sistemu/',
              title: this.$t('menu.links.kak-vybrat-crm-sistemu')
            },
            {
              link: '/news/articles/onlayn-planirovshchik-zadach/',
              title: this.$t('menu.links.onlayn-planirovshchik-zadach')
            }
          ]
        },
        {
          title: this.$t('menu.links.support'),
          subMenu: [
            {
              link: 'https://help.megaplan.ru/',
              title: this.$t('menu.links.help')
            },
            {
              link: '/help-training/#training',
              title: this.$t('menu.links.training')
            }
          ]
        }
      ],
      lang: ''
    }
  },
  mounted () {
    this.lang = this.activeLanguage
    this.callTrackingStart()
  },
  methods: {
    isAvailable (item) {
      return this.isExist(item.lang)
        ? item.lang.includes(this.lang)
        : true
    },
    getClass (item) {
      return this.isExist(item.class)
        ? item.class
        : ''
    }
  }
}

