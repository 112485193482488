import consola from 'consola'

let ActivePopup = null
const MAIN_LAYOUT_CONTENT = '.layout__content'

export default {
  computed: {
    activeLanguage () {
      return this.$i18n.locale
    },
    route () {
      return this.$nuxt.$route.name
    },
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
    onlyForRussia () {
      return this.activeLanguage === 'ru'
    },
    getActivePopup () {
      return ActivePopup
    },
    printCopyright () {
      return `Мегаплан © 2007 - ${new Date().getFullYear()}`
    },
    isGiftPage () {
      return this.$route.name === 'gift'
    },
    getLastDayOfMonth () {
      const now = new Date()
      const options = {
        month: 'long',
        day: 'numeric',
        timezone: 'UTC'
      }
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
      return lastDay.toLocaleString('ru', options)
    }
  },
  methods: {
    isExist (value) {
      return typeof value !== 'undefined' && value !== null
    },
    /**
     * Возвращает рандомный элемент массива
     * @param arr
     */
    getRandElArray (arr) {
      const rand = Math.floor(Math.random() * arr.length)
      return arr[rand]
    },
    /**
     * Показываем всплывашку (обработчик в layouts/default)
     * @param popupItem
     * @param params
     */
    showPopup (popupItem, params = {}) {
      ActivePopup = popupItem
      const body = document.querySelector('body')
      body.style.overflow = 'hidden'
      this.$root.$emit('popupGenerator', popupItem, true, params)
    },
    /**
     * Закрываем всплывашку
     * @param popupItem
     */
    closePopup (popupItem) {
      ActivePopup = null
      const body = document.querySelector('body')
      body.style.overflow = ''
      this.$root.$emit('popupGenerator', popupItem, false)
    },
    /**
     * Используется для components/elements/tabs
     * Передает активный таб
     * @param item
     */
    changeTab (item) {
      const tabs = document.querySelectorAll('.tab-item')
      tabs.forEach((el) => {
        el.classList.remove('active')
      })
      this.activeTab = item
    },
    /**
     * Выбираем блок до которого нужно произвести плавный скролл.
     * Для нескольких прокруток внутри одной страницы (например в блоге) использовать парам inner
     * @param elem
     * @param offset
     * @param inner прокрутка внутри страницы
     */
    scrollToBlock (elem, offset = -100, inner = false) {
      const block = document.getElementById(elem)
      if (inner) {
        block.scrollIntoView({ behavior: 'smooth' })
      } else {
        const scrollableContainer = document.querySelector(MAIN_LAYOUT_CONTENT)
        const y = block.getBoundingClientRect().top + offset
        scrollableContainer.scrollTo({ top: y, behavior: 'smooth' })
      }
    },
    /**
     * Обнуляет прокрутку основного блока с контентом если она была
     */
    resetPageScroll () {
      const scrollableContainer = document.querySelector(MAIN_LAYOUT_CONTENT)
      if (scrollableContainer.scrollTop !== 0) {
        scrollableContainer.scrollTop = 0
      }
    },
    console (msg, line = null) {
      if (!this.isProduction) {
        const debugMsg = line !== null
          ? `debug: ${this.$route.fullPath}, line: ${line}`
          : `debug: ${this.$route.fullPath}`
        return consola.warn(debugMsg, msg)
      }
      return false
    },
    /**
     * Показываем попап с регистрацией в конкретный продукт
     * @param item
     * @param formParams
     */
    showProductPopup (item = this.getLicense, formParams = {}) {
      this.setProduct(item)
      this.showPopup('reg-form', formParams)
    },
    /**
     * Показываем попап с регистрацией (с отправлением событий)
     * @param popupName
     * @param formParams
     * @param target
     */
    showRegPopup (popupName = 'reg-form', formParams = { formEmailTitle: '' }, target = 'tryForFreeButton') {
      const params = { subtitle: this.$t('regForm.subtitle'), header: this.$i18n.nl2br('regForm.title'), ...formParams }
      this.sendGoalEvent(target, { page: this.$nuxt.$route.fullPath })
      this.showPopup(popupName, params)
    },
    /**
     * Сохраняем выбор продукта
     * @param item
     */
    setProduct (item) {
      this.$store.commit('account/setSelectedProduct', item)
    },
    /**
     * Записываем в стор цель
     * @param target
     */
    setTarget (target) {
      this.$store.commit('account/setProductTarget', target)
    },
    /**
     * заменяем перенос строки в JS
     * @param string
     * @returns {*}
     */
    nl2br (string) {
      if (this.isExist(string)) {
        return string.split('\n').join('<br />')
      }
      return string
    },
    /**
     * Проверяет, включена ли капча для форм в настройках
     */
    async checkSettingGoogleRecaptchaForForm () {
      const result = await this.$api('formRequest', 'checkCaptchaSettings')
      this.showCaptchaForm = result
    },
    /**
     * Комплексная замена строки с обработкой спецсимволов
     * @param mainString
     * @param subString
     * @param replaceString
     * @returns {*}
     */
    complexInsertinon (mainString, subString, replaceString) {
      return this.nl2br(mainString.replace(subString, replaceString))
    },
    checkPhone (str) {
      return str.length >= 10
    },
    checkEmail (str = this.email) {
      return /^[\dA-Za-z]+(?:[+._-][\dA-Za-z]+)*@[\dA-Za-z]+(?:[.-][\dA-Za-z]+)*\.[A-Za-z]{2,}$/.test(String(str))
    },
    setBodyOverflow (value = '') {
      document.getElementsByTagName('body')[0].style.overflow = value
    },
    shareTargetInit (name) {
      this.sendGoalEvent(`click${name.charAt(0).toUpperCase() + name.slice(1)}`)
    },
    generateUrlQuery (arr) {
      const esc = encodeURIComponent
      return Object.keys(arr)
        .map((k) => `${esc(k)}=${esc(arr[k])}`)
        .join('&')
    },
    setLocale (locale) {
      this.$i18n.locale = locale
      this.$store.commit('locale/set', locale)
      if (localStorage) {
        localStorage.setItem('locale', locale)
      }
    },
    setLocaleHash () {
      this.$router.push({ hash: `lang=${this.activeLanguage}` })
    },
    readingTime (post = 'toto') {
      const postLength = post.length
      const imagesAmount = (post.match(/<img/g) || []).length
      let result = Math.round((postLength / 1850 + imagesAmount * 0.25) * 2) / 2
      result = result < 1 ? 1 : result
      const resultModule = result % 10

      if (result === 1 || (result > 20 && resultModule === 1)) {
        return `${result} ${this.$t('pages.blog.reading-time1')}`
      }
      if ((result > 1 && result < 5) || (result > 20 && resultModule > 1 && resultModule < 5)) {
        return `${result} ${this.$t('pages.blog.reading-time2')}`
      }
      if ((result >= 5 && result <= 20) || (result > 20 && (resultModule >= 5 || resultModule === 0))) {
        return `${result} ${this.$t('pages.blog.reading-time5')}`
      }

      return result
    }
  },
  head () {
    const globalHeadOffParams = [
      'spaces',
      'spaces-old',
      'megaplan-plus_old',
      'collaboration',
      'crm-for-clients',
      'crm-for-business-slug',
      'news-articles-slug',
      'news-category',
      'news-category-post',
      'video-megaplan-category',
      'video-megaplan-category-post',
      'blog-category',
      'blog-category-post',
      'blog-crm',
      'blog-crm-post',
      'blog-author-slug',
      'news-articles',
      'clients-page',
      'updates-page',
      'crm-for-business-crm-for-construction',
      'crm-for-business-crm-for-manufacture',
      'event-sklad2022',
      'msp_old',
      'test',
      'test-id',
      'login-social',
      'amp-news-articles-slug',
      'amp-calculation',
      'amp-crm-for-business-slug',
      'amp-vnedrenie-crm-sistemy',
      'amp-blog-category-post',
      'crm-capabilities-slug',
      'crm-for-clients-ym',
      'collaboration-ym',
      'collaboration-business-ym',
      'crm-biznes-process-ym',
      'free-closed'
    ]
    if (globalHeadOffParams.includes(this.route)) { return {} }
    return {
      title: this.$nuxt.$i18n.nl2br(`pages.${this.route}.meta.title`),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$nuxt.$i18n.nl2br(`pages.${this.route}.meta.description`)
        }
      ]
    }
  }

}
