

export default {
  name: 'VkVideo',
  props: {
    vkVideoId: {
      type: String,
      default: ''
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    videoSrc () {
      if (this.vkVideoId) {
        const [oid, id] = this.vkVideoId.split('_')
        return `https://vk.com/video_ext.php?oid=-${oid}&id=${id}&hd=2${this.autoplay ? '&autoplay=1' : ''}`
      }
      return ''
    }
  }
}
