
import Paper from './paper.vue'

export default {
  name: 'Hint',
  components: { Paper },
  data: () => ({
    open: false
  }),
  methods: {
    toggleOpen () {
      this.open = !this.open
    }
  }
}
