
import { CButton } from 'site-lib/index.ts'

export default {
  name: 'RosSoftWhoHelped',
  components: {
    CButton
  },
  computed: {
    allCasesLink () {
      return 'https://megaplan.ru/clients/'
    },
    genesisLink () {
      return 'https://megaplan.ru/clients/genesis/'
    }
  }
}
