
export default {
  name: 'PrintImage',
  props: {
    imageUrl: {
      type: String
    },
    alt: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: 'd-block mx-auto'
    },
    withOutClass: {
      type: Boolean,
      default: false
    },
    useAssets: {
      type: Boolean,
      default: false
    },
    useStatic: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    calculatedClass () {
      return this.withOutClass ? '' : this.className
    }
  }
}
