import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/content/`

function getBySystemName (name) {
  return request(API_URL, 'get-by-system-name', name)
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getBySystemName
}
