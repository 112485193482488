
import DateFormat from '../../../mixins/dateFormat'

export default {
  mixins: [DateFormat],
  name: 'AccountSelectForm',
  components: {
    Form: () => import('./components/form.vue'),
    Button: () => import('./components/button.vue')
  },
  props: {
    accounts: {
      default: []
    }
  },
  computed: {
    sortedAccounts () {
      return [...this.accounts].sort((a, b) => (a.lastActivity < b.lastActivity ? 1 : -1))
    }
  },
  methods: {
    async deleteAccountInfo (account) {
      // eslint-disable-next-line vue/no-mutating-props
      this.accounts = this.accounts.filter((filterAccount) => filterAccount.name !== account.name)
      await this.$api('account', 'removeAccountLink', { name: account.name })
    },
    tryLogin (accountUrl) {
      const query = this.$route.query
      if (query?.return) {
        document.location.href = `${accountUrl}/sso/userecho?return=${query.return}`
        return
      }
      document.location.href = `${accountUrl}`
    }
  }
}
