import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/call-tracking/`

function list () {
  return request(API_URL, 'list', null, 'get')
}

export {
  // eslint-disable-next-line import/prefer-default-export
  list
}
