
import Vue from 'vue'
import RegistrationLocal from '../mixins/register-local'
import AbTest from '../mixins/abtest'
import Helpers from '../mixins/helpers'
import scrollBehavior from '../app/router.scrollBehavior'
import analytics from '../mixins/analytics'
import domainList from '../plugins/json/domains.json'
import callTracking from '../mixins/callTracking'
import bannersStatistics from '../mixins/bannersStatistics'
import Breadcrumbs from '../mixins/breadcrumbs'

// Подключаем миксин глобально
Vue.mixin(Helpers)
Vue.mixin(analytics)
Vue.mixin(callTracking)
Vue.mixin(bannersStatistics)

Vue.component('VkShareButton', () => import('../components/UI/buttons/vk-share-button.vue'))
Vue.component('VideoBlock', () => import('../components/UI/video-block.vue'))
Vue.component('YoutubeVideo', () => import('../components/widgets/youtube-video.vue'))
Vue.component('VkVideo', () => import('../components/widgets/vk-video.vue'))
Vue.component('RegButton', () => import('../components/forms/reg-button.vue'))
Vue.component('DButton', () => import('../components/site-lib/src/components/DButton/DButton.vue'))
Vue.component('CIcon', () => import('../components/site-lib/src/components/CIcon/CIcon.vue'))
Vue.component('PrintImage', () => import('../components/elements/print-image.vue'))
Vue.component('blog-banner', () => import('../components/pages/blog/blog-banner.vue'))
Vue.component('blog-read-related', () => import('../components/pages/blog/blog-read-related.vue'))
Vue.component('blog-quote', () => import('../components/pages/blog/blog-quote.vue'))
Vue.component('blog-custom-form', () => import('../components/pages/blog/blog-custom-form.vue'))
Vue.component('blog-importsubst-banner', () => import('../components/pages/blog/blog-importsubst-banner.vue'))

export default {
  components: {
    Banners: () => import('../components/elements/banners.vue'),
    MenuContainer: () => import('../components/elements/menu-container-new.vue')
  },
  mixins: [RegistrationLocal, AbTest, Breadcrumbs],
  scrollToTop: true,
  data: () => ({
    loadPopupObject: '',
    showPopupObject: false,
    popupParams: {},
    indexCanonical: ['gift', 'megaplanstart'],
    menuObserver: null
  }),
  computed: {
    loadPopupGeneratorComponent () {
      if (process.env.NEW_MODAL === 'on') {
        return () => import('~/components/popup/new/c-modal-container.vue')
      }
      return () => import('~/components/popup/popup-generator.vue')
    }
  },
  watch: {
    $route () {
      this.resetPageScroll()
    }
  },
  created () {
    this.$root.$on('popupGenerator', (popupName, show, params = {}) => {
      this.loadPopupObject = popupName
      this.showPopupObject = show
      this.popupParams = params
    })
    this.saveRoutes()
  },
  async fetch () {
    await this.$store.dispatch('categories/getRouteList')
  },
  async beforeMount () {
    /** При открытии сайта нужно запомнить урл перехода, даже если на этой странице не открывали форму регистрации */
    this.fillMetaData()
    this.checkRouteHash()
    this.loadLocale()
    this.sendPageView().then()
    this.callTrackingStart()
  },
  mounted () {
    const interval = setInterval(() => {
      if (this.$refs.menu) {
        clearInterval(interval)
        this.setMenuObserver()
      }
    }, 50)
    this.needAbTest(process.env.MENU, 'menu')
  },
  beforeDestroy () {
    this.menuObserver.unobserve(this.$refs.menu.$el)
    this.menuObserver = null
    this.$root.$off(['popupGenerator'])
  },
  methods: {
    loadLocale () {
      let lang = /^#lang=(by|ua|kz|ru)$/.exec(this.$nuxt.$route.hash)

      if (lang && (lang.length > 1)) {
        lang = lang[1].toLowerCase()
        return this.setLocale(lang)
      }

      const locale = localStorage.getItem('locale')
      if (this.getLocalesList().includes(locale)) {
        return this.setLocale(locale)
      }
      return this.setLocale('ru')
    },
    getLocalesList () {
      return domainList.languages.map((el) => el.code)
    },
    checkRouteHash () {
      if (this.$route.hash && typeof document.getElementById(this.$route.hash.slice(1)) !== 'undefined' && document.getElementById(this.$route.hash.slice(1)) != null) {
        this.$nextTick(() => {
          window.scrollTo(0, scrollBehavior(this.$route).y)
        })
      }
    },
    scrollEvent (evt) {
      this.$refs.menu.handleScroll(evt)
    },
    setMenuObserver () {
      this.menuObserver = new ResizeObserver(([{ target }]) => {
        this.$store.commit('common/setMenuHeight', target.offsetHeight)
      })
      this.menuObserver.observe(this.$refs.menu.$el)
    }
  },
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const domainRu = 'https://megaplan.ru'
    let canonicalHref
    let { fullPath, name } = this.$route
    const route = fullPath.split('/')
    const pathArray = fullPath.split('/').filter((path) => path)
    let htmlLang = 'ru-RU'
    if (this.indexCanonical.includes(route[1])) {
      fullPath = '/'
    }

    canonicalHref = domainRu + fullPath
    if (fullPath.lastIndexOf('/') !== fullPath.length - 1) {
      canonicalHref += '/'
    }
    if (domainUpperLevel === 'ua') {
      htmlLang = 'uk-UA'
    } else if (domainUpperLevel === 'by') {
      htmlLang = 'ru-BY'
    } else if (domainUpperLevel === 'kz') {
      htmlLang = 'ru-KZ'
    }

    const links = [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: canonicalHref
      }
    ]

    // eslint-disable-next-line func-names
    const ogMarkup = function () {
      let baseOGMarkup = [
        { hid: 'og:locale', name: 'og:locale', content: `${htmlLang}` },
        { hid: 'og:image', name: 'og:image', content: `${domain}/share.png` },
        { hid: 'og:image:secure_url', name: 'og:image:secure_url', content: `${domain}/share.png` },
        { hid: 'og:type', name: 'og:type', content: 'website' },
        { hid: 'og:site_name', name: 'og:site_name', content: 'Megaplan' },
        { hid: 'og:url', name: 'og:url', content: `${canonicalHref}` }
      ]
      if (!Object.keys(this.$route.params).length || this.$route.name === 'index') {
        baseOGMarkup = [
          ...baseOGMarkup,
          { hid: 'og:title', name: 'og:title', content: this.$t(`pages.${route[1] || 'index'}.meta.title`) },
          { hid: 'og:description', name: 'og:description', content: this.$t(`pages.${route[1] || 'index'}.meta.description`) }
        ]
      }
      return baseOGMarkup
    }
    const webPageData = (key) => {
      if (name === 'collaboration') {
        name = 'collaborationplus'
      }
      if (name === 'crm-for-clients') {
        name = 'crm-plus'
      }
      const keys = Object.keys(this.$t(`pages.${name}`))
      if (keys.includes(key)) {
        return this.$t(`pages.${name}.${key}`)
      }
      if (!keys.includes('meta')) {
        return this.$t(`pages.${route[1]}.${key}`)
      }
      return this.$t(`pages.${name}.meta.${key}`)
    }
    const breadcrumbList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: `${domain}/`
      }
    ]
    if (name !== 'index') {
      breadcrumbList.push({
        '@type': 'ListItem',
        position: 2,
        name: webPageData('title'),
        item: canonicalHref
      })
    }
    const webPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      url: canonicalHref,
      image: `${domain}/share.png`,
      name: webPageData('title'),
      description: webPageData('description'),
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbList
      },
      publisher: {
        '@type': 'Organization',
        name: 'Megaplan',
        logo: {
          '@type': 'ImageObject',
          url: `${domain}/share.png`,
          width: '200',
          height: '200'
        }
      }
    }
    return {
      htmlAttrs: {
        lang: htmlLang
      },
      meta: [
        ...ogMarkup.call(this),
        { name: 'yandex-verification', content: '51957b5ca74431a6' },
        { name: 'yandex-verification', content: '0521ed1973b5e7b5' },
        { hid: 'description', name: 'description', content: this.$t('pages.index.meta.description') }
      ],
      link: links,
      script: pathArray.length < 2 ? [{ type: 'application/ld+json', json: webPageSchema }] : []
    }
  }
}
