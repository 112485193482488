
import { DButton } from 'site-lib'
import { gsap } from '~/plugins/utils/gsap'
import TabsJson from '~/plugins/json/spaces/spaces.json'

export default {
  name: 'SpacesBlock',
  components: {
    DButton,
    Tabs: () => import('../elements/tabs.vue')
  },
  data () {
    return {
      tabsItems: TabsJson.tabs,
      activeTab: null,
      industryImages: TabsJson.tabs[0],
      baseEase: 'power2.inOut',
      observer: null
    }
  },
  methods: {
    setTab (tab) {
      this.industryImages = this.tabsItems.find((item) => item.tab === tab)
      this.activeTab = tab
    },
    setWrapperHeight (height) {
      if (height > 100) {
        this.$refs.spacesIndustryWrapper.style.height = `${height}px`
      }
    },
    setObserver () {
      this.observer = new ResizeObserver(([{ target }]) => {
        this.setWrapperHeight(target.getBoundingClientRect().height)
      })
      this.observer.observe(this.$refs.spacesIndustryWrapper.children[0])
    },
    removeObserver () {
      if (this.observer) {
        this.observer.unobserve(this.$refs.spacesIndustryWrapper.children[0])
        this.observer = null
      }
    },
    regAction () {
      this.showPopup('reg-form')
    }
  },
  mounted () {
    this.$refs.spacesIndustryWrapper.style.height = `${this.$refs.spacesIndustryImages.offsetHeight}px`
    this.setObserver()
    if (!window.matchMedia('(max-width: 1023px)').matches) {
      gsap.from(this.$refs.industryTitle, {
        scrollTrigger: {
          trigger: '.spaces__container',
          start: 'top 70%'
        },
        duration: 0.6,
        scale: 0.2,
        ease: this.baseEase,
        xPercent: -100,
        opacity: 0
      })
      const tabs = document.querySelectorAll('.spaces__industry-tags .tab-item')
      gsap.timeline({
        scrollTrigger: {
          trigger: '.spaces__container',
          start: 'top 70%'
        }
      }).from(tabs, {
        lazy: true,
        duration: 0.2,
        ease: this.baseEase,
        x: -100,
        y: -50,
        scale: 0.2,
        opacity: 0,
        delay: 0.4,
        stagger: 0.1
      })
    }
  }
}

