
import PricesHelpers from '~/mixins/prices'
import tables from '~/plugins/json/calculation/tables.json'

export default {
  name: 'ProductFeatures',
  mixins: [PricesHelpers],
  data () {
    return {
      features: tables.pages.components.compareTableNew.features,
      openedItem: undefined
    }
  },
  props: {
    tariff: {
      type: String,
      required: true
    }
  },
  computed: {
    tariffCode () {
      if (this.tariff === 'crm-biznes-process') {
        return 'crmbusiness'
      }
      if (this.tariff === 'collaboration-business') {
        return 'collaborationplusbusiness'
      }
      return this.tariff.replaceAll('-', '')
    }
  },
  methods: {
    groupFeature (group) {
      const featuresArray = [...new Set(this.features.filter((e) => e.group === group).flatMap((e) => e.tariffs))]
      return featuresArray.includes(this.tariffCode)
    },
    showFeaturesList (e) {
      this.hideFeaturesList()
      e.target.classList.add('open')
      this.openedItem = e.target
    },
    hideFeaturesList () {
      if (this.openedItem) {
        this.openedItem.classList.remove('open')
      }
    }
  },
  beforeDestroy () {
    this.hideFeaturesList()
    this.openedItem = undefined
  }
}
