export default {
  data: () => ({
    errorList: []
  }),
  methods: {
    /**
     * Устанавливает и отображает ошибку
     * @param field
     * @param msg
     */
    setError (field, msg) {
      if (!this.isExist(this.errorList.find((x) => x.field === field))) {
        this.errorList.push({ field, error: true })
      }
      const elems = document.querySelectorAll(`[data-field="${field}"]`);
      [].forEach.call(elems, (el) => {
        if (el.classList.contains('errorList') && this.isExist(msg)) { el.innerHTML = msg }
        el.classList.add('errors')
      })
    },
    /**
     * Прячем ошибку
     * @param field
     */
    clearError (field) {
      this.errorList = this.errorList.filter((el) => el.field !== field)
      const elems = document.querySelectorAll(`[data-field="${field}"]`);
      [].forEach.call(elems, (el) => {
        el.classList.remove('errors')
      })
    },
    /**
     * Очищаем все ошибки
     */
    clearErrors () {
      this.errorList.forEach((error) => {
        this.clearError(error.field)
      })
    }
  }
}
