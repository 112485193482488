
import RegistrationLocal from '../../mixins/register-local'

export default {
  name: 'CrmForBusinessTemplateNew',
  components: {
    CIcon: () => import('../site-lib/src/components/CIcon/CIcon.vue'),
    BreadCrumbs: () => import('../elements/breadcrumbs.vue'),
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue'),
    Companies: () => import('~/components/blocks/companies.vue'),
    TagsSlider: () => import('~/components/UI/tags-slider.vue'),
    QuoteBlock: () => import('../blocks/quote-block.vue'),
    FaqBlock: () => import('../blocks/faq-block.vue'),
    EmbeddedVideoBlock: () => import('../blocks/embedded-video-block.vue'),
    CrmCapabilitiesDescriptionBlock: () => import('~/components/UI/crm-capabilities-description-block.vue')
  },
  mixins: [RegistrationLocal],
  props: {
    meta: {
      type: Object,
      required: true
    },
    folder: {
      type: String,
      required: true,
      default: ''
    },
    headerImage: {
      type: String,
      required: false,
      default: ''
    },
    productCode: {
      type: String,
      required: false,
      default: 'crmbusiness'
    },
    demoHost: {
      type: String,
      required: false,
      default: 'crmbusiness.megaplan.ru'
    },
    content: {
      type: String,
      required: false
    }
  },
  data: () => ({
    // убрать когда все страницы переведем
    newProblemsList: [
      'crm-for-real-estate',
      'crm-for-metals-trading',
      'crm-for-manufacture',
      'crm-for-construction',
      'crm-for-service-center',
      'crm-optovye-prodazhi',
      'crm-system-for-sale',
      'crm-uslugi-b2b',
      'crm-for-lawyers-and-law-firms',
      'korporativniy-portal',
      'crm-konsalting',
      'crm-for-accounting',
      'crm-clothing-industry'
    ]
  }),
  computed: {
    renderedMd () {
      let mdTemplate = '<div />'
      if (this.content) {
        mdTemplate = this.$md.render(this.content)
      }
      return {
        name: 'MDComponent',
        template: mdTemplate
      }
    }
  },
  methods: {
    contacUsClick () {
      this.showPopup('form', {
        header: this.$t('regForm.contactSales'),
        formEmailName: 'default',
        formEmailTitle: `${this.$t('regForm.requestFrom')} "${document.title}"`
      })
    }
  },
  mounted () {
    this.setProduct(this.productCode)
  },
  beforeDestroy () {
    this.setProduct('')
  }
}
