
export default {
  data: () => ({
    accounts: []
  }),
  layout: 'login',
  components: {
    AuthorizationForm: () => import('../../components/pages/login/authorization-form.vue'),
    AccountSelectForm: () => import('../../components/pages/login/account-select-form.vue')
  },
  computed: {
    authFormHeaderText () {
      return this.accounts.length
        ? this.$t('pages.login.or-auth')
        : this.$t('pages.login.auth')
    }
  },
  async beforeMount () {
    const accounts = await this.$api('account', 'accountLinks')
    this.accounts = JSON.parse(accounts)
  }
}
