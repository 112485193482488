
import { DButton } from 'site-lib'

export default {
  name: 'VideoCategoryBlock',
  components: {
    DButton
  },
  props: {
    categoryId: {
      type: Number
    },
    categorySlug: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    outputType: {
      type: Number,
      required: true,
      default: 1
    },
    customItems: {
      type: Array
    },
    // url на кнопку "показать ещё"
    customUrl: {
      type: String
    },
    buttonText: {
      type: String
    }
  },
  data () {
    return {
      items: this.customItems ? this.customItems : null
    }
  },
  async fetch () {
    if (!this.customItems) {
      const videos = await this.$api('blogPosts', 'list', {
        categoryId: this.categoryId,
        siteId: 3,
        limit: this.limit
      })
      this.items = videos.items
    }
  },
  methods: {
    getUrl (item) {
      return item.siteId === 2
        ? `/blog/${item.categorySlug}/${item.articleSlug}/`
        : `/video-megaplan/${item.categorySlug}/${item.articleSlug}/`
    }
  },
  computed: {
    limit () {
      switch (this.outputType) {
      case 1:
        return 2
      case 2:
        return 4
      case 3:
        return 3
      case 4:
        return 3
      default:
        return 0
      }
    }
  }
}
