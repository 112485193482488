

export default {
  name: 'VideoCases',
  components: {
    PlayButton: () => import('./play-button.vue')
  },
  data () {
    return {
      cases: [{
        imageUrl: '/upload/pages/index/video-bg-vector.png',
        imageUrlMobile: '/upload/pages/index/video-bg-vector.png',
        videoId: '3784463_456239320',
        title: 'Директор производства об использовании Мегаплана',
        description: 'Директор компании «Вектор» Губин Артем рассказывает о выборе, внедрении и работе с CRM Мегаплан.'
      },
      {
        imageUrl: '/upload/pages/index/video-bg-ctd-mos-small.png',
        imageUrlMobile: '/upload/pages/index/video-bg-ctd-mos-small.png',
        videoId: '3784463_456239328',
        title: 'Малый производственный бизнес рассказывает о Мегаплане',
        description: 'Директора малого российского производства Павел Жендаров и Станислав Карпенко из ООО «ЦТБ Мос» рассказывают о выборе и использовании CRM Мегаплан.'
      }]
    }
  },
  props: {
    classNames: {
      type: String,
      default: '',
      required: false
    }
  }
}
