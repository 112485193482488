import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/evaluation-of-article/`

function addRateArticle (params) {
  return request(API_URL, 'add-rate-article', params)
}

function getRateArticle (params) {
  return request(API_URL, `get-rate-article?articleId=${params.articleId}`, null, 'get')
}

export {
  // eslint-disable-next-line import/prefer-default-export
  addRateArticle,
  getRateArticle
}
