
export default {
  name: 'MCRadioButton',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: false,
      default: undefined
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isChecked () {
      return this.modelValue === this.value
    }
  }
}
