
import { CIcon } from 'site-lib/index.ts'

export default {
  name: 'BigSliderCard',
  components: {
    CIcon
  },
  props: {
    image: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: ''
    }
  }
}
