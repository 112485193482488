
export default {
  name: 'Footer',
  components: {
    FooterLinks: () => import('./footer-menu.vue')
  },
  props: {
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    showDocSubMenu: {
      res: false,
      nonres: false
    }
  }),
  methods: {
    showDocs (subMenuKey) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(this.showDocSubMenu)) {
        if (key === subMenuKey) {
          this.showDocSubMenu[subMenuKey] = !this.showDocSubMenu[subMenuKey]
        } else {
          this.showDocSubMenu[key] = false
        }
      }
    }
  }
}

