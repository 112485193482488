import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/quotations-authors/`

function list () {
  return request(API_URL, 'list', null, 'get')
}

function getAuthor (params) {
  return request(API_URL, `get-author?authorId=${params.authorId}`, null, 'get')
}

export {
  // eslint-disable-next-line import/prefer-default-export
  list,
  getAuthor
}
