

export default {
  name: 'VideoPost',
  components: {
    BreadCrumbs: () => import('../../../components/elements/breadcrumbs.vue'),
    YoutubeVideo: () => import('../../../components/widgets/youtube-video.vue'),
    VkVideo: () => import('../../../components/widgets/vk-video.vue'),
    FooterGlowWrapper: () => import('../../../components/elements/footer-glow-wrapper.vue')
  },
  beforeMount () {
    this.$store.commit('categories/addRoute', this.postRoute)
  },
  async asyncData ({ app, params, error }) {
    const videoPost = await app.$api('blogPosts', 'article', {
      slug: params.post,
      siteId: 3
    })
    if (typeof videoPost === 'undefined' || typeof videoPost.item === 'undefined' || !videoPost.item) {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text2')} ${params.post} ${app.i18n.t('pages.blog.record.text1')}` })
    }
    return {
      post: videoPost.item,
      postRoute: {
        slug: videoPost.item.articleSlug,
        name: videoPost.item.articleTitle
      }
    }
  },
  computed: {
    hasVkVideo () {
      return !!this.post.vkVideoId
    }
  },
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbName = (index, path) => {
      switch (index) {
      case 0:
        return this.$t(`pages.${path}.title`)
      case 1:
        return this.post.articleCategoryTitle
      case 2:
        return this.post.articleTitle
      default:
        return this.post.articleTitle
      }
    }
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: breadcrumbName(index, path),
        item: breadcrumbPath(index)
      })
    })
    return {
      title: this.post.articleMetaTitle,
      meta: [
        { hid: 'description', name: 'description', content: this.post.articleMetaDescription },
        { hid: 'og:title', name: 'og:title', content: this.post.articleMetaTitle },
        { hid: 'og:description', name: 'og:description', content: this.post.articleMetaDescription }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.post.articleTitle,
            description: this.post.articleMetaDescription,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  }
}

