import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from '../locales/ru.json'
import by from '../locales/by.json'
import kz from '../locales/kz.json'
import ua from '../locales/ua.json'

Vue.use(VueI18n)

export default ({ app, store }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: store.getters['locale/getLocale'],
    fallbackLocale: 'ru',
    silentTranslationWarn: true,
    messages: {
      ru,
      by,
      kz,
      ua
    }
  })

  app.i18n.nl2br = (string, variables = [], stringVariables = {}) => {
    let translate = app.i18n.t(string, stringVariables).split('\n').join('<br />')
    variables.forEach((variable, i) => {
      const regExp = new RegExp(`%${(i + 1)}%`, 'g')
      translate = translate.replace(regExp, variable)
    })
    return translate
  }
}
