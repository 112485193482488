
import { DButton } from 'site-lib'

export default {
  name: 'Facilities',
  components: {
    DButton,
    DarkBackgroundWrapper: () => import('../components/blocks/dark-bg-wrapper.vue'),
    SplitScrollingBlock: () => import('../components/blocks/split-scrolling-block.vue'),
    FacilitiesBlock: () => import('../components/pages/facilities/facilities-block.vue'),
    AdditionsBlock: () => import('../components/blocks/additions-block.vue'),
    IntegrationsBlock: () => import('../components/blocks/integrations-block.vue'),
    MobileBlock: () => import('../components/blocks/mobile-app-block.vue'),
    Companies: () => import('../components/blocks/companies.vue'),
    Prefooter: () => import('../components/elements/pre-footer.vue'),
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    reversedBlocks: ['tasks', 'warehouse']
  })
}
