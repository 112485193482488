
export default {
  name: 'FormElements',
  components: {
    FormField: () => import('./form-field.vue')
  },
  props: {
    header: {
      type: String,
      required: false
    },
    subHeader: {
      type: String,
      required: false
    },
    styleName: {
      type: String,
      default: 'default'
    },
    inputStyle: {
      type: String,
      default: 'c-forms-form-field'
    },
    formFields: {
      type: Array,
      required: true
    },
    formButtonText: {
      type: String
    },
    formEmailName: {
      type: String,
      required: false,
      default: 'default'
    },
    formEmailTitle: {
      type: String,
      required: true
    },
    formSuccessMessage: {
      type: String,
      required: true
    },
    showSendMore: {
      type: Boolean,
      default: false
    },
    metricsTarget: {
      type: String,
      default: null
    },
    metricsUtm: {
      type: String,
      default: null
    },
    showErrorsWhenSubmit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    model: {},
    errors: [],
    buttonText: null,
    messageAfterSubmit: [],
    showPopupAfterSubmit: false,
    hasSubmitErrors: false,
    submitButtonIsDisabled: false
  }),
  mounted () {
    this.buttonText = this.isExist(this.formButtonText)
      ? this.formButtonText
      : this.$t('regForm.buttonDefault')
    this.formFields.map((el) => {
      if (el.validateOnKey || el.required) {
        this.errors.push({
          field: el.name,
          correct: false
        })
      }
    })
  },
  methods: {
    formErrors (e) {
      this.errors.map((el, i) => {
        if (el.field === e.field) {
          if (e.correct) {
            this.errors.splice(i, 1)
            this.formFields.find((x) => x.name === el.field).showError = false
          } else {
            this.formFields.find((x) => x.name === el.field).showError = this.showErrorsWhenSubmit
          }
        }
      })
    },
    async submitForm () {
      const defaultButtonText = this.isExist(this.formButtonText)
        ? this.formButtonText
        : this.$t('regForm.buttonDefault')
      if (this.errors.length > 0) {
        this.errors.map((el) => this.formErrors(el))
        this.buttonText = this.$t('regForm.fillForm')
        setTimeout(() => {
          this.buttonText = defaultButtonText
        }, 3000)
        return false
      }
      const fields = []
      this.formFields.map((field) => {
        if (this.isExist(this.model[field.name])) {
          const description = this.$t(`regForm.${field.name}`)
          fields.push({
            name: field.name,
            description,
            value: this.model[field.name]
          })
        }
      })
      this.submitButtonIsDisabled = true
      this.buttonText = this.$t('regForm.buttonIndexCheck')
      const result = await this.$api('formRequest', 'sendForm', {
        systemName: this.formEmailName,
        title: this.formEmailTitle,
        utm: this.isExist(this.metricsUtm) ? this.metricsUtm : null,
        fields
      })
      this.buttonText = defaultButtonText
      this.submitButtonIsDisabled = false
      if (result.length > 0) {
        this.hasSubmitErrors = true
        result.map((e) => this.messageAfterSubmit.push(e))
      } else {
        this.hasSubmitErrors = false
        this.messageAfterSubmit.push(this.formSuccessMessage)
        if (this.isExist(this.metricsTarget)) {
          await this.sendGoalEvent(this.metricsTarget)
        }
      }
      this.showPopupAfterSubmit = true
      return true
    }
  }
}
