
export default {
  name: 'OnboardingInput',
  data () {
    return {
      targetIsTriggered: false
    }
  },
  props: {
    model: {
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    fieldName: {
      type: String,
      required: true
    },
    labelled: {
      type: Boolean,
      required: false,
      default: true
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    analyticsTargetName: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    getAutocompleteValue () {
      return this.autocomplete ? 'on' : 'off'
    }
  },
  methods: {
    inputHandler (event) {
      if (this.analyticsTargetName !== '' && this.targetIsTriggered === false) {
        this.sendGoalEvent(this.analyticsTargetName)
        this.targetIsTriggered = true
      }
      this.$emit('input', event.target.value)
    }
  }
}
