
import Helpers from '../../mixins/helpers'

export default {
  name: 'Social',
  layout: 'empty',
  mixins: [Helpers],
  components: {
    InputWithLegend: () => import('../../components/forms/input-with-legend.vue')
  },
  data () {
    return {
      email: null,
      buttonText: this.$t('regForm.buttonCreateAccount'),
      formError: {
        phone: null,
        email: null
      }
    }
  },
  computed: {
    canSend () {
      return this.isExist(this.phone) && this.isExist(this.email)
    }
  },
  methods: {
    validate () {
      this.formError = {
        email: null
      }

      if (!this.canSend) {
        setTimeout(() => {
          this.buttonText = this.$t('regForm.buttonCreateAccount')
        }, 3000)
        this.buttonText = this.$t('regForm.errors.form-empty')
        return false
      }
      if (!this.checkEmail()) {
        this.formError.email = this.$t('regForm.errors.email')
        return false
      }
      return true
    },
    create () {
      if (this.validate()) {
        // eslint-disable-next-line no-console
        console.log('create!')
      }
    }
  },
  head () {
    return {
      title: this.$t('pages.login-social.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.login-social.description')
        }
      ]
    }
  }
}
