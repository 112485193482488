
import { CIcon, DButton } from 'site-lib/index.ts'
import domainList from '../../../../plugins/json/domains.json'
import RegistrationLocal from '../../../../mixins/register-local'
import AbTest from '../../../../mixins/abtest'
import Analytics from '../../../../mixins/analytics'
import menuItems from '../../../../mixins/menuItems'

export default {
  name: 'MobileMenu',
  mixins: [RegistrationLocal, AbTest, Analytics, menuItems],
  data () {
    return {
      showMenu: false,
      onTopClass: '',
      showLanguageMenu: false
    }
  },
  components: {
    CIcon,
    DButton,
    MenuBanner: () => import('../menu-banner.vue')
  },
  computed: {
    getLocalesList () {
      return domainList.languages.filter((el) => el.code !== this.activeLanguage)
    }
  },
  watch: {
    showMenu (val) {
      if (val) {
        this.onTopClass = ' opened'
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      } else {
        this.onTopClass = ''
        document.getElementsByTagName('body')[0].style.overflow = ''
        const links = document.getElementsByClassName('main-link')
        for (let i = 0; i < links.length; i += 1) {
          links[i].classList.remove('opened')
        }
      }
      this.$emit('top-class', this.onTopClass)
    },
    $route () {
      this.showMenu = false
    }
  },
  mounted () {
    this.callTrackingStart()
  },
  methods: {
    toggleLocale (locale) {
      this.setLocale(locale)
      this.showLanguageMenu = false
    },
    subMenuAction (e) {
      if (!(e.currentTarget.classList.contains('has-submenu'))) {
        this.showMenu = !this.showMenu
        return
      }

      const link = e.currentTarget
      const links = document.getElementsByClassName('main-link')
      if (link.classList.contains('opened')) {
        link.classList.remove('opened')
        this.menuHeightHandler(false)
      } else {
        for (let i = 0; i < links.length; i += 1) {
          links[i].classList.remove('opened')
        }
        link.classList.add('opened')
        this.menuHeightHandler(true)
      }
    },
    handleLanguageMenu () {
      this.showLanguageMenu = !this.showLanguageMenu
    },
    hideLanguageMenu (e) {
      const composedPath = e.composedPath()
      const exceptionTarget = this.$refs.langSwitcher
      if (!composedPath.includes(exceptionTarget)) {
        this.showLanguageMenu = false
      }
    },
    menuHeightHandler (isSubmenuOpened) {
      if (window.matchMedia('(min-width: 992px) and (max-width: 361px)').matches) {
        return
      }
      const mainMenu = this.$refs.mainMenu
      const subLinks = this.$refs.subLink
      const subMenuActiveClass = 'nav-mobile--submenu'
      if (isSubmenuOpened) {
        const deviceHeight = document.documentElement.clientHeight
        let menuContentHeight = 300
        subLinks.forEach((sublink) => {
          const sublinkHeight = parseInt(window.getComputedStyle(sublink).getPropertyValue('height'), 10)
          menuContentHeight += sublinkHeight
        })
        if (menuContentHeight > deviceHeight) {
          mainMenu.classList.add(subMenuActiveClass)
        }
      } else if (mainMenu.classList.contains(subMenuActiveClass)) {
        mainMenu.classList.remove(subMenuActiveClass)
      }
    },
    showMenuBanner (menuTitle) {
      return menuTitle === 'Продукт' || menuTitle === 'Решения'
    }
  }
}

