
export default {
  name: 'LinkPage',
  data: () => ({
    baseLink: '',
    link: ''
  }),

  computed: {
    megaUrl () {
      const linkWithoutDomain = this.getUri(this.baseLink)
      return `${window.origin}/adm/api/megalink?link=${encodeURIComponent(linkWithoutDomain)}`
    }
  },
  methods: {
    getUri (baseLink) {
      try {
        const url = (new URL(baseLink))
        return url.pathname + url.search
      } catch (e) {
        return ''
      }
    }
  }
}
