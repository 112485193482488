export default async function loadData (params, $api, category = null) {
  const slug = params.post.split('?')[0]
  const categorySlug = params.category?.split('?')[0] || category
  const postData = await $api('blogPosts', 'article', {
    slug,
    siteId: 2
  })

  const showComments = postData.item.isActiveComments === 1
  let comments = []

  if (showComments && typeof postData.item !== 'undefined') {
    const postUrl = `/blog/${postData.item.articleCategorySlug}/${postData.item.articleSlug}/`
    const response = await $api('comments', 'getLocalComments', postUrl)
    if (response.status === 'ok') {
      comments = response.comments
    }
  }

  const showLinks = postData.item.showNavMenu
  const post = postData.item
  if (!post || post.articleCategorySlug !== categorySlug) {
    throw new Error('Post not found')
  }
  return {
    post,
    showComments,
    comments,
    showLinks,
    items: [],
    populars: []
  }
}
