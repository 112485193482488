

export default {
  name: 'Form',
  components: {
    Paper: () => import('./paper.vue')
  },
  props: {
    noPadding: Boolean
  }
}
