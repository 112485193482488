
import Vue, { PropType } from 'vue'
import { CButton } from '../CButton'

export default Vue.extend({
  name: 'CGreenBlock',
  components: {
    CButton
  },
  props: {
    reversed: {
      type: Boolean,
      required: false,
      default: false
    },
    invertedColors: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: false,
      default: ''
    },
    buttonClick: {
      type: Function as PropType<() => void>,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    color (): string {
      return this.invertedColors ? 'success' : 'white'
    },
    hasTitleSlot ():boolean {
      return !!this.$slots['title']
    }
  }
})
