
// Подключаем миксин глобально
import Vue from 'vue'
import Helpers from '../mixins/helpers'
import analytics from '../mixins/analytics'

Vue.mixin(Helpers)
Vue.mixin(analytics)

export default {
  components: {
    Header: () => import('../components/pages/login/header.vue'),
    PopupGenerator: () => import('../components/popup/popup-generator.vue')
  },
  data: () => ({
    loadPopupObject: '',
    showPopupObject: false
  }),
  mounted () {
    this.$root.$on('popupGenerator', (popupName, show) => {
      this.loadPopupObject = popupName
      this.showPopupObject = show
    })
  },
  beforeDestroy () {
    this.$root.$off(['popupGenerator'])
  }
}
