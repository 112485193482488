
export default {
  name: 'MobileAppBlock',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeLanguage () {
      return this.$i18n.locale
    }
  }
}
