
export default {
  name: 'Header',
  components: {
    EmailReg: () => import('./email-reg.vue')
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    pageTitle: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    headerForm: {
      type: String,
      required: false,
      default: ''
    },
    hideSquare: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
