import abSettings from '../plugins/json/abSettings.json'

export default {
  data: () => ({
    abSettings
  }),
  computed: {
    abIndexPage () {
      return this.abPage('indexPage')
    }
  },
  methods: {
    abPage (abName) {
      const abValue = this.getAbValue(abName)
      // eslint-disable-next-line prefer-template
      return () => import('../components/pages/' + this.abSettings[abName].pages[abValue])
    },

    getAbValue (abName) {
      if (!this.isExist(this.abSettings[abName])) {
        return false
      }
      if (!process.browser) {
        return this.getDefault(abName)
      }
      let value = this.loadAbValue(abName)
      if (value === null) {
        if (this.abActive(abName)) {
          value = this.generateValue(abName)
        } else {
          value = this.getDefault(abName)
        }
      }
      this.saveAbValue(abName, value)
      return value
    },

    abActive (abName) {
      const { queryParams } = this.abSettings[abName]
      if (!this.abEnable(abName)) {
        return false
      }
      let result = true
      if (typeof queryParams === 'object') {
        // eslint-disable-next-line no-restricted-syntax
        for (const [param, value] of Object.entries(queryParams)) {
          if (!this.checkQueryParam(param, value)) {
            result = false
            break
          }
        }
      }
      return result
    },

    loadAbData () {
      let data = localStorage.getItem('ab_data')
      if (data === null) {
        data = '{}'
      }
      return JSON.parse(data)
    },

    saveAbData (data) {
      localStorage.setItem('ab_data', JSON.stringify(data))
    },

    loadAbValue (abName) {
      const data = this.loadAbData()
      if (Object.prototype.hasOwnProperty.call(data, abName)) {
        return data[abName]
      }
      return null
    },

    saveAbValue (abName, value) {
      const data = this.loadAbData()
      data[abName] = value
      this.saveAbData(data)
    },

    generateValue (abName) {
      const valueChances = this.abSettings[abName].chances
      const rnd = Math.random()
      let sum = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const [value, chance] of Object.entries(valueChances)) {
        sum += chance
        if (sum >= rnd) {
          this.asyncSendGoal(abName, value)
          return value
        }
      }
      return this.getDefault(abName)
    },

    checkQueryParam (paramName, paramValue) {
      return typeof this.$route.query[paramName] !== 'undefined' && this.$route.query[paramName] === paramValue
    },

    getDefault (abName) {
      return this.abSettings[abName].default
    },

    asyncSendGoal (abName, value) {
      setTimeout(() => {
        this.sendGoalEvent(`${abName}:${value}`)
      }, 1)
    },
    abEnable (abName) {
      return this.isExist(this.abSettings[abName]) ? this.abSettings[abName].enable : false
    },
    needAbTest (setting, param) {
      if (setting !== 'ab') {
        return true
      }
      return this.getAbValue(param.toString()) === 'new'
    }
  }
}
