
export default {
  layout: 'empty',
  head () {
    return {
      title: this.$t('pages.onboarding-subscription.meta.title')
    }
  },
  data () {
    return {
      result: ''
    }
  },
  mounted () {
    if (process.browser) {
      this.result = this.$route.query.value
    }
  }
}
