
import { CInput, DButton, DCheckbox } from 'site-lib/index.ts'
/**
 * Элементы для регистрации
 */
import RegistrationLocal from '~/mixins/register-local'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'RegElements',
  mixins: [RegistrationLocal],
  components: {
    CInput,
    DButton,
    DCheckbox,
    VueRecaptcha
  },
  props: {
    header: {
      type: String,
      required: false,
      default: 'Попробуйте Мегаплан'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    withPromoCode: {
      type: Boolean,
      required: false,
      default: false
    },
    regSourceOption: {
      type: String,
      required: false,
      default: ''
    },
    showSocials: {
      type: Boolean,
      required: false,
      default: true
    },
    className: {
      type: String,
      required: false,
      default: 'c-forms__reg-elements'
    },
    buttonText: {
      type: String,
      required: false,
      default: ''
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      policyAgree: { agreed: false, error: false },
      showSocialsIcons: true,
      showCaptcha: false,
      isVerifyCaptcha: false
    }
  },
  computed: {
    siteKey () {
      return process.env.GCAPTCHA_CODE
    }
  },
  mounted () {
    this.initSocials()
    if (this.showSocialsIcons) {
      this.addSas()
    }
    this.regSourceParameter = this.regSourceOption
  },
  beforeDestroy () {
    this.clearErrors()
    this.removeSas()
  },
  methods: {
    submitForm () {
      if (!this.policyAgree.agreed) {
        this.policyAgree.error = true
        return
      }
      this.toRegistrationQuiz()
    },
    verifyCaptcha () {
      this.isVerifyCaptcha = true
    },
    expiredCaptcha () {
      this.$refs.recaptcha.reset()
    },
    resetPolicyError () {
      this.policyAgree.error = false
    },
    /**
     * Авторизация через 1c
     */
    loginWith1C () {
      const link = `${process.env.HOST_NAME}/adm/api/oauth/callback?oauth=login1c&`
      const esc = encodeURIComponent
      const params = Object.keys(this.getMetaData()).map((k) => `${esc(k)}=${esc(this.getMetaData()[k])}`).join('&')
      window.open(link + params, '_blank')
    },
    sendGoalWithNetwork (e) {
      if (!this.showCaptcha) {
        this.showCaptcha = true
        return
      }
      const path = e.target.currentSrc.split('/')
      const getSocialName = path[path.length - 1].split('.')
      const socialName = getSocialName[0]
      this.sendGoalEvent('openRegFormWithSocial', { provider: socialName })
    },
    initSocials () {
      if (this.isProduction) {
        this.showSocialsIcons = this.showSocials && this.getOauthMetaData()
      }
      this.showSocialsIcons = this.showSocials && this.oauthMetaData !== null
    },
    addSas () {
      const script = document.createElement('script')
      script.id = 'sas'
      script.src = 'https://sas.megaplan.ru/oauth/widgets/js'
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    },
    removeSas () {
      const sasScript = document.getElementById('sas')
      if (this.isExist(sasScript)) {
        sasScript.remove()
        document.querySelector('link[href="https://sas.megaplan.ru/bundles/oauth/css/widget.css"]').remove()
      }
    }
  }
}
