
import analytics from '../mixins/analytics'

export default {
  components: {
    Main404Page: () => import('../pages/404.vue')
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  mixins: [analytics]
}
