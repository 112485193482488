import articlesRoutes from '../plugins/json/articles/meta.json'
import clientsRoutes from '../plugins/json/clients/meta.json'
import crmForBusinessRoutes from '../plugins/json/crm-for-business/meta.json'
import crmCapabilitiesRoutes from '../plugins/json/crm-capabilities/meta.json'
import blogAuthorsRoutes from '../plugins/json/blog/authors/meta.json'
import staticRoutes from '../plugins/json/staticRoutes.json'

export default {
  methods: {
    saveRoutes () {
      if (process.client) {
        const routes = [
          ...clientsRoutes.pages,
          ...crmForBusinessRoutes.pages,
          ...crmCapabilitiesRoutes.pages,
          ...blogAuthorsRoutes.pages,
          ...staticRoutes.pages
        ]
        routes.forEach((el) => {
          const route = {
            slug: el.slug,
            name: el.name
          }
          this.$store.commit('categories/addRoute', route)
        })
        articlesRoutes.pages.forEach((el) => {
          const route = {
            slug: el.slug,
            name: el.translations[this.activeLanguage].name || el.translations.ru.name
          }
          this.$store.commit('categories/addRoute', route)
        })
      }
    }
  }
}
