
import RegistrationLocal from '~/mixins/register-local'
import { pages } from '~/plugins/json/clients/meta.json'

export default {
  name: 'CrmForBusinessTemplateUpdated',
  components: {
    Companies: () => import('~/components/blocks/companies.vue'),
    SplitSlider: () => import('./split-slider.vue'),
    SplitScrollingBlock: () => import('~/components/blocks/split-scrolling-block.vue'),
    Prefooter: () => import('~/components/elements/pre-footer.vue'),
    SliderMultipleItems: () => import('~/components/UI/slider-multiple-items.vue'),
    ClientCard: () => import('~/components/pages/clients/client-card.vue'),
    VideoCases: () => import('~/components/UI/video-cases.vue'),
    DarkBackgroundWrapper: () => import('~/components/blocks/dark-bg-wrapper.vue'),
    StagesBlock: () => import('~/components/blocks/stages-block.vue'),
    ImplementationBlock: () => import('~/components/blocks/implementation-block.vue'),
    FaqBlock: () => import('~/components/blocks/faq-block.vue'),
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue')
  },
  mixins: [RegistrationLocal],
  props: {
    meta: {
      type: Object,
      required: true
    }
  },
  methods: {
    contactUsClick () {
      this.showPopup('form', {
        header: this.$t('regForm.contactSales'),
        formEmailName: 'default',
        formEmailTitle: `${this.$t('regForm.requestFrom')} "${document.title}"`
      })
    }
  },
  computed: {
    clientsCases () {
      return pages.filter((el) => this.meta.clients.includes(el.slug))
    },
    folder () {
      return `/upload/pages/crm-for-business/slugs/${this.meta.slug}`
    }
  }
}
