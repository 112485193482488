import request from './request'

const API_URL = '/adm/api/save-lead/'

function step1 (params) {
  return request(API_URL, 'step1', params)
}

function step2 (params) {
  return request(API_URL, 'step2', params)
}

export {
  step1,
  step2
}
