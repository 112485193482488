
import Vue, { PropType } from 'vue'
import { CIcon } from '../CIcon'

type Directions = 'left' | 'right'

export default Vue.extend({
  name: 'CArrow',
  components: {
    CIcon,
  },
  props: {
    direction: {
      type: String as PropType<Directions>,
      required: false,
      default: 'left' as Directions
    },
  },
  data(): { windowWidth: number} {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    iconName(): string {
      if (this.direction === 'right'){
        if (this.windowWidth > 1365) {
          return 'ARROW_SIDE_RIGHT'
        }
        else {
          return 'ARROW_SIDE_RIGHT_ADAPTIVE'
        }
      }
      else {
        if (this.windowWidth > 1365) {
          return 'ARROW_SIDE_LEFT'
        }
        else {
          return 'ARROW_SIDE_LEFT_ADAPTIVE'
        }
      }
    },
    iconSize(): string {
      if (this.windowWidth > 1365){
        return 'big'
      }
      if (this.windowWidth > 1023){
        return 'small'
      }
      else return 'xsmall'
    }
  },
    methods: {  
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
})
