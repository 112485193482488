
import TabsJson from '~/plugins/json/documents/documents.json'

export default {
  name: 'Documents',
  components: {
    RegButton: () => import('../components/forms/reg-button.vue'),
    SpaceSky: () => import('../components/blocks/space-sky.vue'),
    SplitScrollingBlock: () => import('../components/blocks/split-scrolling-block.vue'),
    SafetyAnimatedBlock: () => import('../components/blocks/safety-animated-block.vue'),
    Tabs: () => import('../components/elements/tabs.vue'),
    Companies: () => import('../components/blocks/companies.vue'),
    SimpleBlock: () => import('../components/blocks/simple-logo-title-reg.vue'),
    FormElements: () => import('../components/forms/new/form-elements.vue'),
    Footer: () => import('../components/elements/footer.vue')
  },
  data () {
    return {
      tabsItems: TabsJson.tabs,
      activeTab: TabsJson.tabs[0].tab,
      possibilitiesContent: TabsJson.tabs[0],
      knowledgeBlock: TabsJson.knowledgeBlock,
      safetyItems: TabsJson.safety,
      formSubmitted: false
    }
  },
  methods: {
    setTab (tab) {
      this.possibilitiesContent = this.tabsItems.find((item) => item.tab === tab)
      this.activeTab = tab
    },
    toggleFormStatus (value) {
      this.formSubmitted = value
    },
    getFormParams () {
      return {
        header: this.$t('pages.documents.form.header'),
        subHeader: this.$t('pages.documents.form.sub-header'),
        formEmailTitle: this.$t('pages.documents.form.formEmailTitle'),
        formSuccessMessage: this.$t('regForm.defaultSuccessMessage'),
        styleName: 'documents',
        inputStyle: 'documents',
        formEmailName: 'documents',
        formFields: [
          {
            name: 'name'
          },
          {
            name: 'phone',
            validateOnKey: true,
            required: true,
            rule: 'phone'
          },
          {
            name: 'email',
            validateOnKey: true,
            required: true,
            rule: 'email'
          }
        ]
      }
    }
  },
  head () {
    return {
      title: this.$t('pages.documents.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.documents.meta.description')
        }
      ]
    }
  }
}
