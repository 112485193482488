
import RegButtonAmp from '../../forms/reg-button-amp.vue'
import AmpBottomBanner from '../blog/amp-bottom-banner.vue'

export default {
  name: 'AmpCrmPostExtended',
  props: {
    meta: {
      type: Object,
      required: true
    },
    folder: {
      type: String,
      required: true
    },
    headerImage: {
      type: String,
      required: false,
      default: 'common/bg/articles-inner.jpg'
    },
    productCode: {
      type: String,
      required: false,
      default: 'crmbusiness'
    },
    demoHost: {
      type: String,
      required: false,
      default: 'crmbusiness.megaplan.ru'
    }
  },
  components: {
    RegButtonAmp,
    AmpBottomBanner
  },
  computed: {
    previewType () {
      return this.isExist(this.meta.video) ? 'video' : 'img'
    },
    typeForSlug () {
      if (this.meta.slug === 'crm-for-construction') {
        return 'construction'
      }
      if (this.meta.slug === 'crm-for-manufacture') {
        return 'manufacture'
      }
      return 'company'
    },
    demoHostLink () {
      return this.meta.demoLink ? this.meta.demoLink : this.demoHost
    },
    canonicalUrl () {
      return process.env.HOST_NAME + this.$route.fullPath.replace(/\/amp\//, '/')
    }
  },
  methods: {
    setClass (bIndex) {
      const isOrdered = bIndex % 2 === 0 ? ' order-2 order-lg-1' : ''
      return `col-12 col-lg-6 content-blocks-block-text${isOrdered}`
    },
    getFolderContent (folder, index, type = 'fits') {
      const types = {
        fits: {
          name: 'icon-fits',
          format: 'svg'
        },
        blocks: {
          name: 'block',
          format: 'png'
        }
      }
      return `/upload/${folder}${types[type].name + (index + 1)}.${types[type].format}`
    }
  },
  mounted () {
    this.setProduct(this.productCode)
  },
  beforeDestroy () {
    this.setProduct('')
  },
  head () {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.canonicalUrl
        }
      ]
    }
  }
}
