
export default {
  name: 'BlogItemsList',
  components: {
    BlogCard: () => import('./blog-list-card.vue')
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    content: {
      type: [Array, Object],
      required: true
    },
    // "grid", "grid2", "row4", "row3"
    layout: {
      type: String,
      default: 'grid'
    },
    noIcons: {
      type: Boolean,
      default: false
    },
    linkToPage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      gridLayoutCount: this.layout === 'grid' ? [0, 5] : [0, 1]
    }
  }
}
