
import imgPopup from '../../../mixins/imgPopup'

export default {
  props: {
    html: {
      type: String,
      default: ''
    }
  },
  computed: {
    dynamicComponent () {
      return {
        template: this.html
          .replace(/{{/gm, '&#123;&zwnj;&#123;')
          .replace(/}}/gm, '&#125;&zwnj;&#125;')
      }
    }
  },
  mixins: [imgPopup],
  // eslint-disable-next-line
  render (createElement, context) {
    return createElement('div', [
      createElement(this.dynamicComponent)
    ])
  }
}
