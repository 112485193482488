
import answerList from '../plugins/json/help-training/answerList_for_help.json'

export default {
  name: 'HelpTrainingPage',
  components: {
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    helpBlocks: answerList.pages,
    searchText: ''
  }),
  mounted () {
    const setInputPlaceholder = () => {
      let placeholder = this.$t('pages.help-training.search-answer')
      if (window.innerWidth < 360) {
        placeholder = this.$t('pages.help-training.search-answer-short')
      }
      this.$refs.searchInput.placeholder = placeholder
    }
    setInputPlaceholder()
    window.addEventListener('resize', () => {
      setInputPlaceholder()
    })
    if (this.$route.hash) {
      this.scrollToRoutHash()
    }
  },
  methods: {
    helpSearch () {
      window.open(`https://help.megaplan.ru/search/${this.searchText}`, '_blank')
    },
    scrollToRoutHash () {
      const hashList = ['training', 'webinar']
      const hashText = this.$route.hash.replace('#', '')
      hashList.includes(hashText) ? this.scrollToBlock(hashText) : undefined
    }
  }
}

