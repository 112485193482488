
export default {
  name: 'imagePopup',
  props: {
    image: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: false,
      default: ''
    }
  }
}
