
export default {
  name: 'BlogCases',
  components: {
    VideoCases: () => import('./video-category-block.vue')
  },
  data () {
    return {
      items: [],
      pageNumber: 0
    }
  },
  methods: {
    async getItems () {
      const posts = await this.$api('blogPosts', 'list', {
        page: this.pageNumber,
        offset: this.pageNumber * 10
      })
      const filteredItems = posts.items.filter((post) => post.siteId !== 1 && post.categorySlug !== 'crm').slice(0, 4)
      this.pageNumber += 1
      return filteredItems
    }
  },
  async fetch () {
    const posts = []
    while (posts.length < 4) {
      // eslint-disable-next-line no-await-in-loop
      const morePosts = await this.getItems()
      posts.push(...morePosts)
    }
    this.items.push(...posts.slice(0, 4))
  }
}
