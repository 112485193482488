
import { CIcon, DButton } from 'site-lib/index.ts'
import AbTest from '../../../../mixins/abtest'
import Analytics from '../../../../mixins/analytics'
import menuItems from '../../../../mixins/menuItems'

export default {
  name: 'DesktopMenu',
  mixins: [AbTest, Analytics, menuItems],
  components: {
    CIcon,
    DButton,
    MenuBanner: () => import('../menu-banner.vue')
  },
  data () {
    return {
      subMenuData: null,
      menuTitle: null,
      showSubmenu: false,
      subMenuActivated: false,
      itemServices: false
    }
  },
  props: {
    mode: {
      type: String,
      required: true,
      default: ''
    }
  },
  watch: {
    $route () {
      this.showSubmenu = false
    }
  },
  methods: {
    mainMenuClicked (item) {
      this.subMenuActivated = true
      this.menuTitle = item.title
      this.subMenuAction(item.subMenu)
      this.sendGoalEvent('menuClicked:new', { 'Новое меню (клик)': item.title })
    },
    mainMenuHovered (item) {
      this.menuTitle = item.title
      this.subMenuAction(item.subMenu)
      this.sendGoalEvent('menuHovered:new', { 'Новое меню (наведение)': item.title })
    },
    subMenuClicked (title) {
      this.sendGoalEvent('subMenuClicked:new', { 'Новое подменю (клик)': { [this.menuTitle]: title } })
    },
    subMenuAction (item, show = true) {
      if (this.subMenuActivated) {
        this.subMenuData = item
        this.showSubmenu = show
      }
    },
    menuLeave () {
      this.menuTitle = null
      this.subMenuAction(null, false)
      this.subMenuActivated = false
    },
    showMenuBanner (menuTitle) {
      return menuTitle === 'Продукт' || menuTitle === 'Решения'
    }
  }
}

