
export default {
  name: 'InputWithLegend',
  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    errors: {
      type: String,
      default: null
    }
  },
  computed: {
    showLegend () {
      return this.value !== null ? this.value.length > 0 : false
    }
  }
}
