import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/blog-categories/`

/**
 * id = 0, slug = null, siteId = 1
 */
function get (params) {
  return request(API_URL, 'get', params)
}

// eslint-disable-next-line import/prefer-default-export
export { get }
