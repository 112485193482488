export default {
  mounted () {
    this.imagesZoom()
  },
  methods: {
    imagesZoom () {
      try {
        const container = this.$refs.postWrapper || document.querySelector('.post')
        if (container) {
          const images = container.querySelectorAll('img')
          if (!images) { return }
          images.forEach((img) => {
            img.addEventListener('click', () => {
              const regAttr = img.getAttribute('reg')
              if (regAttr) {
                this.showPopup('reg-form')
                return
              }
              const formAttr = img.getAttribute('form')
              if (formAttr) {
                const headerAttr = img.getAttribute('header')
                const typeFormAttr = img.getAttribute('typeform')
                this.showPopup('form', {
                  header: headerAttr,
                  formEmailName: typeFormAttr,
                  formEmailTitle: `${this.$t('regForm.requestFrom')} "${document.title}"`
                })
                return
              }
              const srcAttr = img.getAttribute('src')
              if ((/\.(svg)$/i).test(srcAttr)) {
                return
              }
              let altAttr = ''
              try {
                altAttr = img.getAttribute('alt')
                // eslint-disable-next-line brace-style
              }
              // eslint-disable-next-line
              catch (e) {}
              this.showPopup('image-popup', { image: srcAttr, alt: altAttr })
            })
          })
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to display image popup: ', e)
      }
    }
  }
}
