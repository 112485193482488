
export default {
  name: 'VideoIndex',
  components: {
    BreadCrumbs: () => import('../../components/elements/breadcrumbs.vue'),
    VideoCategoryBlock: () => import('../../components/blocks/video-category-block.vue'),
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue')
  },
  async asyncData ({ app, error }) {
    const categories = await app.$api('blogCategories', 'get', {
      siteId: 3
    })
    if (typeof categories === 'undefined') {
      return error({ statusCode: 500, message: 'Внутренняя ошибка' })
    }
    const sortedCategories = Array.isArray(categories) ? categories.sort((a, b) => a.sort - b.sort) : [categories]
    return {
      categories: sortedCategories
    }
  },
  head () {
    return {
      title: this.$t('pages.video-megaplan.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.video-megaplan.meta.description')
        }
      ]
    }
  }
}
