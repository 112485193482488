
import MCButton from '../../UI/buttons/mc-button.vue'

export default {
  name: 'ModalSubscribe',
  components: {
    MCButton
  },
  props: {
    dialog: {
      type: Boolean,
      required: false,
      default: true
    },
    modalTitle: {
      type: String,
      required: true,
      default: ''
    },
    modalMessage: {
      type: String,
      required: true,
      default: ''
    },
    preloader: {
      type: Boolean,
      required: false,
      default: false
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: true
    },
    closeButtonText: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    handleOverlayClick () {
      if (this.dialog) {
        this.closePopup('modal-subscribe')
      }
      this.$router.back()
    }
  }
}
