
import { DButton } from 'site-lib'

export default {
  name: 'ClientsBanner',
  components: {
    DButton
  },
  methods: {
    bannerClick () {
      this.showRegPopup('reg-form',
        {
          metricsTarget: 'casesRegNew'
        },
        'casesRegNew')
    }
  }
}
