
export default {
  name: 'ClientFeatures',
  props: {
    clientFeatures: {
      type: Object,
      default: () => ({})
    }
  }
}
