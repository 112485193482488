
import PriceHelpers from '../mixins/prices'
import 'swiper/dist/css/swiper.css'

export default {
  name: 'FreePage',
  mixins: [PriceHelpers],
  components: {
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    hoverEl: null,
    selectedMonth: 12, // выводим цены с учетом выбора месяца
    tab: 'cloud',
    cloudSlider: {
      slidesPerView: 3,
      breakpoints: {
        991: {
          slidesPerView: 1
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }),
  methods: {
    formParams (tariff) {
      let subtitleString = this.$t('regForm.subtitle')
      if (tariff === 'startlite') {
        subtitleString = ''
      }
      return {
        subtitle: subtitleString,
        header: this.$i18n.nl2br('regForm.title')
      }
    }
  },
  head () {
    return {
      title: this.$t('pages.free.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.free.meta.description')
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  }
}
