

export default {
  name: 'BlogAuthorProfile',
  props: {
    author: {
      type: Object,
      required: true
    }
  }
}
