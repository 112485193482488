
export default {
  name: 'MobilePage',
  components: {
    RegButton: () => import('../../components/forms/reg-button.vue'),
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    phoneSlider: {
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 5000
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    desktopSlider: {
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }),
  computed: {
    printBlocks () {
      return this.$t(`pages.${this.route}.deals.left`).concat(this.$t(`pages.${this.route}.deals.right`))
    }
  },
  methods: {
    pauseSwiper (name) {
      this[name].autoplay.stop()
    },
    startSwiper (name) {
      this[name].autoplay.start()
    },
    switchSlide (number) {
      this.phone.slideTo(number)
      const buttons = document.getElementsByClassName('slides-deals')
      const buttonMobile = document.getElementsByClassName('slides-mobile-block')
      for (let i = 0; i < buttons.length; i += 1) {
        buttons[i].classList.remove('active')
        buttonMobile[i].classList.remove('active')
      }
      buttons[number].classList.add('active')
      buttonMobile[number].classList.remove('active')
    },
    switchSliderButton () {
      const slideNumber = this.phone.realIndex
      const buttons = document.getElementsByClassName('slides-deals')
      const buttonMobile = document.getElementsByClassName('slides-mobile-block')
      for (let i = 0; i < buttons.length; i += 1) {
        buttons[i].classList.remove('active')
        buttonMobile[i].classList.remove('active')
      }
      buttons[slideNumber].classList.add('active')
      buttonMobile[slideNumber].classList.add('active')
    }
  }

}

