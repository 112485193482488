
import BlogList from './blog-items-list.vue'
import NewsItems from './news-items.vue'

export default {
  name: 'BlogSearchResult',
  components: {
    BlogList: () => import('./blog-items-list.vue'),
    BlogLoadNextPage: () => import('./blog-load-next-page.vue')
  },
  props: {
    result: {
      type: [Array, Object],
      required: false,
      default: null
    },
    popular: {
      type: [Array, Object],
      required: false,
      default: null
    },
    siteId: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    items: {
      blogList: BlogList,
      news: NewsItems
    },
    sectionBlogId: process.env.sectionBlogId
  }),
  computed: {
    getComponent () {
      if (this.siteId === this.sectionBlogId) {
        return this.items.blogList
      }
      return this.items.news
    }
  }
}
