export default (context, inject) => {
  // eslint-disable-next-line consistent-return
  inject('api', async (controller, method, params) => {
    try {
      // eslint-disable-next-line prefer-template,global-require,import/no-dynamic-require
      const api = require('../api/' + controller.replace(/^\/+|\/+$|\.+/g, ''))
      return await api[method](params)
    } catch (e) {
      // e
    }
  })
}
