
import { DButton } from 'site-lib'

export default {
  name: 'CrmCapabilitiesDescriptionBlock',
  components: {
    DButton,
    RegButtonAmp: () => import('../forms/reg-button-amp.vue')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    reversed: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonColor: {
      required: false,
      default: 'black',
      type: String
    }
  },
  computed: {
    imgObj () {
      return {
        src: `/upload/pages/crm-capability/video/${this.item.gif}.webm`,
        loading: `/upload/pages/crm-capability/plugs/${this.item.gif}.png`,
        error: `/upload/pages/crm-capability/plugs/${this.item.gif}.png`
      }
    },
    hasButtonSlot () {
      return !!this.$slots.button
    }
  },
  methods: {
    itemClass (item) {
      let classes = ''
      // if ((index + 1) % 2 === 0) {
      //   classes += 'crm-capability__item--reverse '
      // }
      if (this.reversed) {
        classes += 'crm-capability__item--reverse '
      }
      // eslint-disable-next-line
      return classes += ('crm-capability__item--' + item.classes)
    }
  }
}
