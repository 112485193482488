import request from './request'

const API_URL = '/adm/api/account/data'

function sendAccountQuizData (params) {
  return request(API_URL, 'account-quiz', params)
}

export {
  // eslint-disable-next-line import/prefer-default-export
  sendAccountQuizData
}
