
import { DButton, CIcon } from 'site-lib/index.ts'
import Content from '../../plugins/json/clients/meta.json'
import ClientsAbout from '../../plugins/json/clients/clientsAbout.json'

import 'swiper/dist/css/swiper.css'

export default {
  name: 'ClientsIndex',
  components: {
    DButton,
    CIcon,
    ClientCard: () => import('../../components/pages/clients/client-card.vue'),
    QuotesSlider: () => import('../../components/UI/quotes-slider.vue'),
    VideoCases: () => import('../../components/UI/video-cases.vue'),
    BreadCrumbs: () => import('../../components/elements/breadcrumbs.vue'),
    FooterGlowWrapper: () => import('../../components/elements/footer-glow-wrapper.vue')
  },
  data () {
    return {
      clientsAbout: ClientsAbout.comments,
      showedCases: null,
      hasMoreCases: true,
      currentFilters: [],
      swiperOption: {
        autoplay: {
          delay: 5000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      formParams: {
        header: this.$t('pages.clients.form-title'),
        formEmailName: 'blogform',
        formEmailTitle: this.$t('pages.clients.email-title'),
        metricsTarget: 'submitCasePublicationRequest'
      }
    }
  },
  computed: {
    mainCase () {
      return Content.pages[0]
    },
    allCases () {
      return Content.pages.filter((el, index) => el.active && index > 0)
    },
    tagsList () {
      return Content.tagsList
    },
    quotesSlides () {
      return ClientsAbout.comments
    },
    queryFilter () {
      return this.$route.query.filter
    }
  },
  methods: {
    regAction () {
      const popupName = 'form'
      const formParams = this.formParams
      this.showRegPopup(popupName, formParams)
    },
    showMoreCases () {
      const moreCases = this.allCases.slice(this.showedCases.length, this.showedCases.length + 10)
      this.showedCases = this.showedCases.concat(moreCases)
      if (moreCases.length < 10) { this.hasMoreCases = false }
    },
    isTagActive (tag) {
      return this.currentFilters.includes(tag)
    },
    toggleFilter (tag) {
      if (this.isTagActive(tag)) {
        this.currentFilters = this.currentFilters.filter((item) => item !== tag)
      } else {
        this.currentFilters.push(tag)
      }
      this.sendGoalEvent('newClientsFilterClick')
    },
    isValidFilter (filter) {
      return this.tagsList.some((category) => category.tags.includes(filter))
    },
    resetFilters () {
      this.currentFilters = []
      if (this.queryFilter) {
        this.resetQueryFilter()
      }
      this.hasMoreCases = true
    },
    resetQueryFilter () {
      this.$router.replace({ query: null })
    }
  },
  watch: {
    currentFilters (filters) {
      if (filters.length === 0) {
        this.showedCases = this.allCases.slice(0, 10)
        this.queryFilter ? this.resetQueryFilter() : false
      } else {
        if (filters.length > 1) {
          this.queryFilter ? this.resetQueryFilter() : false
        }
        // eslint-disable-next-line max-len
        this.showedCases = this.allCases.filter((theCase) => theCase.tags.some((tag) => this.currentFilters.includes(tag)))
      }
    },
    queryFilter (filter) {
      if (this.isValidFilter(filter)) {
        this.currentFilters = [filter]
      }
    }
  },
  mounted () {
    if (this.queryFilter && this.isValidFilter(this.queryFilter)) {
      this.currentFilters.push(this.queryFilter)
    } else if (this.queryFilter && !this.isValidFilter(this.queryFilter)) {
      this.showedCases = this.allCases.slice(0, 10)
      this.resetQueryFilter()
    } else {
      this.showedCases = this.allCases.slice(0, 10)
    }
  },
  beforeDestroy () {
    this.clientsAbout = null
    this.showedCases = null
  }
}
