
import subscribePopup from '../../../mixins/subscribePopup'

export default {
  name: 'BlogSlug',
  mixins: [subscribePopup],
  components: {
    FooterGlowWrapper: () => import('../../../components/elements/footer-glow-wrapper.vue'),
    BlogContentNew: () => import('../../../components/pages/blog/blog-content-new.vue')
  },
  async asyncData ({ app, params, error }) {
    const slug = params.category.split('?')[0]
    const category = await app.$api('blogCategories', 'get', {
      slug,
      siteId: 2
    })
    if (typeof category === 'undefined' || typeof category.id === 'undefined' || category.length === 0) {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text')} ${slug} ${app.i18n.t('pages.blog.record.text1')}` })
    }

    const posts = await app.$api('blogPosts', 'list', {
      categoryId: category.id,
      siteId: 2,
      limit: 6
    })
    const populars = await app.$api('blogPosts', 'list', {
      categoryId: category.id,
      siteId: 2,
      limit: 4,
      popular: 1
    })
    if (typeof posts === 'undefined' || typeof posts.items === 'undefined' || typeof populars === 'undefined' || typeof populars.items === 'undefined') {
      return error({ statusCode: 404, message: `${app.i18n.t('pages.blog.record.text')} ${slug} ${app.i18n.t('pages.blog.record.text1')}` })
    }
    const categories = await app.$api('blogCategories', 'get', {
      siteId: 1
    })
    const updateCategory = categories.find((item) => item.slug === 'updates')
    const updateItems = await app.$api('blogPosts', 'list', {
      categoryId: updateCategory.id,
      siteId: 1,
      limit: 4
    })
    const videos = await app.$api('blogPosts', 'list', {
      siteId: 3,
      limit: 3
    })
    return {
      category,
      posts: posts.items,
      populars: populars.items,
      updates: updateItems.items,
      videos: videos.items
    }
  },
  data: () => ({
    searchResults: null
  }),
  head () {
    const domainUpperLevel = this.activeLanguage.toLowerCase()
    const domain = `https://megaplan.${domainUpperLevel}`
    const { fullPath } = this.$route
    const pathArray = fullPath.split('/').filter((path) => path)
    const breadcrumbPath = (index) => {
      let path = domain
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        path += `/${pathArray[i]}`
      }
      return path
    }
    const breadcrumbsList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Главная',
        item: domain
      }
    ]
    pathArray.map((path, index) => {
      breadcrumbsList.push({
        '@type': 'ListItem',
        position: index + 2,
        name: index === 0 ? this.$t(`pages.${path}.title`) : this.category.title,
        item: breadcrumbPath(index)
      })
    })

    return {
      title: this.category.title + this.$t('pages.blog.meta.common-title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `${this.category.title}. ${this.$t('pages.blog.meta.description')}`
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: domain + fullPath,
            image: `${domain}/share.png`,
            name: this.category.title,
            description: this.$t('pages.blog.meta.description'),
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbsList
            },
            publisher: {
              '@type': 'Organization',
              name: 'Megaplan',
              logo: {
                '@type': 'ImageObject',
                url: `${domain}/share.png`,
                width: '200',
                height: '200'
              }
            }
          }
        }
      ]
    }
  }
}
