
import Vue, { PropType } from 'vue'
import { CIcon, IconNames } from '../CIcon'

type Sizes = 'xsmall' | 'small' | 'normal' | 'big'
type Colors = 'success' | 'white' | 'grey' | 'transparent' | 'black'

export default Vue.extend({
  name: 'CButton',
  components: {
    CIcon
  },
  props: {
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    className: {
      type: String,
      required: false
    },
    iconName: {
      type: String as PropType<keyof typeof IconNames>,
      required: false
    },
    size: {
      type: String as PropType<Sizes>,
      required: false,
      default: 'small' as Sizes
    },
    color: {
      type: String as PropType<Colors>,
      required: false,
      default: 'success' as Colors
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    href: {
      type: String,
      required: false
    },
    // название файла, если кнопка это ссылка на скачивание
    download: {
      type: String,
      required: false
    },
    blank: {
      type: Boolean,
      required: false,
      default: false
    },
    padding: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    sizeClass(): string {
      return `button-size-${this.size}`
    },
    colorClass(): string {
      return `button-color-${this.color}`
    },
    paddingClass(): string {
      return this.padding ? '' : 'button-no-padding'
    },
    iconClass(): string {
      return this.disabled ? 'button-icon-disabled' : 'button-icon'
    }
  },
  methods: {
    onClick() {
      if (!this.disabled && !this.href) {
        this.$emit('click')
      }
    }
  }
})
