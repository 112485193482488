
import { CIcon, DButton } from 'site-lib'
import RegisterLocal from '../../mixins/register-local'

export default {
  name: 'email-reg',
  components: {
    CIcon,
    DButton
  },
  mixins: [RegisterLocal],
  props: {
    customClass: {
      type: String,
      required: false
    },
    iconName: {
      type: String,
      required: false,
      default: 'EMAIL_1'
    }
  },
  data () {
    return {
      active: false
    }
  },
  watch: {
    active (val) {
      const input = document.getElementsByClassName('registration__input-email')[0]
      if (val) {
        input.classList.add('registration__input-email--active')
      } else {
        input.classList.remove('registration__input-email--active')
      }
    }
  },
  computed: {
    regAction () {
      return this.userDeviceIsMobile === true ? this.toMobileQuiz : this.beforeCreate
    }
  },
  methods: {
    toMobileQuiz () {
      this.toRegistrationQuiz()
      this.sendGoalEvent('headerFormButtonClick')
      if (process.browser && this.isExist(window.VK)) {
        window.VK.Retargeting.Event('conversion')
      }
    }
  }
}
