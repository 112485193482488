
import { CModal } from 'site-lib/index.ts'

import RegisterLocal from '~/mixins/register-local'

export default {
  name: 'FormPopup',
  components: {
    CModal,
    FormElements: () => import('~/components/forms/new/form-elements.vue')
  },
  mixins: [RegisterLocal],
  props: {
    formFields: {
      type: Array,
      required: false,
      /**
       * в name указываем lang параметр name: 'name' === this.$t('regForm.name')
       * и текст ошибки this.$t('regForm.errors.name')
       */
      default: () => [
        {
          name: 'name',
          autofocus: true
        },
        {
          name: 'email',
          required: true,
          validateOnKey: true,
          rule: 'email'
        },
        {
          name: 'phone',
          required: true,
          validateOnKey: true,
          rule: 'phone'
        },
        {
          name: 'company'
        }
      ]
    },
    header: {
      type: String,
      default: null
    },
    subHeader: {
      type: String,
      default: null
    },
    formButtonText: {
      type: String
    },
    formEmailName: {
      type: String,
      required: false,
      default: 'default'
    },
    formEmailTitle: {
      type: String,
      required: true
    },
    formSuccessMessage: {
      type: String,
      required: false
    },
    formSuccessDescription: {
      type: String,
      required: false
    },
    formSuccessButtonText: {
      type: String,
      required: false
    },
    formSuccessButtonLink: {
      type: String,
      required: false
    },
    sendsay: {
      type: Object,
      default: () => ({})
    },
    showSendMore: {
      type: Boolean,
      default: false
    },
    metricsTarget: {
      type: String,
      default: null
    },
    metricsUtm: {
      type: String,
      default: null
    },
    setUtm: {
      type: Boolean,
      default: true
    },
    bannerId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    model: {},
    scrollable: null,
    formObserver: null,
    showPopupAfterSubmit: false
  }),
  computed: {
    getRoute () {
      return this.$route.name === 'blog-category-post' || this.$route.name === 'news-category-post'
        ? this.$route.fullPath
        : this.$route.name
    },
    formParams () {
      const header = this.header
        ? this.header
        : this.getTranslation(`pages.${this.getRoute}.form.header`, 'regForm.title')

      const subHeader = this.subHeader
        ? this.subHeader
        : this.getTranslation(`pages.${this.getRoute}.form.subtitle`)

      const formButtonText = this.formButtonText
        ? this.formButtonText
        : this.getTranslation(`pages.${this.getRoute}.form.btnText`, 'regForm.buttonDefault')

      const formEmailTitle = this.formEmailTitle
        ? this.formEmailTitle
        : this.getTranslation(`pages.${this.getRoute}.form.emailTitle`, 'regForm.defaultTitle', { path: this.getRoute })

      const formSuccessMessage = this.formSuccessMessage
        ? this.formSuccessMessage
        : this.getTranslation(`pages.${this.getRoute}.form.successMessage`, 'regForm.defaultSuccessMessage')

      return {
        header,
        subHeader,
        formFields: this.formFields,
        formButtonText,
        formEmailName: this.formEmailName,
        formEmailTitle,
        formSuccessMessage,
        formSuccessDescription: this.formSuccessDescription,
        formSuccessButtonText: this.formSuccessButtonText,
        formSuccessButtonLink: this.formSuccessButtonLink,
        sendsay: this.sendsay,
        metricsUtm: this.setUtm ? this.getMetaData().utm_source : null,
        metricsTarget: this.metricsTarget,
        showSendMore: this.showSendMore,
        bannerId: this.bannerId
      }
    }
  },
  methods: {
    setFormScroll (form) {
      if (process.browser) {
        if (!this.scrollable && window.innerHeight - form.offsetHeight < 100) {
          this.scrollable = true
        }
        if (this.scrollable && window.innerHeight - form.scrollHeight >= 100) {
          this.scrollable = false
        }
      }
    },
    observeHeight () {
      const form = document.querySelector('.c-popup-form-content')
      this.formObserver = new ResizeObserver((() => {
        this.setFormScroll(form)
      }))
      this.formObserver.observe(form)
    },
    getTranslation (key, alternativeKey = '', alternativeKeyVariables = {}) {
      if (this.$te(key) || this.$te(key, 'ru')) {
        return this.$i18n.nl2br(key)
      }
      if (alternativeKey) {
        return this.$i18n.nl2br(alternativeKey, [], alternativeKeyVariables)
      }
      return ''
    },
    /**
     * Проверяем поля формы
     * на пустоту
     */
    checkEmptyModelField () {
      let result = true
      Object.keys(this.model).forEach((key) => {
        if (this.model[key].length > 0) {
          result = false
        }
      })
      return result
    },
    /**
     * Проверяем поля формы
     * на полную заполненность
     */
    checkNoEmptyAllFields () {
      const fieldNames = []
      let result = true
      this.formFields.forEach((item) => {
        const nameField = item.name
        fieldNames.push(nameField)
      })

      fieldNames.forEach((key) => {
        if (!this.model[key]) {
          result = false
        }
        if (this.model[key] && this.model[key].length === 0) {
          result = false
        }
      })
      return result
    }
  },
  mounted () {
    this.observeHeight()
  },
  beforeDestroy () {
    if (this.bannerId > 0 && this.showPopupAfterSubmit === false) {
      if (this.checkEmptyModelField() === true) {
        this.statBannerPlus('closing_without_filling_in', this.bannerId)
      } else if (this.checkNoEmptyAllFields() === true) {
        this.statBannerPlus('closing_with_filled', this.bannerId)
      }
    }
    this.formObserver.disconnect()
  }
}
