
import { DButton } from 'site-lib'

export default {
  name: 'MenuBanner',
  components: {
    DButton
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      // 'webinar', 'notion'
      default: 'webinar',
      required: false
    }
  },
  computed: {
    isWebinar () {
      return this.type === 'webinar'
    },
    imagePath () {
      if (this.isWebinar && this.mobile) {
        return 'common/banners/webinar-mobile.png'
      }
      return `common/banners/${this.type}.png`
    },
    buttonText () {
      return this.isWebinar ? this.$t('pages.index.webinar.button-text') : this.$t('pages.components.header.moreText')
    }
  },
  methods: {
    buttonAction () {
      const metricGoal = this.isWebinar ? 'webinar-menu' : 'notion-menu'
      this.sendGoalEvent(metricGoal)
      if (this.isWebinar) {
        window.open('https://my.mts-link.ru/megaplan/megapresentation?utm_source=site&utm_medium=menu&utm_campaign=webinar', '_blank').focus()
      } else {
        this.$router.push('/documents/')
      }
    }
  }
}
