
import { CIcon } from 'site-lib/index.ts'

export default {
  name: 'BenefitsBlock',
  components: {
    CIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    itemClass (item) {
      const defaultClass = 'benefit-item'
      const colorClass = `${defaultClass}--${item.color}`
      const vertical = `${defaultClass}--vertical`
      let classList
      this.vertical
        ? classList = `${defaultClass} ${colorClass} ${vertical} ${this.customClass}`
        : classList = `${defaultClass} ${colorClass} ${this.customClass}`
      return classList
    }
  }
}
