export default {
  getSelectedProduct (state) {
    return state.selectedProduct
  },
  getProductTarget (state) {
    return state.productTarget
  },
  getSocialUser (state) {
    return state.socialUser
  },
  getComment (state) {
    return state.comment
  }
}
