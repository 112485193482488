
import helpers from '../../mixins/helpers'
import registerLocal from '../../mixins/register-local'

export default {
  name: 'SocialRegistration',
  mixins: [helpers, registerLocal],
  data () {
    return {
      showSocialsIcons: false
    }
  },
  props: {
    showAlways: {
      type: Boolean,
      required: true
    }
  },
  mounted () {
    if (this.isProduction) {
      this.initSocials()
    }
    this.addSas()
  },
  methods: {
    /**
     * Авторизация через 1c
     */
    loginWith1C () {
      const link = `${process.env.HOST_NAME}/adm/api/oauth/callback?oauth=login1c&`
      const esc = encodeURIComponent
      const params = Object.keys(this.getMetaData()).map((k) => `${esc(k)}=${esc(this.getMetaData()[k])}`).join('&')
      window.open(link + params, '_blank')
    },
    sendGoalWithNetwork (e) {
      const path = e.target.currentSrc.split('/')
      const getSocialName = path[path.length - 1].split('.')
      const socialName = getSocialName[0]
      this.sendGoalEvent('openRegFormWithSocial', { provider: socialName })
    },
    initSocials () {
      if (this.isProduction) {
        this.showSocialsIcons = this.showSocials && this.getOauthMetaData()
      }
      this.showSocialsIcons = this.showSocials && this.oauthMetaData !== null
    },
    addSas () {
      const script = document.createElement('script')
      script.id = 'sas'
      script.src = 'https://sas.megaplan.ru/oauth/widgets/js'
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    }
  }
}
