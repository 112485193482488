import request from './request'

const API_URL = `${process.env.HOST_NAME}/adm/api/form-request/`

function sendForm (params) {
  return request(API_URL, 'send-form', params)
}

function checkCaptchaSettings (params) {
  return request(API_URL, 'check-captcha-settings', params)
}

export {
  // eslint-disable-next-line import/prefer-default-export
  sendForm,
  checkCaptchaSettings
}
