
import { CModal } from 'site-lib/index.ts'

import YandexShare from '@cookieseater/vue-yandex-share'

export default {
  name: 'BlogMarkPopup',
  components: {
    CModal,
    YandexShare
  },
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      url: process.env.HOST_NAME + this.$route.fullPath.split('?').shift(),
      showMarkPopup: false
    }
  },
  mounted () {
    this.showMarkPopup = this.isExist(this.$route.query.mark)
  },
  methods: {
    goToComments () {
      this.scrollToBlock('comments')
      this.showMarkPopup = false
    }
  }
}
