
import DateFormat from '../../../mixins/dateFormat'

export default {
  mixins: [DateFormat],
  name: 'NewsItems',
  props: {
    content: {
      type: Array,
      required: true
    },
    searchResults: {
      type: Array,
      required: false,
      default: null
    }
  },
  methods: {
    printContent () {
      return this.searchResults ? this.searchResults : this.content
    },
    articleTitle (block) {
      return this.isExist(block.translations[this.activeLanguage])
        ? block.translations[this.activeLanguage].title
        : block.translations.ru.title
    },
    /**
       * Обрезка описания страницы в блоге
       * @param content
       * @param size
       * @param suffix
       * @returns String
       */
    truncateString (content, size, suffix = '...') {
      if (content.length > size) {
        const newString = content.substring(0, size)
        return newString.substring(0, Math.min(content.length, content.lastIndexOf(' '))) + suffix
      }
      return content
    },
    getArticleSlug (page) {
      return this.isExist(page.articleSlug) ? page.articleSlug : page.slug
    }
  }
}
