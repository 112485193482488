
import { CIcon } from 'site-lib'

export default {
  name: 'SliderMultipleItems',
  components: {
    CIcon
  },
  props: {
    title: {
      type: String,
      required: false
    },
    slides: {
      type: Array,
      required: true
    },
    fixedMobileWidth: {
      type: Boolean,
      required: false
    },
    customClass: {
      type: String,
      default: ''
    },
    slidesPerShow: {
      type: Number,
      required: false,
      default: 3
    }
  },
  methods: {
    pauseSwiper () {
      this.mySwiper.autoplay.stop()
    },
    startSwiper () {
      this.mySwiper.autoplay.start()
    }
  },
  data () {
    return {
      quotesSwiper: {
        on: {
          update () {
            this.loopDestroy()
            this.loopCreate()
          }
        },
        slidesPerView: this.slidesPerShow,
        slidesPerGroup: this.slidesPerShow,
        breakpoints: {
          1023: {
            slidesPerView: 1,
            slidesPerGroup: 1
          }
        },
        loop: true,
        effect: 'slide',
        speed: 300,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        watchSlidesVisibility: true,
        navigation: {
          nextEl: '.slider-multiple-items__slider-nav--next',
          prevEl: '.slider-multiple-items__slider-nav--prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    isFixedMobileWidth () {
      return this.fixedMobileWidth
    }
  }
}
