
import UserDevice from '../../plugins/utils/userDevice'

export default {
  name: 'video',
  props: {
    videoId: {
      type: String,
      required: false,
      default: 'JfrLOg4IFrM'
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isAutoplayed () {
      const deviceType = UserDevice().os.split(' ').shift()
      return this.autoplay || deviceType === 'iOS' || deviceType === 'Android'
    }
  }
}

