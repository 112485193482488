export default {
  setType (state, type) {
    state.type = type
  },
  setCount (state, count) {
    state.count = count
  },
  setPeriod (state, period) {
    state.period = period
  }
}
