
import RegistrationLocal from '../mixins/register-local'
import userDevice from '../plugins/utils/userDevice'

export default {
  name: 'RosSoftLanding',
  layout: 'default',
  mixins: [RegistrationLocal],
  data () {
    return {
      isMobile: false,
      formParams: {
        formEmailName: 'price',
        formEmailTitle: this.$t('pages.ros-soft.common.formTitle')
      }
    }
  },
  components: {
    FooterGlowWrapper: () => import('~/components/elements/footer-glow-wrapper.vue'),

    RosSoftHeader: () => import('~/components/pages/ros-soft/ros-soft-header.vue'),
    RosSoftForWhat: () => import('~/components/pages/ros-soft/ros-soft-for-what.vue'),
    RosSoftCrmCapabilities: () => import('~/components/pages/ros-soft/ros-soft-crm-capabilities.vue'),
    RosSoftSlider: () => import('~/components/pages/ros-soft/ros-soft-slider.vue'),
    RosSoftWhoHelped: () => import('~/components/pages/ros-soft/ros-soft-who-helped.vue'),
    RosSoftKnowledge: () => import('~/components/pages/ros-soft/ros-soft-knowledge.vue')
  },
  mounted () {
    this.isMobile = userDevice().device !== 'desktop'
    this.setRegistrationComment('Регистрация со страницы МСП')
  },
  methods: {
    regAction () {
      const popupName = 'reg-form'
      const formParams = this.isMobile ? this.formParams : undefined
      this.showRegPopup(popupName, formParams)
      this.sendGoalEvent('tryForFreeButton')
    }
  },
  destroyed () {
    this.setRegistrationComment('')
  }
}
