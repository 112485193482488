
export default {
  layout: 'empty',
  name: 'CreatingMobile',
  components: {
    YoutubeVideo: () => import('../../components/widgets/youtube-video.vue')
  },
  mounted () {
    this.callTrackingStart()
  }
}
