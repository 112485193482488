
import { gsap } from 'gsap/dist/gsap.min'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min'

gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'SafetyAnimatedBlock',
  data: () => ({
    safetyImagesCount: 7,
    spacesSafetyTrigger: '.safety__wrapper'
  }),
  props: {
    safetyItems: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    spanTitle: {
      type: String,
      default: ''
    },
    typeFor: {
      type: String,
      default: 'spaces'
    }
  },
  mounted () {
    ScrollTrigger.defaults({ scroller: '.layout__content' })
    if (!window.matchMedia('(max-width: 1023px)').matches) {
      gsap.timeline({
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '50% 100%'
        }
      }).from(this.$refs.safetyBlocks, {
        lazy: true,
        duration: 1,
        ease: this.baseEase,
        x: -100,
        scale: 0.2,
        opacity: 0,
        stagger: 0.2
      })
      gsap.from(this.$refs.safetyImages, {
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '150px 70%'
        },
        lazy: true,
        yPercent: 120
      })
      gsap.from(this.$refs.safetyImg5, {
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '150px 70%'
        },
        lazy: true,
        left: '50%',
        xPercent: -50,
        delay: 0.8
      })
      gsap.from(this.$refs.safetyImg4, {
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '150px 70%'
        },
        lazy: true,
        right: '50%',
        xPercent: 50,
        delay: 0.8
      })
      gsap.from(this.$refs.safetyImg3, {
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '150px 70%'
        },
        lazy: true,
        left: '50%',
        xPercent: -50,
        delay: 1
      })
      gsap.from(this.$refs.safetyImg2, {
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '150px 70%'
        },
        lazy: true,
        right: '50%',
        xPercent: 50,
        delay: 1
      })
      gsap.from(this.$refs.safetyImg1, {
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '150px 70%'
        },
        lazy: true,
        left: '50%',
        xPercent: -50,
        delay: 1.2
      })
      gsap.from(this.$refs.safetyImg0, {
        scrollTrigger: {
          trigger: this.spacesSafetyTrigger,
          start: '150px 70%'
        },
        lazy: true,
        right: '50%',
        xPercent: 50,
        delay: 1.2
      })
    }
  }
}
