
import DateFormat from '../../../mixins/dateFormat'

export default {
  name: 'BlogItems',
  components: {
    SubscribeBlock: () => import('./subscribe.vue')
  },
  mixins: [DateFormat],
  props: {
    content: {
      type: [Array, Object],
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    firstLoad: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDate: {
      type: Boolean,
      required: false,
      default: false
    },
    showSubscribeButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getThumbUrl (path, articleBackgroundFolder, articleSlug) {
      let result
      if (path) {
        const dir = path.substring(0, path.lastIndexOf('/') + 1)
        const fileName = path.split('/').pop()
        result = `/upload/blog/${dir}thumb__${fileName}`
        if (articleBackgroundFolder) {
          result = `/upload/blog/${articleSlug}/${dir}thumb__${fileName}`
        }
      } else {
        result = '/img/plane_thumb.png'
      }
      return result
    },
    getErrorThumb (e) {
      e.target.src = e.target.parentNode.parentNode.classList.contains('pic') ? '/img/plane_thumb_full.png' : '/img/plane_thumb.png'
      e.target.style.backgroundColor = `rgba(${Math.round(Math.random() * 255)}
      , ${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, .15)`
      e.target.style.border = '1px solid rgba(0,0,0,.15)'
    },
    subscribeShow (index) {
      return this.showSubscribeButton && this.firstLoad && index === 0
    }
  }
}
