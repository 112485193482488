
import Meta from '../../../plugins/json/blog/authors/meta.json'

export default {
  name: 'BlogAuthorSlug',
  components: {
    FooterGlowWrapper: () => import('../../../components/elements/footer-glow-wrapper.vue'),
    NotFoundPage: () => import('../../404.vue'),
    BlogAuthor: () => import('../../../components/pages/blog/blog-author.vue')
  },
  data () {
    return {
      slug: '',
      meta: undefined
    }
  },
  async asyncData ({ app, params, error }) {
    const meta = Meta.pages.find((x) => x.slug === params.slug)
    const posts = await app.$api('blogPosts', 'list', {
      authorId: meta.authorId,
      siteId: 2,
      limit: 9
    })
    if (typeof posts === 'undefined' || typeof posts.items === 'undefined') {
      return error({ statusCode: 500, message: 'Внутренняя ошибка' })
    }
    return {
      posts: posts.items
    }
  },
  created () {
    this.slug = this.$route.params.slug
    this.meta = Meta.pages.find((x) => x.slug === this.slug)
  },
  head () {
    let meta = this.meta
    if (typeof meta === 'undefined') {
      meta = {
        title: '',
        subtitle: '',
        description: ''
      }
    }
    return {
      title: meta.title,
      meta: [
        { hid: 'description', name: 'description', content: meta.description }
      ]
    }
  }
}
