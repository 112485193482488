
import domainList from '../../../plugins/json/domains.json'

const addressValidationRegExp = /^[\w-]+$/

export default {
  name: 'AuthorizationForm',
  components: {
    Error: () => import('./components/error.vue'),
    Form: () => import('./components/form.vue'),
    Hint: () => import('./components/hint.vue'),
    Button: () => import('./components/button.vue'),
    Input: () => import('./components/input.vue'),
    ArrowForwardIcon: () => import('./components/arrow-forward-icon.vue')
  },
  data: () => ({
    inputErrorMessage: '',
    inputAddress: ''
  }),
  computed: {
    currentDomain () {
      let domain = process.browser && this.isExist(window.location.hostname)
        ? window.location.hostname
        : 'megaplan.ru'

      domainList.languages.map((info) => {
        if (info.link === domain) {
          domain = info.link
        }
      })

      return domain
    }
  },
  methods: {
    async tryLogin () {
      // запоминаем на случай, если значение изменится пока мы ходим на бекенд
      const address = this.inputAddress
      if (!address || !!this.inputErrorMessage) {
        return
      }

      const exist = await this.$api('account', 'isAccountExist', { name: address })
      this.inputErrorMessage = exist
        ? ''
        : this.$t('pages.components.authorization-form.address-not-exist')

      const query = this.$route.query
      if (exist) {
        if (query?.return) {
          document.location.href = `https://${address}.${this.currentDomain}/sso/userecho?return=${query.return}`
          return
        }
        document.location.href = `https://${address}.${this.currentDomain}`
      }
    },
    handleChange () {
      this.inputErrorMessage = !this.inputAddress || addressValidationRegExp.test(this.inputAddress)
        ? ''
        : this.$t('pages.components.authorization-form.address-not-valid')
    }
  }
}
