
import { CIcon } from 'site-lib'

export default {
  name: 'FacilitiesBlock',
  components: {
    CIcon,
    SliderMultipleItems: () => import('../../UI/slider-multiple-items.vue')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    reversed: {
      type: Boolean,
      default: false
    },
    isRegButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeItemIndex: 0
  })
}
